import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../../components/ScrollButton';
// import SEO from '../../components/SEO';

const ParallaxBox = ({ children, x, y, className }) => {
  const ref = React.useRef();
  const isInView = useInView(ref, { amount: 0.5 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x, y }}
      animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

const HeroBanner = () => {
  return (
    <ParallaxBanner className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/Teraace-bg-113.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            Design with Confidence<br/>
            <em>Using Real Behavioral Patterns</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            Let ML reveal how users actually interact with your interface to inform better design decisions.
          </h3>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

const KeyBenefits = () => {
  return (
    <div className="section-inner">
      <h2>Key Benefits</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Understand Real Usage</h3>
          <ul>
            <li>See natural user paths</li>
            <li>Identify interaction patterns</li>
            <li>Spot usability issues</li>
            <li>Understand user preferences</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Validate Decisions</h3>
          <ul>
            <li>Test design hypotheses</li>
            <li>Measure UX changes</li>
            <li>Compare alternatives</li>
            <li>Track improvement impact</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Optimize Flows</h3>
          <ul>
            <li>Identify optimal paths</li>
            <li>Reduce friction points</li>
            <li>Improve navigation</li>
            <li>Enhance user experience</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const UXFeatures = () => {
  return (
    <div className="section-inner">
      <h2>Features for UX Teams</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Behavior Visualizer</h3>
          <ul>
            <li>Real user flow mapping</li>
            <li>Interaction pattern analysis</li>
            <li>Success path identification</li>
            <li>Friction point detection</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Impact Analyzer</h3>
          <ul>
            <li>Design change tracking</li>
            <li>Before/after comparison</li>
            <li>Segment analysis</li>
            <li>Performance metrics</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Pattern Explorer</h3>
          <ul>
            <li>Automatic pattern detection</li>
            <li>Cross-page analysis</li>
            <li>Interaction mapping</li>
            <li>Success path identification</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const Applications = () => {
  return (
    <div className="section-inner">
      <h2>Real-World Applications</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Flow Optimization</h3>
          <ul>
            <li>Map user journeys</li>
            <li>Identify shortcuts</li>
            <li>Optimize navigation</li>
            <li>Reduce friction</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Design Validation</h3>
          <ul>
            <li>Test assumptions</li>
            <li>Measure impact</li>
            <li>Compare alternatives</li>
            <li>Track improvements</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Experience Enhancement</h3>
          <ul>
            <li>Identify pain points</li>
            <li>Optimize interactions</li>
            <li>Improve usability</li>
            <li>Enhance satisfaction</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const GetStarted = () => {
  return (
    <div className="final-cta">
      <div className="section-inner">
        <h2>Ready to Transform Your Design Process?</h2>
        <div className="cta-buttons">
          <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
            Start Free Trial
          </ScrollToTopButton>
          <ScrollToTopButton to="/requestdemo" className="secondary-cta">
            See UX Demo
          </ScrollToTopButton>
        </div>
      </div>
    </div>
  );
};
const TeamUX = () => {
    return (
      <div className="homepage">
        <HeroBanner />
        <KeyBenefits />
        <UXFeatures />
        <Applications />
        <GetStarted />
      </div>
    );
  };
  
  const TeamUXPage = () => {
    return (
      <ParallaxProvider>
        <TeamUX />
      </ParallaxProvider>
    );
  };
  
  export default TeamUXPage;