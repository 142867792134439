import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import GlowBox from '../../components/GlowBox';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../../components/ScrollButton';

const ParallaxGlowBox = ({ children, glowColor, bgColor, x, y }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        exit="exit"
        variants={fadeInOut(x, y)}
      >
        <GlowBox glowColor={glowColor} bgColor={bgColor}>
          {children}
        </GlowBox>
      </motion.div>
    );
};

const fadeInOut = (x, y) => ({
    hidden: { opacity: 0, x, y },
    visible: {
      opacity: 1,
      x: '0%',
      y: '0%',
      transition: { duration: 0.5, ease: 'easeInOut' }
    },
    exit: { opacity: 0, x: -x, y: -y, transition: { duration: 0.5, ease: 'easeInOut' } }
});

const ParallaxBox = ({ children, x, y, className }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial={{ opacity: 0, x, y }}
        animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        className={className}
      >
        {children}
      </motion.div>
    );
};

const HeroBanner = () => {
  return (
    <ParallaxBanner className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/Teraace-bg-005.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            Onboarding Optimization<br/>
            <em>Create Perfect First Experiences</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            Let machine learning reveal the optimal paths to user success from day one.
          </h3>
          <div className="hero-cta-container">
            <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
              Optimize Onboarding
            </ScrollToTopButton>
            <ScrollToTopButton to="/requestdemo" className="secondary-cta">
              See Onboarding Analysis
            </ScrollToTopButton>
          </div>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

const ChallengesSection = () => {
  return (
    <div className="section-inner challenge-section xy-pad">
      <h2 className="standard-pad no-btm">Onboarding Challenges</h2>
      <div className="twobytwo">
        <ParallaxBox x={-50} y={0} className="panel-left">
          <div className="panel-content">
            <h3>Common Issues</h3>
            <ul>
              <li>Unclear next steps</li>
              <li>Information overload</li>
              <li>Feature discovery problems</li>
              <li>Value demonstration gaps</li>
              <li>Drop-off points</li>
            </ul>
          </div>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="panel-right">
          <div className="panel-content">
            <h3>Teraace's Solutions</h3>
            <ul>
              <li>Success pattern identification</li>
              <li>Personalized paths</li>
              <li>Friction detection</li>
              <li>Value acceleration</li>
              <li>Drop-off prevention</li>
            </ul>
          </div>
        </ParallaxBox>
      </div>
    </div>
  );
};

const FeaturesSection = () => {
  return (
    <div className="section-inner">
      <h2>Key Features</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="feature-column">
          <h3>Path Optimizer</h3>
          <ul>
            <li>Success pattern detection</li>
            <li>Friction point identification</li>
            <li>Segment-specific insights</li>
            <li>Impact prediction</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="feature-column">
          <h3>Experience Analyzer</h3>
          <ul>
            <li>User behavior tracking</li>
            <li>Success metrics monitoring</li>
            <li>Segment comparison</li>
            <li>Pattern discovery</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="feature-column">
          <h3>Value Accelerator</h3>
          <ul>
            <li>Key action identification</li>
            <li>Timing optimization</li>
            <li>Success prediction</li>
            <li>Impact measurement</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const OptimizationSection = () => {
  return (
    <div className="product-showcase">
      <div className="section-inner">
        <div className="showcase-content">
          <div className="showcase-features">
            <h2>Data-Driven Approach</h2>
            <ul>
              <li>Map successful journeys</li>
              <li>Identify key actions</li>
              <li>Optimize timing</li>
              <li>Measure impact</li>
            </ul>
          </div>
          <div className="showcase-preview">
            <img src="/images/Teraace-bg-008.webp" alt="Optimization Framework" className="rounded-lg shadow-xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

const MetricsSection = () => {
  return (
    <div className="section-inner">
      <h2>Success Metrics</h2>
      <div className="fivebyfive">
        <ParallaxBox x={0} y={20} className="metric-item benefit-column">
          <h3>Time to value</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="metric-item benefit-column">
          <h3>Completion rates</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="metric-item benefit-column">
          <h3>Feature adoption</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="metric-item benefit-column">
          <h3>User engagement</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="metric-item benefit-column">
          <h3>Long-term retention</h3>
        </ParallaxBox>
      </div>
    </div>
  );
};

const GetStartedSection = () => {
  return (
    <div className="section-inner bg-orange top-pad">
      <h2>Quick Start Steps</h2>
      <div className="fourbyfour">
        <ParallaxBox x={0} y={20} className="step-item">
          <h3>1. Connect onboarding data</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="step-item">
          <h3>2. Analyze current patterns</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="step-item">
          <h3>3. Identify opportunities</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="step-item">
          <h3>4. Optimize and measure</h3>
        </ParallaxBox>
      </div>
    </div>
  );
};

const OnboardingSection = () => {
  return (
    <div className="homepage">
      <HeroBanner />
      <ChallengesSection />
      <FeaturesSection />
      <OptimizationSection />
      <MetricsSection />
      <GetStartedSection />
      <div className="final-cta">
        <div className="section-inner">
          <h2>Start Optimizing Your Onboarding Today</h2>
          <p>Transform your user onboarding with Teraace's optimization</p>
          <div className="cta-buttons">
            <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">Optimize Onboarding</ScrollToTopButton>
            <ScrollToTopButton to="/requestdemo" className="secondary-cta">See Onboarding Analysis</ScrollToTopButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const OnboardingOptimization = () => {
  return (
    <ParallaxProvider>
      <OnboardingSection />
    </ParallaxProvider>
  );
};

export default OnboardingOptimization;