import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../components/ScrollButton';

const ParallaxBox = ({ children, x, y, className }) => {
  const ref = React.useRef();
  const isInView = useInView(ref, { amount: 0.5 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x, y }}
      animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

const HeroBanner = () => {
  return (
    <ParallaxBanner className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/Teraace-bg-105.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            Transform User Behavior<br/>
            <em>into Product Intelligence</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            Teraace's platform turns complex user interactions into clear, actionable insights through machine learning that's uniquely trained on your product.
          </h3>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

const ProcessSection = () => {
  return (
    <div className="section-inner">
      <h2>How It Works</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>1. Capture</h3>
          <ul>
            <li>Auto-capture script that intelligently tracks all user interactions</li>
            <li>Direct API integration for precise control over data collection</li>
            <li>Data pipeline connections through tools like Segment and Rudderstack</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>2. Learn</h3>
          <ul>
            <li>Build a unique understanding of your product's usage patterns</li>
            <li>Identify successful user journeys automatically</li>
            <li>Discover behavioral patterns you didn't know to look for</li>
            <li>Adapt and improve as more data is collected</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>3. Reveal</h3>
          <ul>
            <li>Visualize user journeys in interactive 3D</li>
            <li>Predict likely user behaviors and outcomes</li>
            <li>Surface opportunities for optimization</li>
            <li>Generate actionable recommendations</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const CoreComponents = () => {
  return (
    <div className="section-inner">
      <h2>Core Platform Components</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Insights Engine</h3>
          <ul>
            <li>Pattern Discovery: Automatically identify successful user journeys</li>
            <li>Behavioral Analysis: Understand how different user segments interact</li>
            <li>Success Mapping: See which features truly drive user value</li>
            <li>Trend Detection: Spot emerging patterns early</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Visualization Suite</h3>
          <ul>
            <li>3D Path Analyzer: Explore user journeys in three dimensions</li>
            <li>Pattern Explorer: Interact with discovered behavioral patterns</li>
            <li>Success Flows: Map the most effective paths to user goals</li>
            <li>Impact Analysis: Measure feature adoption and usage</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Prediction System</h3>
          <ul>
            <li>Journey Forecasting: Anticipate user paths and outcomes</li>
            <li>Churn Prevention: Identify at-risk behaviors early</li>
            <li>Opportunity Detection: Surface optimization possibilities</li>
            <li>Impact Prediction: Forecast the effects of product changes</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const PlatformBenefits = () => {
  return (
    <div className="section-inner">
      <h2>Platform Benefits</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="platform-column">
          <h3>Move Beyond Basic Analytics</h3>
          <ul>
            <li>Replace guesswork with ML-powered insights</li>
            <li>Understand complex user behaviors automatically</li>
            <li>Predict outcomes based on proven patterns</li>
            <li>Surface opportunities you didn't know existed</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="platform-column">
          <h3>Save Time and Resources</h3>
          <ul>
            <li>Eliminate manual data analysis</li>
            <li>Automate pattern discovery</li>
            <li>Generate insights continuously</li>
            <li>Focus on acting, not analyzing</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="platform-column">
          <h3>Drive Better Outcomes</h3>
          <ul>
            <li>Optimize user journeys proactively</li>
            <li>Prevent churn before it happens</li>
            <li>Increase feature adoption</li>
            <li>Improve user success rates</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const SecuritySection = () => {
  return (
    <div className="section-inner">
      <h2>Security & Compliance</h2>
      <div className="fivebyfive">
        <ParallaxBox x={-30} y={0} className="benefit-column">
          <h3>Enterprise-grade security</h3>
          {/* <img src="/images/example.webp" alt="Security icon" /> */}
        </ParallaxBox>
        
        <ParallaxBox x={-15} y={0} className="benefit-column">
          <h3>SOC 2 compliant</h3>
          {/* <img src="/images/example.webp" alt="SOC2 icon" /> */}
        </ParallaxBox>
        
        <ParallaxBox x={0} y={0} className="benefit-column">
          <h3>GDPR compliant</h3>
          {/* <img src="/images/example.webp" alt="GDPR icon" /> */}
        </ParallaxBox>
        
        <ParallaxBox x={15} y={0} className="benefit-column">
          <h3>Data encryption</h3>
          {/* <img src="/images/example.webp" alt="Encryption icon" /> */}
        </ParallaxBox>
        
        <ParallaxBox x={30} y={0} className="benefit-column">
          <h3>Regular penetration testing</h3>
          {/* <img src="/images/example.webp" alt="Audit icon" /> */}
        </ParallaxBox>
      </div>
    </div>
  );
};

const GetStarted = () => {
  return (
    <div className="final-cta">
      <div className="section-inner">
        <h2>Ready to Transform Your Product Understanding?</h2>
        <div className="cta-buttons">
          <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
            Start Free Trial
          </ScrollToTopButton>
          <ScrollToTopButton to="/requestdemo" className="secondary-cta">
            Schedule Demo
          </ScrollToTopButton>
        </div>
      </div>
    </div>
  );
};

// const Resources = () => {
//   return (
//     <div className="section-inner">
//       <div className="twobytwo">
//         <div>
//           <h3>Learn More About:</h3>
//           <ul>
//             <li><a href="/ml-capabilities">Machine Learning Capabilities</a></li>
//             <li><a href="/pattern-recognition">Pattern Recognition</a></li>
//             <li><a href="/predictive-analytics">Predictive Analytics</a></li>
//             <li><a href="/algorithm-library">Algorithm Library</a></li>
//           </ul>
//         </div>
//         <div>
//           <h3>Additional Resources:</h3>
//           <ul>
//             <li><a href="/implementation-guide">Implementation Guide</a></li>
//             <li><a href="/docs">Technical Documentation</a></li>
//             <li><a href="/case-studies">Case Studies</a></li>
//             <li><a href="/blog">Platform Updates Blog</a></li>
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// };

const PlatformOverview = () => {
  return (
    <div className="homepage">
      <HeroBanner />
      <ProcessSection />
      <CoreComponents />
      <PlatformBenefits />
      <SecuritySection />
      {/* <Resources /> */}
      <GetStarted />
    </div>
  );
};

const Platform = () => {
  return (
    <ParallaxProvider>
      <PlatformOverview />
    </ParallaxProvider>
  );
};

export default Platform;
