import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../../components/ScrollButton';

const ParallaxBox = ({ children, x, y, className }) => {
  const ref = React.useRef();
  const isInView = useInView(ref, { amount: 0.5 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x, y }}
      animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

const HeroBanner = () => {
  return (
    <ParallaxBanner style={{ aspectRatio: '2 / 1' }} className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/Teraace-bg-015.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            Transform Complex User Data <em>into Clear Understanding</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            Your product generates millions of data points. Our machine learning transforms them into clear, actionable insights about how users actually succeed.
          </h3>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

const TrendsSection = () => {
  return (
    <div className="product-showcase bg-yt">
      <div className="section-inner">
        <div className="product-show-content">
          <div className="product-show-features">
            <h2>Trends Analysis</h2>
            <h3>Time-Based Pattern Recognition</h3>
            <ul>
              <li>Track and analyze weekly/monthly page visits across your entire site</li>
              <li>Monitor user engagement through session counts and durations</li>
              <li>Identify your most active pages and top-performing events</li>
              <li>Flexible date range selection for custom period analysis</li>
              <li>Compare performance across different time periods</li>
            </ul>
            <div className="mt-4">
              <h3 className="font-medium mb-2">Key Metrics Tracked:</h3>
              <ul className="grid grid-cols-2 gap-2">
                <li>Weekly Page Visits</li>
                <li>Top Pages Traffic</li>
                <li>User Count Trends</li>
                <li>Session Frequency</li>
                <li>Page Duration</li>
                <li>Top Events Dashboard</li>
              </ul>
            </div>
          </div>
          <div className="showcase-preview">
            <img src="/productgifs/Teraace-Trends.gif" alt="Trends Analysis Dashboard" className="rounded-lg shadow-xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

const BenchmarksSection = () => {
  return (
    <div className="product-showcase">
      <div className="section-inner">
        <div className="product-show-content">
        <div className="showcase-preview">
            <img src="/productgifs/Teraace-Benchmarks.gif" alt="Benchmarks Dashboard" className="rounded-lg shadow-xl" />
          </div>
          <div className="product-show-features">
            <h2>Benchmarks Overview</h2>
            <h3>Holistic Performance Metrics</h3>
            <ul>
              <li>Comprehensive view of user navigation patterns and behaviors</li>
              <li>Deep insights into traffic sources and user entry points</li>
              <li>Detailed event tracking and interaction analysis</li>
              <li>Query parameter impact on user journeys</li>
            </ul>
            <div className="mt-4">
              <h3 className="font-medium mb-2">Key Insights:</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h4 className="font-medium">Where are they going?</h4>
                  <p className="text-sm">Page visit distribution</p>
                </div>
                <div>
                  <h4 className="font-medium">What are they doing?</h4>
                  <p className="text-sm">Event interaction patterns</p>
                </div>
                <div>
                  <h4 className="font-medium">What's driving them in?</h4>
                  <p className="text-sm">Campaign performance analysis</p>
                </div>
                <div>
                  <h4 className="font-medium">Where are they from?</h4>
                  <p className="text-sm">Referrer source tracking</p>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

const ConversionSection = () => {
  return (
    <div className="product-showcase bg-orange">
      <div className="section-inner">
        <div className="product-show-content">
          <div className="product-show-features">
            <h2>Conversion Funnel Analysis</h2>
            <h3>Beyond Basic Conversion Tracking</h3>
            <ul>
              <li>See all possible paths through your conversion funnel, not just the obvious ones</li>
              <li>Identify where different user segments naturally diverge from expected paths</li>
              <li>Discover successful alternate routes you didn't know existed</li>
              <li>Predict likely conversion outcomes based on early behavior patterns</li>
            </ul>
          </div>
          <div className="showcase-preview">
            <img src="/productgifs/Teraace-Funnel.gif" alt="Conversion Analysis" className="rounded-lg shadow-xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

const HappyPathSection = () => {
  return (
    <div className="product-showcase bg-yt">
      <div className="section-inner">
        <div className="product-show-content">
        <div className="showcase-preview">
            <img src="/productgifs/Teraace-Happy-Path.gif" alt="Happy Path Analysis" className="rounded-lg shadow-xl" />
          </div>
          <div className="product-show-features">
            <h2>Happy Path Analysis</h2>
            <h3>Understand How Users Actually Succeed</h3>
            <ul>
              <li>Let ML identify the most effective paths to user success</li>
              <li>Compare journey efficiency across different user segments</li>
              <li>Discover natural workflow patterns you didn't design for</li>
              <li>Optimize based on proven behavioral patterns, not assumptions</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const IntelligenceSection = () => {
  return (
    <div className="product-showcase">
      <div className="section-inner">
        <div className="product-show-content">
          <div className="product-show-features">
            <h2>Real-Time Intelligence Feed</h2>
            <h3>Insights That Find You</h3>
            <ul>
              <li>Automatically surface significant patterns and anomalies</li>
              <li>Receive ML-generated recommendations for optimization</li>
              <li>Track the impact of changes on user behavior patterns</li>
              <li>Stay ahead of emerging trends and potential issues</li>
            </ul>
          </div>
          <div className="showcase-preview">
            <img src="/images/Teraace-bg-059.webp" alt="Real-Time Intelligence" className="rounded-lg shadow-xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

const CTASection = () => {
  return (
    <div className="final-cta">
      <div className="section-inner">
        <h2>Start Seeing Patterns Today</h2>
        <p>Transform your understanding of how users interact with your product.</p>
        <div className="cta-buttons">
          <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
            Get Started Free
          </ScrollToTopButton>
          <ScrollToTopButton to="/requestdemo" className="secondary-cta">
            Schedule Demo
          </ScrollToTopButton>
        </div>
      </div>
    </div>
  );
};

const InsightsPage = () => {
  return (
    <div className="homepage">
      <HeroBanner />
      <TrendsSection />
      <BenchmarksSection />
      <ConversionSection />
      <HappyPathSection />
      <IntelligenceSection />
      <CTASection />
    </div>
  );
};

const Insights = () => {
  return (
    <ParallaxProvider>
      <InsightsPage />
    </ParallaxProvider>
  );
};

export default Insights;