import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../../components/ScrollButton';
// import SEO from '../../components/SEO';

const ParallaxBox = ({ children, x, y, className }) => {
  const ref = React.useRef();
  const isInView = useInView(ref, { amount: 0.5 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x, y }}
      animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

const HeroBanner = () => {
  return (
    <ParallaxBanner className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/Teraace-bg-099.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            Make Confident Decisions <br/>
            <em>with ML-Powered Intelligence</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            Get a holistic view of product performance and user behavior patterns for strategic decision-making.  
          </h3>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

const KeyBenefits = () => {
  return (
    <div className="section-inner">
      <h2>Key Benefits</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Strategic Insight</h3>
          <ul>
            <li>Understand key success drivers</li>
            <li>Identify growth opportunities</li>
            <li>Spot emerging trends</li>
            <li>Predict future outcomes</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Performance Intelligence</h3>
          <ul>
            <li>Track critical metrics</li>
            <li>Measure initiative impact</li>
            <li>Monitor team performance</li>
            <li>Forecast future results</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Resource Optimization</h3>
          <ul>
            <li>Prioritize investments</li>
            <li>Optimize team focus</li>
            <li>Validate decisions</li>
            <li>Measure ROI</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const ExecutiveFeatures = () => {
  return (
    <div className="section-inner">
      <h2>Features for Executives</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Executive Dashboard</h3>
          <ul>
            <li>Key metric tracking</li>
            <li>Trend visualization</li>
            <li>Predictive analytics</li>
            <li>Strategic alerts</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Impact Analyzer</h3>
          <ul>
            <li>Initiative tracking</li>
            <li>ROI calculation</li>
            <li>Performance comparison</li>
            <li>Resource optimization</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Strategy Navigator</h3>
          <ul>
            <li>Opportunity identification</li>
            <li>Risk assessment</li>
            <li>Resource planning</li>
            <li>Performance forecasting</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const Applications = () => {
  return (
    <div className="section-inner">
      <h2>Real-World Applications</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Strategic Planning</h3>
          <ul>
            <li>Identify opportunities</li>
            <li>Validate strategies</li>
            <li>Allocate resources</li>
            <li>Track outcomes</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Performance Management</h3>
          <ul>
            <li>Monitor key metrics</li>
            <li>Track team impact</li>
            <li>Measure success</li>
            <li>Optimize execution</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Investment Planning</h3>
          <ul>
            <li>Prioritize initiatives</li>
            <li>Validate investments</li>
            <li>Track returns</li>
            <li>Optimize spending</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

// const ExecutiveResources = () => {
//   return (
//     <div className="section-inner">
//       <h2>Executive Resources</h2>
//       <ul className="resource-list">
//         <li>ROI Calculator</li>
//         <li>Strategy Playbook</li>
//         <li>Case Studies</li>
//         <li>Benchmark Reports</li>
//       </ul>
//     </div>
//   );
// };

const GetStarted = () => {
  return (
    <div className="final-cta">
      <div className="section-inner">
        <h2>Ready to Elevate Your Decision-Making?</h2>
        <div className="cta-buttons">
          <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
            Start Free Trial
          </ScrollToTopButton>
          <ScrollToTopButton to="/requestdemo" className="secondary-cta">
            Schedule Executive Demo
          </ScrollToTopButton>
        </div>
      </div>
    </div>
  );
};

const ExecutiveTeams = () => {
    return (
      <div className="homepage">
        <HeroBanner />
        <KeyBenefits />
        <ExecutiveFeatures />
        <Applications />
        {/* <ExecutiveResources /> */}
        <GetStarted />
      </div>
    );
  };
  
  const ExecutiveTeamsPage = () => {
    return (
      <ParallaxProvider>
        <ExecutiveTeams />
      </ParallaxProvider>
    );
  };
  
  export default ExecutiveTeamsPage;