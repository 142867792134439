import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../../components/ScrollButton';
// import SEO from '../../components/SEO';

const ParallaxBox = ({ children, x, y, className }) => {
  const ref = React.useRef();
  const isInView = useInView(ref, { amount: 0.5 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x, y }}
      animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

const HeroBanner = () => {
  return (
    <ParallaxBanner className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/Teraace-bg-111.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            ML-Powered Intelligence<br/>
            <em>for Every Team</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            Different teams need different insights. Teraace's machine learning adapts to each team's unique needs while maintaining a single source of truth about user behavior.
          </h3>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

const TeamSolutions = () => {
  return (
    <div className="section-inner">
      <h2>Who We Serve</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Product Teams</h3>
          <p>Move beyond gut feelings to data-driven conviction. Let ML reveal which features truly drive user success.</p>
          <ScrollToTopButton to="/team-product" variant="subtle" className="learn-more bg-cran">
            Learn More →
          </ScrollToTopButton>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Growth Teams</h3>
          <p>Discover the real patterns behind activation and retention. See the complete picture of user behavior, not just isolated events.</p>
          <ScrollToTopButton to="/team-growth" variant="subtle" className="learn-more bg-cran">
            Learn More →
          </ScrollToTopButton>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Customer Success</h3>
          <p>Guide users to success through their preferred paths. Predict and prevent churn by understanding the full journey.</p>
          <ScrollToTopButton to="/team-customersuccess" variant="subtle" className="learn-more bg-cran">
            Learn More →
          </ScrollToTopButton>
        </ParallaxBox>
      </div>

      <div className="twobytwo">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>UX Design</h3>
          <p>Design with confidence using real behavioral patterns and ML-identified success paths.</p>
          <ScrollToTopButton to="/team-userexperience" variant="subtle" className="learn-more bg-cran">
            Learn More →
          </ScrollToTopButton>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Executives</h3>
          <p>Get a holistic view of product performance and user behavior patterns for confident decision-making.</p>
          <ScrollToTopButton to="/executives" variant="subtle" className="learn-more bg-cran">
            Learn More →
          </ScrollToTopButton>
        </ParallaxBox>
      </div>
    </div>
  );
};

const WhyTeraace = () => {
  return (
    <div className="section-inner">
      <h2>Why Teams Choose Teraace</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Beyond Basic Analytics</h3>
          <ul>
            <li>ML models that learn your product's unique patterns</li>
            <li>Automatic discovery of success patterns</li>
            <li>Predictive insights for proactive decisions</li>
            <li>Cross-team collaboration on real user behavior data</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Immediate Time to Value</h3>
          <ul>
            <li>Start seeing patterns from day one</li>
            <li>No data science expertise required</li>
            <li>Automated insight generation</li>
            <li>Continuous learning and improvement</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>True User Understanding</h3>
          <ul>
            <li>Discover unknown user journey patterns</li>
            <li>Understand behavior across segments</li>
            <li>Predict future user actions</li>
            <li>Optimize based on actual usage</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const GetStarted = () => {
  return (
    <div className="final-cta">
      <div className="section-inner">
        <h2>Ready to Transform Your Team's Understanding?</h2>
        <div className="cta-buttons">
          <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
            Get Started Today
          </ScrollToTopButton>
          <ScrollToTopButton to="/requestdemo" className="secondary-cta">
            Schedule Demo
          </ScrollToTopButton>
        </div>
      </div>
    </div>
  );
};

const Solutions = () => {
  return (
    <div className="homepage">
      <HeroBanner />
      <TeamSolutions />
      <WhyTeraace />
      <GetStarted />
    </div>
  );
};

const SolutionsPage = () => {
  return (
    <ParallaxProvider>
      <Solutions />
    </ParallaxProvider>
  );
};

export default SolutionsPage;