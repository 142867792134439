import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../components/ScrollButton';
// import SEO from '../components/SEO';

const ParallaxBox = ({ children, x, y, className }) => {
  const ref = React.useRef();
  const isInView = useInView(ref, { amount: 0.5 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x, y }}
      animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

const HeroBanner = () => {
  return (
    <ParallaxBanner className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/Teraace-bg-119.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            Built for Enterprise Scale<br/>
            <em>Security, Control, Performance</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            Teraace delivers the scalability, control, and support that enterprise organizations demand.
          </h3>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

const AdvancedControls = () => {
  return (
    <div className="section-inner">
      <h2>Advanced Controls</h2>
      <div className="twobytwo">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Administration</h3>
          <ul>
            <li>Centralized user management</li>
            <li>Custom role definitions</li>
            <li>Access control policies</li>
            <li>Audit logging</li>
            <li>Usage reporting</li>
            <li>License management</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Team Management</h3>
          <ul>
            <li>Multiple team support</li>
            <li>Cross-team collaboration</li>
            <li>Project isolation</li>
            <li>Resource sharing controls</li>
            <li>Team-level analytics</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const EnterpriseIntegration = () => {
  return (
    <div className="section-inner">
      <h2>Enterprise Integration</h2>
      <div className="twobytwo">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Authentication & Access</h3>
          <ul>
            <li>SAML 2.0 support</li>
            <li>OAuth 2.0</li>
            <li>Active Directory integration</li>
            <li>Custom SSO solutions</li>
            <li>Multi-factor authentication</li>
            <li>JWT support</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Data Management</h3>
          <ul>
            <li>Custom data retention</li>
            <li>Data export APIs</li>
            <li>Backup solutions</li>
            <li>Archive access</li>
            <li>Recovery tools</li>
            <li>Migration assistance</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const EnterpriseSupport = () => {
  return (
    <div className="section-inner">
      <h2>Enterprise Support</h2>
      <div className="twobytwo">
        <ParallaxBox x={-50} y={0} className="benefit-column bg-orange">
          <h3>Dedicated Success</h3>
          <ul>
            <li>Named account manager</li>
            <li>Technical account manager</li>
            <li>Implementation consulting</li>
            <li>24/7 priority support</li>
            <li>Custom SLAs</li>
            <li>Training programs</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column bg-orange">
          <h3>Professional Services</h3>
          <ul>
            <li>Custom implementation</li>
            <li>Migration assistance</li>
            <li>Integration support</li>
            <li>Advanced training</li>
            <li>Custom development</li>
            <li>Architecture review</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const ScalePerformance = () => {
  return (
    <div className="section-inner">
      <h2>Scale & Performance</h2>
      <div className="twobytwo">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Infrastructure</h3>
          <ul>
            <li>Global CDN</li>
            <li>Auto-scaling</li>
            <li>Load balancing</li>
            <li>High availability</li>
            <li>Disaster recovery</li>
            <li>Performance monitoring</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Capacity</h3>
          <ul>
            <li>Unlimited events</li>
            <li>Unlimited users</li>
            <li>Unlimited projects</li>
            <li>Custom throughput</li>
            <li>Dedicated infrastructure options</li>
            <li>Custom scaling solutions</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const EnterpriseAgreements = () => {
  return (
    <div className="section-inner">
      <h2>Enterprise Agreements</h2>
      <div className="twobytwo">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Business Terms</h3>
          <ul>
            <li>Custom contracts</li>
            <li>SLA guarantees</li>
            <li>IP protection</li>
            <li>Liability coverage</li>
            <li>Custom billing</li>
            <li>Payment terms</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Support Plans</h3>
          <ul>
            <li>Technical support SLAs</li>
            <li>Response time guarantees</li>
            <li>Emergency support</li>
            <li>On-site support options</li>
            <li>Custom training</li>
            <li>Documentation access</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const EnterpriseDeployment = () => {
  return (
    <div className="section-inner">
      <h2>Enterprise Deployment</h2>
      <div className="steps-container">
        <div className="fivebyfive">
          <ParallaxBox x={0} y={20} className="five-column">
            <h3>Requirements assessment</h3>
          </ParallaxBox>
          <ParallaxBox x={0} y={20} className="five-column">
            <h3>Implementation planning</h3>
          </ParallaxBox>
          <ParallaxBox x={0} y={20} className="five-column">
            <h3>Custom configuration</h3>
          </ParallaxBox>
          <ParallaxBox x={0} y={20} className="five-column">
            <h3>Team training</h3>
          </ParallaxBox>
          <ParallaxBox x={0} y={20} className="five-column">
            <h3>Go-live support</h3>
          </ParallaxBox>
        </div>
      </div>
    </div>
  );
};

const GetStarted = () => {
  return (
    <div className="final-cta">
      <div className="section-inner">
        <h2>Ready for Enterprise-Grade Analytics?</h2>
        <div className="cta-buttons">
          <ScrollToTopButton to="/requestcontact" className="primary-cta">
            Contact Enterprise Sales
          </ScrollToTopButton>
          <ScrollToTopButton to="https://app.teraace.com" className="secondary-cta">
            Create Account Today
          </ScrollToTopButton>
        </div>
      </div>
    </div>
  );
};

const EnterpriseReady = () => {
  return (
    <div className="homepage">
      <HeroBanner />
      <AdvancedControls />
      <EnterpriseIntegration />
      <EnterpriseSupport />
      <ScalePerformance />
      <EnterpriseAgreements />
      <EnterpriseDeployment />
      <GetStarted />
    </div>
  );
};

const EnterprisePage = () => {
  return (
    <ParallaxProvider>
      <EnterpriseReady />
    </ParallaxProvider>
  );
};

export default EnterprisePage;

// import React from 'react';
// import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
// import { motion, useInView } from 'framer-motion';
// import ScrollToTopButton from '../components/ScrollButton';
// import SEO from '../components/SEO';

// const ParallaxBox = ({ children, x, y, className }) => {
//   const ref = React.useRef();
//   const isInView = useInView(ref, { amount: 0.5 });

//   return (
//     <motion.div
//       ref={ref}
//       initial={{ opacity: 0, x, y }}
//       animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
//       transition={{ duration: 0.5, ease: 'easeInOut' }}
//       className={className}
//     >
//       {children}
//     </motion.div>
//   );
// };

// const HeroBanner = () => {
//   return (
//     <ParallaxBanner style={{ aspectRatio: '2 / 1' }} className="parallax-hero">
//       <ParallaxBannerLayer
//         image="/images/example.webp"
//         speed={-20}
//         expanded={true}
//         className="parallax-banner-layer"
//       />
//       <ParallaxBannerLayer speed={5}>
//         <div className="parallax-hero-text-div">
//           <h1 className="parallax-hero-text">
//             Built for Enterprise Scale<br/>
//             <em>Security, Control, Performance</em>
//           </h1>
//           <h3 className="parallax-hero-subtext">
//             Teraace delivers the security, scalability, and control that enterprise organizations demand.
//           </h3>
//         </div>
//       </ParallaxBannerLayer>
//     </ParallaxBanner>
//   );
// };

// const AdvancedControls = () => {
//   return (
//     <div className="section-inner">
//       <h2>Enterprise Features</h2>
//       <div className="twobytwo">
//         <ParallaxBox x={-50} y={0} className="benefit-column">
//           <h3>Administration</h3>
//           <ul>
//             <li>Centralized user management</li>
//             <li>Custom role definitions</li>
//             <li>Access control policies</li>
//             <li>Audit logging</li>
//             <li>Usage reporting</li>
//             <li>License management</li>
//           </ul>
//         </ParallaxBox>
        
//         <ParallaxBox x={50} y={0} className="benefit-column">
//           <h3>Team Management</h3>
//           <ul>
//             <li>Multiple team support</li>
//             <li>Cross-team collaboration</li>
//             <li>Project isolation</li>
//             <li>Resource sharing controls</li>
//             <li>Team-level analytics</li>
//           </ul>
//         </ParallaxBox>
//       </div>
//     </div>
//   );
// };

// const EnterpriseIntegration = () => {
//   return (
//     <div className="section-inner">
//       <h2>Enterprise Integration</h2>
//       <div className="twobytwo">
//         <ParallaxBox x={-50} y={0} className="benefit-column">
//           <h3>Authentication</h3>
//           <ul>
//             <li>SAML 2.0 support</li>
//             <li>OAuth 2.0</li>
//             <li>Active Directory integration</li>
//             <li>Custom SSO solutions</li>
//             <li>Multi-factor authentication</li>
//             <li>JWT support</li>
//           </ul>
//         </ParallaxBox>
        
//         <ParallaxBox x={50} y={0} className="benefit-column">
//           <h3>Data Management</h3>
//           <ul>
//             <li>Custom data retention</li>
//             <li>Data export APIs</li>
//             <li>Backup solutions</li>
//             <li>Archive access</li>
//             <li>Recovery tools</li>
//             <li>Migration assistance</li>
//           </ul>
//         </ParallaxBox>
//       </div>
//     </div>
//   );
// };

// const EnterpriseSupport = () => {
//   return (
//     <div className="section-inner">
//       <h2>Enterprise Support</h2>
//       <div className="twobytwo">
//         <ParallaxBox x={-50} y={0} className="benefit-column">
//           <h3>Dedicated Success</h3>
//           <ul>
//             <li>Named account manager</li>
//             <li>Technical account manager</li>
//             <li>Implementation consulting</li>
//             <li>24/7 priority support</li>
//             <li>Custom SLAs</li>
//             <li>Training programs</li>
//           </ul>
//         </ParallaxBox>
        
//         <ParallaxBox x={50} y={0} className="benefit-column">
//           <h3>Professional Services</h3>
//           <ul>
//             <li>Custom implementation</li>
//             <li>Migration assistance</li>
//             <li>Integration support</li>
//             <li>Advanced training</li>
//             <li>Custom development</li>
//             <li>Architecture review</li>
//           </ul>
//         </ParallaxBox>
//       </div>
//     </div>
//   );
// };

// const ScalePerformance = () => {
//   return (
//     <div className="section-inner">
//       <h2>Scale & Performance</h2>
//       <div className="twobytwo">
//         <ParallaxBox x={-50} y={0} className="benefit-column">
//           <h3>Infrastructure</h3>
//           <ul>
//             <li>Global CDN</li>
//             <li>Auto-scaling</li>
//             <li>Load balancing</li>
//             <li>High availability</li>
//             <li>Disaster recovery</li>
//             <li>Performance monitoring</li>
//           </ul>
//         </ParallaxBox>
        
//         <ParallaxBox x={50} y={0} className="benefit-column">
//           <h3>Capacity</h3>
//           <ul>
//             <li>Unlimited events</li>
//             <li>Unlimited users</li>
//             <li>Unlimited projects</li>
//             <li>Custom throughput</li>
//             <li>Dedicated infrastructure options</li>
//             <li>Custom scaling solutions</li>
//           </ul>
//         </ParallaxBox>
//       </div>
//     </div>
//   );
// };

// const ComplianceSecurity = () => {
//   return (
//     <div className="section-inner">
//       <h2>Compliance & Security</h2>
//       <div className="twobytwo">
//         <ParallaxBox x={-50} y={0} className="benefit-column">
//           <h3>Enterprise Security</h3>
//           <ul>
//             <li>Custom security reviews</li>
//             <li>Penetration testing</li>
//             <li>Security customization</li>
//             <li>Custom security policies</li>
//             <li>VPC deployment options</li>
//             <li>Private cloud options</li>
//           </ul>
//         </ParallaxBox>
        
//         <ParallaxBox x={50} y={0} className="benefit-column">
//           <h3>Compliance Support</h3>
//           <ul>
//             <li>Custom compliance reports</li>
//             <li>Audit assistance</li>
//             <li>Compliance documentation</li>
//             <li>Security questionnaires</li>
//             <li>Risk assessments</li>
//             <li>Custom agreements</li>
//           </ul>
//         </ParallaxBox>
//       </div>
//     </div>
//   );
// };

// const EnterpriseAgreements = () => {
//   return (
//     <div className="section-inner">
//       <h2>Enterprise Agreements</h2>
//       <div className="twobytwo">
//         <ParallaxBox x={-50} y={0} className="benefit-column">
//           <h3>Business Terms</h3>
//           <ul>
//             <li>Custom contracts</li>
//             <li>SLA guarantees</li>
//             <li>IP protection</li>
//             <li>Liability coverage</li>
//             <li>Custom billing</li>
//             <li>Payment terms</li>
//           </ul>
//         </ParallaxBox>
        
//         <ParallaxBox x={50} y={0} className="benefit-column">
//           <h3>Support Plans</h3>
//           <ul>
//             <li>Technical support SLAs</li>
//             <li>Response time guarantees</li>
//             <li>Emergency support</li>
//             <li>On-site support options</li>
//             <li>Custom training</li>
//             <li>Documentation access</li>
//           </ul>
//         </ParallaxBox>
//       </div>
//     </div>
//   );
// };

// const SuccessStories = () => {
//   return (
//     <div className="section-inner">
//       <h2>Success Stories</h2>
//       <div className="benefit-column">
//         <h3>Enterprise Case Studies</h3>
//         <ul>
//           <li>Fortune 500 implementations</li>
//           <li>Scale examples</li>
//           <li>Success metrics</li>
//           <li>ROI analysis</li>
//           <li>Migration stories</li>
//           <li>Integration examples</li>
//         </ul>
//       </div>
//     </div>
//   );
// };

// const GetStarted = () => {
//   return (
//     <div className="final-cta">
//       <div className="section-inner">
//         <h2>Ready for Enterprise-Grade Analytics?</h2>
//         <div className="cta-buttons">
//           <ScrollToTopButton to="/enterprise/contact" className="primary-cta">
//             Contact Enterprise Sales
//           </ScrollToTopButton>
//           <ScrollToTopButton to="/enterprise/overview" className="secondary-cta">
//             Download Enterprise Overview
//           </ScrollToTopButton>
//         </div>
//       </div>
//     </div>
//   );
// };

// const EnterpriseReady = () => {
//   return (
//     <div className="homepage">
//       <HeroBanner />
//       <AdvancedControls />
//       <EnterpriseIntegration />
//       <EnterpriseSupport />
//       <ScalePerformance />
//       <ComplianceSecurity />
//       <EnterpriseAgreements />
//       <SuccessStories />
//       <GetStarted />
//       <div className="footer">
//         <p className="text-center text-sm mt-8">©2025 HYE Partners, LLC.</p>
//       </div>
//       <SEO
//         title="Enterprise Ready - Teraace"
//         description="Enterprise-grade analytics platform with advanced security, scalability, and control for organizations that demand excellence."
//         url="/enterprise"
//       />
//     </div>
//   );
// };

// const EnterprisePage = () => {
//   return (
//     <ParallaxProvider>
//       <EnterpriseReady />
//     </ParallaxProvider>
//   );
// };

// export default EnterprisePage;