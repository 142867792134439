import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import GlowBox from '../../components/GlowBox';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../../components/ScrollButton';

const ParallaxGlowBox = ({ children, glowColor, bgColor, x, y }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        exit="exit"
        variants={fadeInOut(x, y)}
      >
        <GlowBox glowColor={glowColor} bgColor={bgColor}>
          {children}
        </GlowBox>
      </motion.div>
    );
};

const fadeInOut = (x, y) => ({
    hidden: { opacity: 0, x, y },
    visible: {
      opacity: 1,
      x: '0%',
      y: '0%',
      transition: { duration: 0.5, ease: 'easeInOut' }
    },
    exit: { opacity: 0, x: -x, y: -y, transition: { duration: 0.5, ease: 'easeInOut' } }
});

const ParallaxBox = ({ children, x, y, className }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial={{ opacity: 0, x, y }}
        animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        className={className}
      >
        {children}
      </motion.div>
    );
};

const HeroBanner = () => {
  return (
    <ParallaxBanner className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/Teraace-bg-043.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            Revenue Optimization<br/>
            <em>Maximize Revenue Through User Understanding</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            Transform user behavior patterns into revenue opportunities with ML-powered insights.
          </h3>
          <div className="hero-cta-container">
            <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
              Start Optimizing Revenue
            </ScrollToTopButton>
            <ScrollToTopButton to="/requestdemo" className="secondary-cta">
              See Revenue Analysis
            </ScrollToTopButton>
          </div>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

const ChallengesSection = () => {
  return (
    <div className="section-inner challenge-section xy-pad">
      <h2 className="standard-pad no-btm">Revenue Challenges</h2>
      <div className="twobytwo">
        <ParallaxBox x={-50} y={0} className="panel-left">
          <div className="panel-content">
            <h3>Common Issues</h3>
            <ul>
              <li>Missed expansion opportunities</li>
              <li>Unclear upgrade timing</li>
              <li>Value demonstration gaps</li>
              <li>Pricing optimization needs</li>
              <li>Feature-value alignment</li>
            </ul>
          </div>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="panel-right">
          <div className="panel-content">
            <h3>Teraace's Solutions</h3>
            <ul>
              <li>Opportunity prediction</li>
              <li>Timing optimization</li>
              <li>Value pattern detection</li>
              <li>Price point analysis</li>
              <li>Impact forecasting</li>
            </ul>
          </div>
        </ParallaxBox>
      </div>
    </div>
  );
};

const FeaturesSection = () => {
  return (
    <div className="section-inner">
      <h2>Key Features</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="feature-column">
          <h3>Revenue Predictor</h3>
          <ul>
            <li>Opportunity detection</li>
            <li>Impact forecasting</li>
            <li>Pattern analysis</li>
            <li>ROI tracking</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="feature-column">
          <h3>Value Analyzer</h3>
          <ul>
            <li>Usage pattern tracking</li>
            <li>Feature value analysis</li>
            <li>Pricing optimization</li>
            <li>Segment insights</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="feature-column">
          <h3>Impact Optimizer</h3>
          <ul>
            <li>Timing recommendations</li>
            <li>Action prioritization</li>
            <li>Value demonstration</li>
            <li>Success tracking</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const OptimizationSection = () => {
  return (
    <div className="product-showcase">
      <div className="section-inner">
        <div className="showcase-content">
          <div className="showcase-features">
            <h2>Data-Driven Approach</h2>
            <ul>
              <li>Identify revenue patterns</li>
              <li>Optimize timing</li>
              <li>Maximize value delivery</li>
              <li>Track impact</li>
            </ul>
          </div>
          <div className="showcase-preview">
            <img src="/images/Teraace-bg-045.webp" alt="Revenue Optimization" className="rounded-lg shadow-xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

const MetricsSection = () => {
  return (
    <div className="section-inner">
      <h2>Success Metrics</h2>
      <div className="fivebyfive">
        <ParallaxBox x={0} y={20} className="metric-item">
          <h3>Revenue growth</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="metric-item">
          <h3>Expansion rates</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="metric-item">
          <h3>Feature adoption</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="metric-item">
          <h3>Customer lifetime value</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="metric-item">
          <h3>Return on investment</h3>
        </ParallaxBox>
      </div>
    </div>
  );
};

const GetStartedSection = () => {
  return (
    <div className="section-inner bg-orange top-pad">
      <h2>Quick Start Guide</h2>
      <div className="fourbyfour">
        <ParallaxBox x={0} y={20} className="step-item">
          <h3>1. Connect revenue data</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="step-item">
          <h3>2. Analyze patterns</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="step-item">
          <h3>3. Identify opportunities</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="step-item">
          <h3>4. Optimize and track</h3>
        </ParallaxBox>
      </div>
    </div>
  );
};

const RevenueSection = () => {
  return (
    <div className="homepage">
      <HeroBanner />
      <ChallengesSection />
      <FeaturesSection />
      <OptimizationSection />
      <MetricsSection />
      <GetStartedSection />
      <div className="final-cta">
        <div className="section-inner">
          <h2>Start Optimizing Your Revenue Today</h2>
          <p>Transform your revenue strategy with Teraace's optimization</p>
          <div className="cta-buttons">
            <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">Start Optimizing Revenue</ScrollToTopButton>
            <ScrollToTopButton to="/requestdemo" className="secondary-cta">See Revenue Analysis</ScrollToTopButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const RevenueOptimization = () => {
  return (
    <ParallaxProvider>
      <RevenueSection />
    </ParallaxProvider>
  );
};

export default RevenueOptimization;