import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../../components/ScrollButton';
// import SEO from '../../components/SEO';

const ParallaxBox = ({ children, x, y, className }) => {
  const ref = React.useRef();
  const isInView = useInView(ref, { amount: 0.5 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x, y }}
      animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

const HeroBanner = () => {
  return (
    <ParallaxBanner className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/Teraace-bg-129.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            Discover the Real Patterns<br/>
            <em>Behind Growth</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            Move beyond surface metrics to understand what truly drives activation, retention, and expansion.
          </h3>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

const KeyBenefits = () => {
  return (
    <div className="section-inner">
      <h2>Key Benefits</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Surface Growth Opportunities</h3>
          <ul>
            <li>Identify key activation triggers</li>
            <li>Discover retention drivers</li>
            <li>Spot expansion opportunities</li>
            <li>Predict churn patterns before they happen</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Optimize User Journeys</h3>
          <ul>
            <li>Map paths to successful activation</li>
            <li>Understand retention behaviors</li>
            <li>Track expansion indicators</li>
            <li>Identify friction points automatically</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Drive Measurable Results</h3>
          <ul>
            <li>Monitor real-time activation rates</li>
            <li>Track cohort retention patterns</li>
            <li>Measure feature adoption impact</li>
            <li>Forecast growth metrics</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const GrowthFeatures = () => {
  return (
    <div className="section-inner">
      <h2>Features for Growth Teams</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Growth Pattern Explorer</h3>
          <ul>
            <li>Automatic success pattern detection</li>
            <li>Cohort behavior analysis</li>
            <li>Leading indicator identification</li>
            <li>Growth opportunity alerting</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Journey Predictor</h3>
          <ul>
            <li>Forecast user paths</li>
            <li>Identify activation barriers</li>
            <li>Predict churn risk</li>
            <li>Recommend intervention points</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Impact Analytics</h3>
          <ul>
            <li>Measure initiative impact</li>
            <li>Compare segment performance</li>
            <li>Track experiment results</li>
            <li>Calculate growth ROI</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const Applications = () => {
  return (
    <div className="section-inner">
      <h2>Real-World Applications</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Activation Optimization</h3>
          <ul>
            <li>Identify successful activation paths</li>
            <li>Reduce time to value</li>
            <li>Optimize onboarding flows</li>
            <li>Increase conversion rates</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Retention Improvement</h3>
          <ul>
            <li>Predict churn risk</li>
            <li>Identify retention drivers</li>
            <li>Optimize engagement loops</li>
            <li>Increase lifetime value</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Expansion Strategy</h3>
          <ul>
            <li>Spot upgrade opportunities</li>
            <li>Identify expansion patterns</li>
            <li>Optimize upgrade timing</li>
            <li>Drive account growth</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const GetStarted = () => {
  return (
    <div className="final-cta">
      <div className="section-inner">
        <h2>Ready to Transform Your Growth Strategy?</h2>
        <div className="cta-buttons">
          <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
            Start Free Trial
          </ScrollToTopButton>
          <ScrollToTopButton to="/requestdemo" className="secondary-cta">
            See Growth Demo
          </ScrollToTopButton>
        </div>
      </div>
    </div>
  );
};

const GrowthTeams = () => {
  return (
    <div className="homepage">
      <HeroBanner />
      <KeyBenefits />
      <GrowthFeatures />
      <Applications />
      <GetStarted />
    </div>
  );
};

const GrowthTeamsPage = () => {
  return (
    <ParallaxProvider>
      <GrowthTeams />
    </ParallaxProvider>
  );
};

export default GrowthTeamsPage;