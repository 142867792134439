import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../../components/ScrollButton';
// import SEO from '../../components/SEO';

const ParallaxBox = ({ children, x, y, className }) => {
  const ref = React.useRef();
  const isInView = useInView(ref, { amount: 0.5 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x, y }}
      animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

const HeroBanner = () => {
  return (
    <ParallaxBanner className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/Teraace-bg-071.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            Guide Users to Success<br/>
            <em>Through Their Preferred Paths</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            Understand, predict, and optimize user journeys to drive successful outcomes.
          </h3>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

const KeyBenefits = () => {
  return (
    <div className="section-inner">
      <h2>Key Benefits</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Proactive Success Management</h3>
          <ul>
            <li>Identify at-risk accounts early</li>
            <li>Predict potential challenges</li>
            <li>Spot success opportunities</li>
            <li>Automate health monitoring</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Journey Understanding</h3>
          <ul>
            <li>Map successful user paths</li>
            <li>Identify common obstacles</li>
            <li>Understand adoption patterns</li>
            <li>Track success milestones</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Efficient Intervention</h3>
          <ul>
            <li>Prioritize accounts automatically</li>
            <li>Time interventions perfectly</li>
            <li>Measure impact of actions</li>
            <li>Scale success strategies</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const CSFeatures = () => {
  return (
    <div className="section-inner">
      <h2>Features for CS Teams</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Health Predictor</h3>
          <ul>
            <li>Real-time health scoring</li>
            <li>Churn risk prediction</li>
            <li>Success probability forecasting</li>
            <li>Automated alerting</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Journey Navigator</h3>
          <ul>
            <li>User path visualization</li>
            <li>Milestone tracking</li>
            <li>Behavior pattern analysis</li>
            <li>Success path mapping</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Impact Measurement</h3>
          <ul>
            <li>Intervention tracking</li>
            <li>Success metrics monitoring</li>
            <li>ROI calculation</li>
            <li>Team performance analytics</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const Applications = () => {
  return (
    <div className="section-inner">
      <h2>Real-World Applications</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Account Management</h3>
          <ul>
            <li>Monitor account health</li>
            <li>Track adoption progress</li>
            <li>Identify expansion opportunities</li>
            <li>Measure account success</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Training Optimization</h3>
          <ul>
            <li>Customize training paths</li>
            <li>Track learning progress</li>
            <li>Identify knowledge gaps</li>
            <li>Optimize content delivery</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Success Planning</h3>
          <ul>
            <li>Create data-driven success plans</li>
            <li>Set realistic milestones</li>
            <li>Track progress automatically</li>
            <li>Adjust based on behavior</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const GetStarted = () => {
  return (
    <div className="final-cta">
      <div className="section-inner">
        <h2>Ready to Transform Your Customer Success Strategy?</h2>
        <div className="cta-buttons">
          <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
            Start Free Trial
          </ScrollToTopButton>
          <ScrollToTopButton to="/requestdemo" className="secondary-cta">
            See CS Demo
          </ScrollToTopButton>
        </div>
      </div>
    </div>
  );
};

const CustomerSuccess = () => {
  return (
    <div className="homepage">
      <HeroBanner />
      <KeyBenefits />
      <CSFeatures />
      <Applications />
      <GetStarted />
    </div>
  );
};

const CustomerSuccessPage = () => {
  return (
    <ParallaxProvider>
      <CustomerSuccess />
    </ParallaxProvider>
  );
};

export default CustomerSuccessPage;