import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const SEO = ({ 
  title = 'Teraace', 
  description = 'Machine learning-powered product intelligence', 
  url = '/' 
}) => {
  // Ensure title and description are strings
  const safeTitle = String(title);
  const safeDescription = String(description);

  // Construct a full URL, handling edge cases
  const canonicalUrl = url.startsWith('http') 
    ? url 
    : `https://teraace.com${url.startsWith('/') ? url : '/' + url}`;

  return (
    <Helmet>
      <title>{safeTitle}</title>
      <meta name="description" content={safeDescription} />
      <link rel="canonical" href={canonicalUrl} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={safeTitle} />
      <meta property="og:description" content={safeDescription} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={canonicalUrl} />
      <meta property="twitter:title" content={safeTitle} />
      <meta property="twitter:description" content={safeDescription} />
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
};

export default SEO;

// import React from 'react';
// import PropTypes from 'prop-types';
// import { Helmet } from 'react-helmet';

// const SEO = ({ title, description, url }) => (
//   <Helmet>
//     <title>{title}</title>
//     <meta name="description" content={description} />
//     <link rel="canonical" href={url} />
//   </Helmet>
// );

// SEO.propTypes = {
//   title: PropTypes.string.isRequired,
//   description: PropTypes.string,
//   url: PropTypes.string,
// };

// SEO.defaultProps = {
//   description: '',
//   url: '',
// };

// export default SEO;
