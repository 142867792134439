import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const Sidebar = ({ onNavigate }) => {
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const submenuTimerRef = useRef(null);

  const mainPages = [
    { 
      name: 'Product', 
      route: '/product',
      subPages: [
        { name: 'Product Overview', route: '/product', isOverview: true },
        { name: 'Insights Pages', route: '/insights' },
        { name: 'Exploration Pages', route: '/exploration' },
        { name: 'Validation Pages', route: '/validation' },
        { name: 'Action Pages', route: '/action' }
      ]
    },
    { 
      name: 'Platform', 
      route: '/platform',
      subPages: [
        { name: 'Platform Overview', route: '/platform', isOverview: true },
        { name: 'Data Collection', route: '/datacollection' },
        { name: 'Security & Privacy', route: '/security' },
        { name: 'Enterprise', route: '/enterprise' }
      ]
    },
    {
      name: 'Solutions',
      route: '/solutions',
      subPages: [
        { name: 'Solutions Overview', route: '/solutions', isOverview: true },
        { name: 'For Product', route: '/team-product' },
        { name: 'For Growth', route: '/team-growth' },
        { name: 'For Customer Success', route: '/team-customersuccess' },
        { name: 'For User Experience', route: '/team-userexperience' },
        { name: 'For Executives', route: '/executives' }
      ]
    },
    {
      name: 'Use Cases',
      route: '/use-cases',
      subPages: [
        { name: 'Journey Optimization', route: '/journeyoptimization' },
        { name: 'Churn Prevention', route: '/churnprevention' },
        { name: 'Feature Adoption', route: '/featureadoption' },
        { name: 'Onboarding Optimization', route: '/onboardingoptimization' },
        { name: 'Revenue Optimization', route: '/revenueoptimization' },
        { name: 'Product-Led Growth', route: '/productledgrowth' }
      ]
    },
    {
      name: 'Resources',
      route: '/resources',
      subPages: [],
    },
        {
      name: 'Request Demo',
      route: '/requestdemo',
      subPages: [],
    },
    {
      name: 'Create Account',
      route: 'https://app.teraace.com/signup',
      subPages: [],
    }
  ];

  const handleMouseEnter = (pageName) => {
    clearTimeout(submenuTimerRef.current);
    setActiveSubmenu(pageName);
  };

  const handleMouseLeave = (event) => {
    const isMovingToNavItem = event.relatedTarget instanceof Element && 
      event.relatedTarget.closest('.nav-item');
    
    if (!isMovingToNavItem) {
      submenuTimerRef.current = setTimeout(() => {
        setActiveSubmenu(null);
      }, 200);
    }
  };



  return (
    <div className="sidebar-container">
      <div className="sidebar-logo">
        <NavLink to="/">
          <img src="/logo-Teraace-250.png" alt="logo" className="logo-image" />
        </NavLink>
      </div>

      {/* Mobile Menu Button */}
      <button 
        className="mobile-menu-button md:hidden"
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      >
        <span className="text-2xl" aria-label="Menu">☰</span>
      </button>
      
      {/* Desktop Navigation */}
      <nav className="sidebar-link-container hidden md:flex">
        {mainPages.map((page, index) => (
          <div 
            key={index}
            className="nav-item"
            onMouseEnter={() => handleMouseEnter(page.name)}
            onMouseLeave={handleMouseLeave}
          >
            <NavLink
              to={page.route}
              className={({ isActive }) => `sidebar-link ${isActive ? 'active' : ''}`}
              onClick={() => onNavigate?.(page.route.replace('/', ''))}
            >
              {page.name}
            </NavLink>
            
            {activeSubmenu === page.name && page.subPages.length > 0 && (
              <div 

                className="submenu"
                onMouseEnter={() => clearTimeout(submenuTimerRef.current)}
                onMouseLeave={handleMouseLeave}
              >
                {page.subPages.map((subPage, subIndex) => (
                  <NavLink
                    key={subIndex}
                    to={subPage.route}
                    className={({ isActive }) => 
                      `submenu-link ${isActive ? 'active' : ''} ${subPage.isOverview ? 'overview-link' : ''}`
                    }
                    onClick={() => {
                      onNavigate?.(subPage.route.replace('/', ''));
                      setActiveSubmenu(null);
                    }}
                  >
                    {subPage.name}
                  </NavLink>
                ))}
              </div>
            )}
          </div>
        ))}
      </nav>

      {/* Mobile Menu */}
      <div className={`mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>
        <button 
          className="mobile-close-button"
          onClick={() => setIsMobileMenuOpen(false)}
        >
          <span aria-label="Close">×</span>
        </button>
        <div className="mobile-menu-content">
          {mainPages.map((page, index) => (
            <div key={index} className="mobile-nav-item">
              <NavLink
                to={page.route}
                className={({ isActive }) => `sidebar-link ${isActive ? 'active' : ''}`}
                onClick={() => {
                  onNavigate?.(page.route.replace('/', ''));
                  if (!page.subPages.length) setIsMobileMenuOpen(false);
                }}
              >
                {page.name}
              </NavLink>
              {page.subPages.length > 0 && (
                <div className="mobile-submenu">
                  {page.subPages.map((subPage, subIndex) => (
                    <NavLink
                      key={subIndex}
                      to={subPage.route}
                      className={({ isActive }) => 
                        `submenu-link ${isActive ? 'active' : ''} ${subPage.isOverview ? 'overview-link' : ''}`
                      }
                      onClick={() => {
                        onNavigate?.(subPage.route.replace('/', ''));
                        setIsMobileMenuOpen(false);
                      }}
                    >
                      {subPage.name}
                    </NavLink>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;



// import React, { useState, useRef, useEffect } from 'react';
// import { NavLink } from 'react-router-dom';

// const Sidebar = ({ onNavigate }) => {
//   const [activeSubmenu, setActiveSubmenu] = useState(null);
//   const submenuRef = useRef(null);
//   const submenuTimerRef = useRef(null);

//   const mainPages = [
//     { 
//       name: 'Product', 
//       route: '/product',
//       subPages: [
//         { name: 'Product Overview', route: '/product', isOverview: true },
//         { name: 'Insights Pages', route: '/insights' },
//         { name: 'Exploration Pages', route: '/exploration' },
//         { name: 'Validation Pages', route: '/validation' }
//       ]
//     },
//     { 
//       name: 'Platform', 
//       route: '/platform',
//       subPages: [
//         { name: 'Platform Overview', route: '/platform', isOverview: true },
//         { name: 'Data Collection', route: '/datacollection' },
//         { name: 'Security & Privacy', route: '/security' },
//         { name: 'Enterprise', route: '/enterprise' }
//       ]
//     },
//     {
//       name: 'Solutions',
//       route: '/solutions',
//       subPages: [
//         { name: 'Solutions Overview', route: '/solutions', isOverview: true },
//         { name: 'For Product', route: '/team-product' },
//         { name: 'For Growth', route: '/team-growth' },
//         { name: 'For Customer Success', route: '/team-customersuccess' },
//         { name: 'For User Experience', route: '/team-userexperience' },
//         { name: 'For Executives', route: '/executives' }
//       ]
//     },
//     {
//       name: 'Use Cases',
//       route: '/use-cases',
//       subPages: [
//         { name: 'Journey Optimization', route: '/journeyoptimization' },
//         { name: 'Churn Prevention', route: '/churnprevention' },
//         { name: 'Feature Adoption', route: '/featureadoption' },
//         { name: 'Onboarding Optimization', route: '/onboardingoptimization' },
//         { name: 'Revenue Optimization', route: '/revenueoptimization' },
//         { name: 'Product-Led Growth', route: '/productledgrowth' }
//       ]
//     },
//     {
//       name: 'Request Demo',
//       route: '/requestdemo',
//       subPages: [],
//     },
//     {
//       name: 'Create Account',
//       route: 'https://app.teraace.com/signup',
//       subPages: [],
//     }
//   ];
//   const handleMouseEnter = (pageName) => {
//     clearTimeout(submenuTimerRef.current);
//     setActiveSubmenu(pageName);
//   };
  
//   // const handleMouseLeave = (event) => {
//   //   if (!event.relatedTarget || !event.relatedTarget.closest('.nav-item')) {
//   //     submenuTimerRef.current = setTimeout(() => {
//   //       setActiveSubmenu(null);
//   //     }, 200);
//   //   }
//   // };

//   const handleMouseLeave = (event) => {
//     // First check if relatedTarget exists and is an Element
//     const isMovingToNavItem = event.relatedTarget instanceof Element && 
//       event.relatedTarget.closest('.nav-item');
    
//     if (!isMovingToNavItem) {
//       submenuTimerRef.current = setTimeout(() => {
//         setActiveSubmenu(null);
//       }, 200);
//     }
//   };

//   useEffect(() => {
//     if (activeSubmenu && submenuRef.current) {
//       const submenu = submenuRef.current;
//       const rect = submenu.getBoundingClientRect();
//       const viewportWidth = window.innerWidth;

//       if (rect.right > viewportWidth) {
//         submenu.style.left = "auto";
//         submenu.style.right = "0";
//         submenu.style.transform = "translateX(0)";
//       } else if (rect.left < 0) {
//         submenu.style.left = "0";
//         submenu.style.transform = "translateX(0)";
//       } else {
//         submenu.style.left = "50%";
//         submenu.style.transform = "translateX(-50%)";
//       }
//     }
//   }, [activeSubmenu]);

//   return (
//     <div className="sidebar-container">
//       <div className="sidebar-logo">
//         <NavLink to="/">
//           <img src="/logo-Teraace-250.png" alt="logo" className="logo-image" />
//         </NavLink>
//       </div>
      
//       <nav className="sidebar-link-container">
//         {mainPages.map((page, index) => (
//           <div 
//             key={index}
//             className="nav-item"
//             onMouseEnter={() => handleMouseEnter(page.name)}
//             onMouseLeave={handleMouseLeave}
//           >
//             <NavLink
//               to={page.route}
//               className={({ isActive }) => `sidebar-link ${isActive ? 'active' : ''}`}
//               onClick={() => onNavigate?.(page.route.replace('/', ''))}
//             >
//               {page.name}
//             </NavLink>
            
//             {activeSubmenu === page.name && (
//               <div 
//                 ref={submenuRef}
//                 className="submenu"
//                 onMouseEnter={() => clearTimeout(submenuTimerRef.current)}
//                 onMouseLeave={handleMouseLeave}
//                 style={{
//                   position: 'absolute',
//                   top: '100%',
//                   left: '50%',
//                   transform: 'translateX(-50%)'
//                 }}
//               >
//                 {page.subPages.map((subPage, subIndex) => (
//                   <NavLink
//                     key={subIndex}
//                     to={subPage.route}
//                     className={({ isActive }) => 
//                       `submenu-link ${isActive ? 'active' : ''} ${subPage.isOverview ? 'overview-link' : ''}`
//                     }
//                     onClick={() => {
//                       onNavigate?.(subPage.route.replace('/', ''));
//                       setActiveSubmenu(null);
//                     }}
//                   >
//                     {subPage.name}
//                   </NavLink>
//                 ))}
//               </div>
//             )}
//           </div>
//         ))}
//       </nav>
//     </div>
//   );
// };

// export default Sidebar;

