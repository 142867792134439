import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../../components/ScrollButton';

const ParallaxBox = ({ children, x, y, className }) => {
  const ref = React.useRef();
  const isInView = useInView(ref, { amount: 0.5 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x, y }}
      animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

const HeroBanner = () => {
  return (
    <ParallaxBanner className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/Teraace-bg-018.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            Machine Learning That <em>Understands Your Users</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            Transform complex user behavior into clear, actionable insights. Our ML-powered platform helps you discover, explore, validate, and automate responses to how users actually succeed with your product.
          </h3>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

const ProductFeatures = () => {
  return (
    <div className="section-inner">
      <h2>Complete User Understanding</h2>
      <div className="twobytwo">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Insights</h3>
          <p>Transform complex user data into clear understanding with ML-powered pattern recognition and analysis.</p>
          <ScrollToTopButton to="/insights" variant="subtle" className="learn-more bg-cran">
            Learn More →
          </ScrollToTopButton>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Exploration</h3>
          <p>Deep dive into user behavior patterns with interactive 3D visualizations and pattern discovery tools.</p>
          <ScrollToTopButton to="/exploration" variant="subtle" className="learn-more bg-cran">
            Learn More →
          </ScrollToTopButton>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Validation</h3>
          <p>Verify ML-discovered patterns against real user behavior with powerful validation tools.</p>
          <ScrollToTopButton to="/validation" variant="subtle" className="learn-more bg-cran">
            Learn More →
          </ScrollToTopButton>
        </ParallaxBox>

        <ParallaxBox x={50} y={50} className="benefit-column">
          <h3>Actions</h3>
          <p>Automatically respond to user behavior patterns with intelligent webhooks and third-party integrations.</p>
          <ScrollToTopButton to="/actions" variant="subtle" className="learn-more bg-cran">
            Learn More →
          </ScrollToTopButton>
        </ParallaxBox>
      </div>
    </div>
  );
};

const InsightsSection = () => {
  return (
    <div className="product-showcase bg-yt">
      <div className="section-inner">
        <div className="product-show-content">
          <div className="showcase-preview">
            <img src="/productgifs/Teraace-Trends.gif" alt="Insights Dashboard" className="rounded-lg shadow-xl" />
          </div>
          <div className="product-show-features">
            <h2>Powerful Insights</h2>
            <h3>See What Traditional Analytics Miss</h3>
            <ul>
              <li>Track trends and benchmarks across your entire product</li>
              <li>Analyze conversion funnels beyond basic tracking</li>
              <li>Discover optimal user success paths</li>
              <li>Receive real-time intelligence updates</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const ExplorationSection = () => {
  return (
    <div className="product-showcase">
      <div className="section-inner">
        <div className="product-show-content">
          <div className="product-show-features">
            <h2>Deep Exploration</h2>
            <h3>Understand Complex Behavior Patterns</h3>
            <ul>
              <li>Visualize user journeys in interactive 3D</li>
              <li>Discover hidden behavioral patterns</li>
              <li>Analyze feature importance and impact</li>
              <li>Map and optimize decision paths</li>
            </ul>
          </div>
          <div className="showcase-preview">
            <img src="/productgifs/Teraace-Path-Analyzer.gif" alt="Exploration Tools" className="rounded-lg shadow-xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

const ValidationSection = () => {
  return (
    <div className="product-showcase bg-orange">
      <div className="section-inner">
        <div className="product-show-content">
          <div className="showcase-preview">
            <img src="/productgifs/Teraace-User-Inspector.gif" alt="Validation Interface" className="rounded-lg shadow-xl" />
          </div>
          <div className="product-show-features">
            <h2>Pattern Validation</h2>
            <h3>Verify Against Real Behavior</h3>
            <ul>
              <li>Inspect individual user journeys in detail</li>
              <li>Ask questions in natural language</li>
              <li>Analyze journey flow probabilities</li>
              <li>Access processed behavioral data</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const ActionsSection = () => {
  return (
    <div className="product-showcase">
      <div className="section-inner">
        <div className="product-show-content">
          <div className="product-show-features">
            <h2>Automated Actions</h2>
            <h3>Turn Insights into Results</h3>
            <ul>
              <li>Configure intelligent webhooks based on user behavior</li>
              <li>Integrate with your existing tools and platforms</li>
              <li>Automate responses to user patterns</li>
              <li>Monitor and optimize automation performance</li>
            </ul>
          </div>
          <div className="showcase-preview">
            <img src="/productgifs/Teraace-Webhooks.gif" alt="Actions Dashboard" className="rounded-lg shadow-xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

const BenefitsSection = () => {
  return (
    <div className="section-inner">
      <h2>Why Choose Teraace</h2>
      <div className="twobytwo">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>ML-Powered Intelligence</h3>
          <ul>
            <li>Models that learn your unique patterns</li>
            <li>Automatic pattern discovery</li>
            <li>Predictive user insights</li>
            <li>Continuous learning and improvement</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Complete Understanding</h3>
          <ul>
            <li>End-to-end user journey visibility</li>
            <li>Cross-feature behavior analysis</li>
            <li>Multi-dimensional insights</li>
            <li>Real-time pattern detection</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Actionable Results</h3>
          <ul>
            <li>Clear visualization of complex patterns</li>
            <li>Natural language interface</li>
            <li>Direct data access</li>
            <li>Custom reporting capabilities</li>
          </ul>
        </ParallaxBox>

        <ParallaxBox x={50} y={50} className="benefit-column">
          <h3>Intelligent Automation</h3>
          <ul>
            <li>Automated pattern response</li>
            <li>Webhook integrations</li>
            <li>Third-party platform sync</li>
            <li>Performance monitoring</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const CTASection = () => {
  return (
    <div className="final-cta">
      <div className="section-inner">
        <h2>Ready to Understand Your Users?</h2>
        <p>Transform your product understanding with ML-powered behavioral analytics and automation.</p>
        <div className="cta-buttons">
          <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
            Get Started Free
          </ScrollToTopButton>
          <ScrollToTopButton to="/requestdemo" className="secondary-cta">
            Schedule Demo
          </ScrollToTopButton>
        </div>
      </div>
    </div>
  );
};

const ProductPage = () => {
  return (
    <div className="homepage">
      <HeroBanner />
      <ProductFeatures />
      <InsightsSection />
      <ExplorationSection />
      <ValidationSection />
      <ActionsSection />
      <BenefitsSection />
      <CTASection />
    </div>
  );
};

const Product = () => {
  return (
    <ParallaxProvider>
      <ProductPage />
    </ParallaxProvider>
  );
};

export default Product;




// import React from 'react';
// import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
// import { motion, useInView } from 'framer-motion';
// import ScrollToTopButton from '../../components/ScrollButton';

// const ParallaxBox = ({ children, x, y, className }) => {
//   const ref = React.useRef();
//   const isInView = useInView(ref, { amount: 0.5 });

//   return (
//     <motion.div
//       ref={ref}
//       initial={{ opacity: 0, x, y }}
//       animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
//       transition={{ duration: 0.5, ease: 'easeInOut' }}
//       className={className}
//     >
//       {children}
//     </motion.div>
//   );
// };

// const HeroBanner = () => {
//   return (
//     <ParallaxBanner className="parallax-hero">
//       <ParallaxBannerLayer
//         image="/images/example.webp"
//         speed={-20}
//         expanded={true}
//         className="parallax-banner-layer"
//       />
//       <ParallaxBannerLayer speed={5}>
//         <div className="parallax-hero-text-div">
//           <h1 className="parallax-hero-text">
//             Machine Learning That<br/>
//             <em>Understands Your Users</em>
//           </h1>
//           <h3 className="parallax-hero-subtext">
//             Transform complex user behavior into clear, actionable insights. Our ML-powered platform helps you discover, explore, and validate how users actually succeed with your product.
//           </h3>
//         </div>
//       </ParallaxBannerLayer>
//     </ParallaxBanner>
//   );
// };

// const ProductFeatures = () => {
//   return (
//     <div className="section-inner">
//       <h2>Complete User Understanding</h2>
//       <div className="threebythree">
//         <ParallaxBox x={-50} y={0} className="benefit-column">
//           <img src="/images/example.webp" alt="Insights Icon" className="team-icon" />
//           <h3>Insights</h3>
//           <p>Transform complex user data into clear understanding with ML-powered pattern recognition and analysis.</p>
//           <ScrollToTopButton to="/insights" variant="subtle" className="learn-more">
//             Learn More →
//           </ScrollToTopButton>
//         </ParallaxBox>
        
//         <ParallaxBox x={0} y={50} className="benefit-column">
//           <img src="/images/example.webp" alt="Exploration Icon" className="team-icon" />
//           <h3>Exploration</h3>
//           <p>Deep dive into user behavior patterns with interactive 3D visualizations and pattern discovery tools.</p>
//           <ScrollToTopButton to="/exploration" variant="subtle" className="learn-more">
//             Learn More →
//           </ScrollToTopButton>
//         </ParallaxBox>
        
//         <ParallaxBox x={50} y={0} className="benefit-column">
//           <img src="/images/example.webp" alt="Validation Icon" className="team-icon" />
//           <h3>Validation</h3>
//           <p>Verify ML-discovered patterns against real user behavior with powerful validation tools.</p>
//           <ScrollToTopButton to="/validation" variant="subtle" className="learn-more">
//             Learn More →
//           </ScrollToTopButton>
//         </ParallaxBox>
//       </div>
//     </div>
//   );
// };

// const InsightsSection = () => {
//   return (
//     <div className="product-showcase bg-yt">
//       <div className="section-inner">
//         <div className="product-show-content">
//           <div className="showcase-preview">
//             <img src="/images/example.webp" alt="Insights Dashboard" className="rounded-lg shadow-xl" />
//           </div>
//           <div className="product-show-features">
//             <h2>Powerful Insights</h2>
//             <h3>See What Traditional Analytics Miss</h3>
//             <ul>
//               <li>Track trends and benchmarks across your entire product</li>
//               <li>Analyze conversion funnels beyond basic tracking</li>
//               <li>Discover optimal user success paths</li>
//               <li>Receive real-time intelligence updates</li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const ExplorationSection = () => {
//   return (
//     <div className="product-showcase">
//       <div className="section-inner">
//         <div className="product-show-content">
//           <div className="product-show-features">
//             <h2>Deep Exploration</h2>
//             <h3>Understand Complex Behavior Patterns</h3>
//             <ul>
//               <li>Visualize user journeys in interactive 3D</li>
//               <li>Discover hidden behavioral patterns</li>
//               <li>Analyze feature importance and impact</li>
//               <li>Map and optimize decision paths</li>
//             </ul>
//           </div>
//           <div className="showcase-preview">
//             <img src="/images/example.webp" alt="Exploration Tools" className="rounded-lg shadow-xl" />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const ValidationSection = () => {
//   return (
//     <div className="product-showcase bg-orange">
//       <div className="section-inner">
//         <div className="product-show-content">
//           <div className="showcase-preview">
//             <img src="/images/example.webp" alt="Validation Interface" className="rounded-lg shadow-xl" />
//           </div>
//           <div className="product-show-features">
//             <h2>Pattern Validation</h2>
//             <h3>Verify Against Real Behavior</h3>
//             <ul>
//               <li>Inspect individual user journeys in detail</li>
//               <li>Ask questions in natural language</li>
//               <li>Analyze journey flow probabilities</li>
//               <li>Access processed behavioral data</li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const BenefitsSection = () => {
//   return (
//     <div className="section-inner">
//       <h2>Why Choose Teraace</h2>
//       <div className="threebythree">
//         <ParallaxBox x={-50} y={0} className="benefit-column">
//           <h3>ML-Powered Intelligence</h3>
//           <ul>
//             <li>Models that learn your unique patterns</li>
//             <li>Automatic pattern discovery</li>
//             <li>Predictive user insights</li>
//             <li>Continuous learning and improvement</li>
//           </ul>
//         </ParallaxBox>
        
//         <ParallaxBox x={0} y={50} className="benefit-column">
//           <h3>Complete Understanding</h3>
//           <ul>
//             <li>End-to-end user journey visibility</li>
//             <li>Cross-feature behavior analysis</li>
//             <li>Multi-dimensional insights</li>
//             <li>Real-time pattern detection</li>
//           </ul>
//         </ParallaxBox>
        
//         <ParallaxBox x={50} y={0} className="benefit-column">
//           <h3>Actionable Results</h3>
//           <ul>
//             <li>Clear visualization of complex patterns</li>
//             <li>Natural language interface</li>
//             <li>Direct data access</li>
//             <li>Custom reporting capabilities</li>
//           </ul>
//         </ParallaxBox>
//       </div>
//     </div>
//   );
// };

// const CTASection = () => {
//   return (
//     <div className="final-cta">
//       <div className="section-inner">
//         <h2>Ready to Understand Your Users?</h2>
//         <p>Transform your product understanding with ML-powered behavioral analytics.</p>
//         <div className="cta-buttons">
//           <ScrollToTopButton to="/signup" className="primary-cta">
//             Get Started Free
//           </ScrollToTopButton>
//           <ScrollToTopButton to="/demo" className="secondary-cta">
//             Schedule Demo
//           </ScrollToTopButton>
//         </div>
//       </div>
//     </div>
//   );
// };

// const ProductPage = () => {
//   return (
//     <div className="homepage">
//       <HeroBanner />
//       <ProductFeatures />
//       <InsightsSection />
//       <ExplorationSection />
//       <ValidationSection />
//       <BenefitsSection />
//       <CTASection />
//     </div>
//   );
// };

// const Product = () => {
//   return (
//     <ParallaxProvider>
//       <ProductPage />
//     </ParallaxProvider>
//   );
// };

// export default Product;