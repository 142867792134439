import React from 'react';
import { NavLink } from 'react-router-dom';

function Footer() {
  const footerSections = [
    {
        title: "Platform",
        links: [
          { name: 'Platform Overview', route: '/platform' },
          { name: 'Data Collection', route: '/datacollection' },
          { name: 'Security & Privacy', route: '/security' },
          { name: 'Enterprise', route: '/enterprise' }
        ]
      },
      {
      title: "Product",
      links: [
        { name: 'Product Overview', route: '/product' },
        { name: 'Insights Pages', route: '/insights' },
        { name: 'Exploration Pages', route: '/exploration' },
        { name: 'Validation Pages', route: '/validation' },
        { name: 'Action Pages', route: '/action' }
      ]
    },
    {
      title: "Solutions",
      links: [
        { name: 'For Product Teams', route: '/team-product' },
        { name: 'For Growth Teams', route: '/team-growth' },
        { name: 'For Customer Success', route: '/team-customersuccess' },
        { name: 'For User Experience', route: '/team-userexperience' },
        { name: 'For Executives', route: '/executives' }
      ]
    },
    {
      title: "Use Cases",
      links: [
        { name: 'Journey Optimization', route: '/journeyoptimization' },
        { name: 'Churn Prevention', route: '/churnprevention' },
        { name: 'Feature Adoption', route: '/featureadoption' },
        { name: 'Onboarding Optimization', route: '/onboardingoptimization' },
        { name: 'Revenue Optimization', route: '/revenueoptimization' },
        { name: 'Product-Led Growth', route: '/productledgrowth' }
      ]
    },
    {
      title: "Company",
      links: [
        { name: 'Create Account', route: 'https://app.teraace.com/signup' },
        { name: 'Request Demo', route: '/requestdemo' },
        { name: 'Request Contact', route: '/requestcontact' },
        { name: 'Resources', route: '/resources' },
        { name: 'Privacy Policy', route: '/privacypolicy' },
        { name: 'Terms of Service', route: '/termsofservice' },
        { name: 'Homepage', route: '/' }

      ]
    }
  ];

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-grid">
          {footerSections.map((section, index) => (
            <div key={index} className="footer-column">
              <p className="footer-heading">{section.title}</p>
              <div className="footer-links">
                {section.links.map((link, linkIndex) => (
                  <NavLink
                    key={linkIndex}
                    to={link.route}
                    className="footer-link"
                  >
                    {link.name}
                  </NavLink>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="footer-top">
        <div className="footer-top-container">
          <NavLink to="/" className="footer-logo">
            <img src="/logo-Teraace-250.png" alt="Teraace Logo" />
          </NavLink>
          <p className="footer-copyright">©2025 HYE Partners, LLC.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;