import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../../components/ScrollButton';

const ParallaxBox = ({ children, x, y, className }) => {
  const ref = React.useRef();
  const isInView = useInView(ref, { amount: 0.5 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x, y }}
      animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

const HeroBanner = () => {
  return (
    <ParallaxBanner style={{ aspectRatio: '2 / 1' }} className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/Teraace-bg-106.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            Verify Patterns with<br/>
            <em>Real User Data</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            Move from insight to understanding with tools that let you validate ML-discovered patterns against actual user behavior.
          </h3>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

const UserInspectorSection = () => {
  return (
    <div className="product-showcase bg-yt">
      <div className="section-inner">
        <div className="product-show-content">
          <div className="showcase-preview">
            <img src="/productgifs/Teraace-User-Inspector.gif" alt="User Inspector Interface" className="rounded-lg shadow-xl" />
          </div>
          <div className="product-show-features">
            <h2>User Inspector</h2>
            <h3>Deep Dive into Individual Journeys</h3>
            <ul>
              <li>Follow complete user journeys from start to success</li>
              <li>Compare behavior against identified patterns</li>
              <li>Understand individual user decision points</li>
              <li>See how different users approach the same goals</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const ConversationsSection = () => {
  return (
    <div className="product-showcase">
      <div className="section-inner">
        <div className="product-show-content">
          <div className="product-show-features">
            <h2>Conversations Interface</h2>
            <h3>Natural Language Exploration</h3>
            <ul>
              <li>Ask questions about user behavior in plain language</li>
              <li>Drill down into specific pages and events</li>
              <li>Compare behavior across different time periods</li>
              <li>Validate hypotheses against real data</li>
            </ul>
          </div>
          <div className="showcase-preview">
            <img src="/productgifs/Teraace-Conversations.gif" alt="Conversations Interface" className="rounded-lg shadow-xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

const JourneyFlowSection = () => {
  return (
    <div className="product-showcase bg-orange">
      <div className="section-inner">
        <div className="product-show-content">
          <div className="showcase-preview">
            <img src="/productgifs/Teraace-Journey-Flow.gif" alt="Journey Flow Analysis" className="rounded-lg shadow-xl" />
          </div>
          <div className="product-show-features">
            <h2>Journey Flow</h2>
            <h3>Understand Path Probabilities</h3>
            <ul>
              <li>See likely next steps based on current behavior</li>
              <li>Identify where users commonly deviate from expected paths</li>
              <li>Compare successful vs unsuccessful journey patterns</li>
              <li>Predict potential roadblocks before they happen</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const ModelDataSection = () => {
  return (
    <div className="product-showcase bg-yt">
      <div className="section-inner">
        <div className="product-show-content">
          <div className="product-show-features">
            <h2>Model Data Access</h2>
            <h3>Direct Access to Processed Data</h3>
            <ul>
              <li>Access cleaned and processed behavioral data</li>
              <li>Export insights for custom analysis</li>
              <li>Integrate with existing tools and workflows</li>
              <li>Build custom visualizations and reports</li>
            </ul>
          </div>
          <div className="showcase-preview">
            <img src="/productgifs/Teraace-Model-Output.gif" alt="Model Data Interface" className="rounded-lg shadow-xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

const CTASection = () => {
  return (
    <div className="final-cta">
      <div className="section-inner">
        <h2>Start Validating Patterns</h2>
        <p>See how ML insights match real user behavior.</p>
        <div className="cta-buttons">
          <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
            Get Started Free
          </ScrollToTopButton>
          <ScrollToTopButton to="/requestdemo" className="secondary-cta">
            Schedule Demo
          </ScrollToTopButton>
        </div>
      </div>
    </div>
  );
};

const ValidationPage = () => {
  return (
    <div className="homepage">
      <HeroBanner />
      <UserInspectorSection />
      <ConversationsSection />
      <JourneyFlowSection />
      <ModelDataSection />
      <CTASection />
    </div>
  );
};

const Validation = () => {
  return (
    <ParallaxProvider>
      <ValidationPage />
    </ParallaxProvider>
  );
};

export default Validation;