import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="terms-container">
      <h1>Privacy Policy</h1>
      <p className="last-modified">Last Modified: Feb 20, 2025</p>

      <section>
        <h2>Introduction</h2>
        <p>
          We at Teraace (HYE Partners, Inc. and any future affiliates) are committed to protecting your privacy. This Privacy Policy applies to the information and data collected by Teraace as a controller, including the information collected on our Websites (<a href="https://www.teraace.com" className="link">www.teraace.com</a> and <a href="https://app.teraace.com" className="link">app.teraace.com</a>).
        </p>
      </section>

      <section>
        <h3>Information We Collect</h3>
        <h4>When You Visit Our Websites</h4>
        <p>
          You are free to explore the Websites without providing any Personal Information about yourself. When you visit the Websites or register for the Subscription Service, we request that you provide Personal Information, and we collect Navigational Information.
        </p>
        <h4>Personal Information</h4>
        <p>
          This refers to any information that you voluntarily submit to us, including contact details, transaction history, and publicly available data. Payment information is securely processed through Stripe.
        </p>
        <h4>Information About Children</h4>
        <p>
          The Websites are not intended for children under 16, and we do not knowingly collect information about them. If you believe we have collected such data, please contact <a href="mailto:security@teraace.com" className="link">security@teraace.com</a>.
        </p>
      </section>

      <section>
        <h3>How We Use Information</h3>
        <p>
          We use the information we collect to personalize experiences, provide services, improve our offerings, and comply with legal requirements. We will never sell your Personal Information to third parties.
        </p>
      </section>

      <section>
        <h3>Data Security</h3>
        <p>
          We implement appropriate technical, organizational, and security measures to protect your Personal Information. For details, visit our <a href="https://www.teraace.com/security" className="link">Security Page</a>.
        </p>
      </section>

      <section>
        <h3>Your Rights</h3>
        <p>
          You can access, correct, or delete your data by contacting us at <a href="mailto:privacy@teraace.com" className="link">privacy@teraace.com</a>. If you are an EU resident, you may file a complaint with your local Data Protection Authority.
        </p>
      </section>

      <section>
        <h3>California Privacy Rights</h3>
        <p>
          California residents may exercise their CCPA rights by submitting a request via the <a href="/requestcontact" className="link">CCPA Request Link</a> or emailing <a href="mailto:security@teraace.com" className="link">security@teraace.com</a>.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
