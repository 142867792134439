import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// Import your resource metadata (stored as plain text with placeholders)
import blog5myths from './resources/Blog-5Myths';
import blogPMAhaMoment from './resources/Blog-PM-AhaMoment';
import blogPMChurn from './resources/Blog-PM-Churn';
import blogPMFeatureAdoption from './resources/Blog-PM-FeatureAdoption';
import blogPMFeatureFatigue from './resources/Blog-PM-FeatureFatigue';
import blogPMFreeToPaid from './resources/Blog-PM-FreeToPaid';
import blogPMOnboarding from './resources/Blog-PM-Onboarding';
import blogPMPMF from './resources/Blog-PM-PMF';
import blogPMRetention from './resources/Blog-PM-Retention';
import blogPMUserAdoption from './resources/Blog-PM-UserAdoption';


/**
 * Decide slide direction based on the index:
 * Even index => slide from left
 * Odd index  => slide from right
 */
function getSlideDirection(index) {
  return index % 2 === 0 ? 'left' : 'right';
}

/**
 * Framer Motion variants to handle sliding in/out from left or right.
 */
const slideVariants = {
  initial: (direction) => ({
    x: direction === 'left' ? '-100%' : '100%',
    opacity: 0,
  }),
  animate: (direction) => ({
    x: 0,
    opacity: 1,
  }),
  exit: (direction) => ({
    x: direction === 'left' ? '-100%' : '100%',
    opacity: 0,
  }),
};

/**
 * Replaces placeholder tokens (e.g. $coverImage) in the content string
 * with actual metadata from the resource object.
 */
function replaceTokens(content, resource) {
  return content
    .replace(/\$coverImage/g, resource.coverImage)
    .replace(/\$title/g, resource.title)
    .replace(/\$publicationDate/g, resource.publicationDate);
}

const Resources = () => {
  // This array defines the DOM order (grid-auto-flow: dense can reorder visually if there's space).
  const resources = [blogPMFreeToPaid, blogPMPMF, blogPMAhaMoment, blogPMChurn, blogPMOnboarding, 
    blogPMFeatureAdoption, blogPMRetention, blogPMFeatureFatigue, blogPMUserAdoption, 
    blog5myths];
  const [expandedIndex, setExpandedIndex] = useState(null);

  // Store refs for each resource container
  const itemRefs = useRef([]);

  return (
    <div className="resources-grid">
      {resources.map((resource, index) => {
        // Ensure we have a ref for each index
        if (!itemRefs.current[index]) {
          itemRefs.current[index] = React.createRef();
        }

        // If expanded, force size to XL; otherwise use resource's declared size
        const sizeClass =
          expandedIndex === index ? 'resource-xl' : `resource-${resource.desiredSize}`;

        // Prepare final HTML for the full content by replacing placeholder tokens
        const finalContentHTML = replaceTokens(resource.fullContent, resource);

        return (
          <div
            key={index}
            ref={itemRefs.current[index]}
            className={sizeClass}
          >
            {/* PREVIEW (collapsed) */}
            <AnimatePresence>
              {expandedIndex !== index && (
                <motion.div
                  key="preview"
                  custom={getSlideDirection(index)}
                  variants={slideVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.4 }}
                  className="resource-item"
                  onClick={() => setExpandedIndex(index)}
                >
                  <img
                    src={resource.coverImage}
                    alt={resource.title}
                    className="resource-cover"
                  />
                  <h2 className="resource-title">{resource.title}</h2>
                  <p className="resource-date">
                    {new Date(resource.publicationDate).toLocaleDateString()}
                  </p>
                  <p className="resource-teaser">{resource.teaser}</p>
                </motion.div>
              )}
            </AnimatePresence>

            {/* EXPANDED (full) */}
            <AnimatePresence>
              {expandedIndex === index && (
                <motion.div
                  key="expanded"
                  custom={getSlideDirection(index)}
                  variants={slideVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.4 }}
                  className="resource-fullview"
                  onAnimationComplete={() => {
                    requestAnimationFrame(() => {
                      requestAnimationFrame(() => {
                        setTimeout(() => {
                          const domNode = itemRefs.current[index]?.current;
                          if (domNode) {
                            const offset = domNode.getBoundingClientRect().top + window.scrollY - 90;
                            window.scrollTo({ top: offset, behavior: 'smooth' });
                          }
                        }, 50);
                      });
                    });
                  }}
                >
                  <button
                    className="resource-close-btn"
                    onClick={() => setExpandedIndex(null)}
                  >
                    Close
                  </button>
                  <div
                    className="resource-full-content"
                    dangerouslySetInnerHTML={{ __html: finalContentHTML }}
                  />
                  <button
                    className="resource-close-btn resource-close-btn-bottom"
                    onClick={() => setExpandedIndex(null)}
                  >
                    Close
                  </button>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        );
      })}
    </div>
  );
};

export default Resources;





// import React, { useState } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';

// // Import your resource metadata (stored as plain text with placeholders)
// import blogSample from './resources/BlogSample';
// import videoSample from './resources/VideoSample';
// import assetSample from './resources/AssetSample';
// import blog5myths from './resources/Blog-5Myths';

// /**
//  * Decide slide direction based on the index:
//  * Even index => slide from left
//  * Odd index  => slide from right
//  */
// function getSlideDirection(index) {
//   return index % 2 === 0 ? 'left' : 'right';
// }

// /**
//  * Framer Motion variants to handle sliding in/out from left or right.
//  */
// const slideVariants = {
//     initial: (direction) => ({
//       x: direction === 'left' ? '-100%' : '100%',
//       opacity: 0,
//     }),
//     animate: (direction) => ({
//       x: 0,
//       opacity: 1,
//     }),
//     exit: (direction) => ({
//       x: direction === 'left' ? '-100%' : '100%',
//       opacity: 0,
//     }),
//   };  

// /**
//  * Replaces placeholder tokens (e.g. $coverImage) in the content string
//  * with actual metadata from the resource object.
//  */
// function replaceTokens(content, resource) {
//   return content
//     .replace(/\$coverImage/g, resource.coverImage)
//     .replace(/\$title/g, resource.title)
//     .replace(/\$publicationDate/g, resource.publicationDate);
// }

// const Resources = () => {
//   // This array defines the DOM order (grid-auto-flow: dense can reorder visually if there's space).
//   const resources = [blog5myths, blogSample, videoSample, assetSample];
//   const [expandedIndex, setExpandedIndex] = useState(null);

//   return (
//     <div className="resources-grid">
//       {resources.map((resource, index) => {
//         // If expanded, force size to XL; otherwise use the resource's declared size
//         const sizeClass =
//           expandedIndex === index ? 'resource-xl' : `resource-${resource.desiredSize}`;

//         // Prepare final HTML for the full content by replacing placeholder tokens
//         const finalContentHTML = replaceTokens(resource.fullContent, resource);

//         return (
//           <div key={index} className={sizeClass}>
//             {/* PREVIEW (collapsed) */}
//             <AnimatePresence>
//               {expandedIndex !== index && (
//                 <motion.div
//                   key="preview"
//                   custom={getSlideDirection(index)}
//                   variants={slideVariants}
//                   initial="initial"
//                   animate="animate"
//                   exit="exit"
//                   transition={{ duration: 0.4 }}
//                   className="resource-item"
//                   onClick={() => setExpandedIndex(index)}
//                 >
//                   <img
//                     src={resource.coverImage}
//                     alt={resource.title}
//                     className="resource-cover"
//                   />
//                   <h2 className="resource-title">{resource.title}</h2>
//                   <p className="resource-date">
//                     {new Date(resource.publicationDate).toLocaleDateString()}
//                   </p>
//                   <p className="resource-teaser">{resource.teaser}</p>
//                 </motion.div>
//               )}
//             </AnimatePresence>

//             {/* EXPANDED (full) */}
//             <AnimatePresence>
//               {expandedIndex === index && (
//                 <motion.div
//                   key="expanded"
//                   custom={getSlideDirection(index)}
//                   variants={slideVariants}
//                   initial="initial"
//                   animate="animate"
//                   exit="exit"
//                   transition={{ duration: 0.4 }}
//                   className="resource-fullview"
//                 >
//                   <button
//                     className="resource-close-btn"
//                     onClick={() => setExpandedIndex(null)}
//                   >
//                     Close
//                   </button>
//                   <div
//                     className="resource-full-content"
//                     dangerouslySetInnerHTML={{ __html: finalContentHTML }}
//                   />
//                   <button
//                     className="resource-close-btn resource-close-btn-bottom"
//                     onClick={() => setExpandedIndex(null)}
//                   >
//                     Close
//                   </button>
//                 </motion.div>
//               )}
//             </AnimatePresence>
//           </div>
//         );
//       })}
//     </div>
//   );
// };

// export default Resources;



// // import React, { useState } from 'react';
// // import { motion, AnimatePresence } from 'framer-motion';

// // // Import your resource metadata
// // import blogSample from './resources/BlogSample';
// // import videoSample from './resources/VideoSample';
// // import assetSample from './resources/AssetSample';
// // import blog5myths from './resources/Blog-5Myths';

// // /**
// //  * Decide slide direction based on the index:
// //  * Even index => slide from left
// //  * Odd index  => slide from right
// //  */
// // function getSlideDirection(index) {
// //   return index % 2 === 0 ? 'left' : 'right';
// // }

// // /**
// //  * Framer Motion variants to handle sliding in/out from left or right.
// //  * We use a custom prop (direction) to pick the correct x offset.
// //  */
// // const slideVariants = {
// //   initial: (direction) => ({
// //     x: direction === 'left' ? '-100%' : '100%',
// //     opacity: 0,
// //   }),
// //   animate: {
// //     x: 0,
// //     opacity: 1,
// //   },
// //   exit: (direction) => ({
// //     x: direction === 'left' ? '-100%' : '100%',
// //     opacity: 0,
// //   }),
// // };

// // const Resources = () => {
// //   // This array defines the DOM order, but "grid-auto-flow: dense" can visually reorder items
// //   const resources = [blog5myths, blogSample, videoSample, assetSample];
// //   const [expandedIndex, setExpandedIndex] = useState(null);

// //   return (
// //     <div className="resources-grid">
// //       {resources.map((resource, index) => {
// //         // If expanded, force size to XL; otherwise use resource's declared size
// //         const sizeClass =
// //           expandedIndex === index ? 'resource-xl' : `resource-${resource.desiredSize}`;

// //         return (
// //           <div key={index} className={sizeClass}>
// //             {/* PREVIEW (collapsed) */}
// //             <AnimatePresence>
// //               {expandedIndex !== index && (
// //                 <motion.div
// //                   key="preview"
// //                   custom={getSlideDirection(index)}
// //                   variants={slideVariants}
// //                   initial="initial"
// //                   animate="animate"
// //                   exit="exit"
// //                   transition={{ duration: 0.4 }}
// //                   className="resource-item"
// //                   onClick={() => setExpandedIndex(index)}
// //                 >
// //                   <img
// //                     src={resource.coverImage}
// //                     alt={resource.title}
// //                     className="resource-cover"
// //                   />
// //                   <h2 className="resource-title">{resource.title}</h2>
// //                   <p className="resource-date">
// //                     {new Date(resource.publicationDate).toLocaleDateString()}
// //                   </p>
// //                   <p className="resource-teaser">{resource.teaser}</p>
// //                 </motion.div>
// //               )}
// //             </AnimatePresence>

// //             {/* EXPANDED (full) */}
// //             <AnimatePresence>
// //               {expandedIndex === index && (
// //                 <motion.div
// //                   key="expanded"
// //                   custom={getSlideDirection(index)}
// //                   variants={slideVariants}
// //                   initial="initial"
// //                   animate="animate"
// //                   exit="exit"
// //                   transition={{ duration: 0.4 }}
// //                   className="resource-fullview"
// //                 >
// //                   <button
// //                     className="resource-close-btn"
// //                     onClick={() => setExpandedIndex(null)}
// //                   >
// //                     Close
// //                   </button>
// //                   <div className="resource-full-content">{resource.fullContent}</div>
// //                   <button
// //                     className="resource-close-btn resource-close-btn-bottom"
// //                     onClick={() => setExpandedIndex(null)}
// //                   >
// //                     Close
// //                   </button>
// //                 </motion.div>
// //               )}
// //             </AnimatePresence>
// //           </div>
// //         );
// //       })}
// //     </div>
// //   );
// // };

// // export default Resources;






// // // import React, { useState } from 'react';
// // // import { motion, AnimatePresence } from 'framer-motion';
// // // import blogSample from './resources/BlogSample';
// // // import videoSample from './resources/VideoSample';
// // // import assetSample from './resources/AssetSample';
// // // import blog5myths from './resources/Blog-5Myths';

// // // /**
// // //  * Resources Page
// // //  *
// // //  * Displays resource shells in a grid. Each card:
// // //  * - Animates into view
// // //  * - Expands to XL size (spanning 4 columns) when clicked, if originally smaller
// // //  */
// // // const Resources = () => {
// // //   const resources = [blog5myths, blogSample, videoSample, assetSample];
// // //   const [expandedIndex, setExpandedIndex] = useState(null);

// // //   return (
// // //     <div className="resources-grid">
// // //       {resources.map((resource, index) => {
// // //         // Determine the size class: if expanded, use XL; else use the resource's desiredSize
// // //         const sizeClass =
// // //           expandedIndex === index ? 'resource-xl' : `resource-${resource.desiredSize}`;

// // //         return (
// // //           <motion.div
// // //             key={index}
// // //             layout
// // //             initial={{ opacity: 0, scale: 0.9 }}
// // //             animate={{ opacity: 1, scale: 1 }}
// // //             exit={{ opacity: 0, scale: 0.9 }}
// // //             transition={{ duration: 0.4 }}
// // //             className={`resource-container ${sizeClass}`}
// // //           >
// // //             {/* Preview (collapsed) view */}
// // //             <div className="resource-item" onClick={() => setExpandedIndex(index)}>
// // //               <img
// // //                 src={resource.coverImage}
// // //                 alt={resource.title}
// // //                 className="resource-cover"
// // //               />
// // //               <h2 className="resource-title">{resource.title}</h2>
// // //               <p className="resource-date">
// // //                 {new Date(resource.publicationDate).toLocaleDateString()}
// // //               </p>
// // //               <p className="resource-teaser">{resource.teaser}</p>
// // //             </div>

// // //             {/* Expanded (full content) view */}
// // //             <AnimatePresence>
// // //               {expandedIndex === index && (
// // //                 <motion.div
// // //                   key="expanded-content"
// // //                   className="resource-fullview"
// // //                   initial={{ opacity: 0, scale: 0.9 }}
// // //                   animate={{ opacity: 1, scale: 1 }}
// // //                   exit={{ opacity: 0, scale: 0.9 }}
// // //                   transition={{ duration: 0.3 }}
// // //                 >
// // //                   <button
// // //                     className="resource-close-btn"
// // //                     onClick={() => setExpandedIndex(null)}
// // //                   >
// // //                     Close
// // //                   </button>
// // //                   <div className="resource-full-content">{resource.fullContent}</div>
// // //                   <button
// // //                     className="resource-close-btn resource-close-btn-bottom"
// // //                     onClick={() => setExpandedIndex(null)}
// // //                   >
// // //                     Close
// // //                   </button>
// // //                 </motion.div>
// // //               )}
// // //             </AnimatePresence>
// // //           </motion.div>
// // //         );
// // //       })}
// // //     </div>
// // //   );
// // // };

// // // export default Resources;




// // // // import React, { useState } from 'react';
// // // // import { motion, AnimatePresence } from 'framer-motion';
// // // // import blogSample from './resources/BlogSample';
// // // // import videoSample from './resources/VideoSample';
// // // // import assetSample from './resources/AssetSample';
// // // // import blog5myths from './resources/Blog-5Myths';

// // // // /**
// // // //  * Resources Page
// // // //  *
// // // //  * Renders all resource shells in order.
// // // //  * All display logic (preview, expansion, animations, and layout) is handled here.
// // // //  * Each resource shell provides its metadata (including resourceType, title, coverImage, publicationDate, teaser, fullContent, and desiredSize).
// // // //  */
// // // // const Resources = () => {
// // // //   const resources = [blog5myths, blogSample, videoSample, assetSample];
// // // //   const [expandedIndex, setExpandedIndex] = useState(null);

// // // //   return (
// // // //     <div className="resources-grid">
// // // //       {resources.map((resource, index) => (
// // // //         <div key={index} className={`resource-${resource.desiredSize}`}>
// // // //           {/* Preview (collapsed) view */}
// // // //           <div className="resource-item" onClick={() => setExpandedIndex(index)}>
// // // //             <img src={resource.coverImage} alt={resource.title} className="resource-cover" />
// // // //             <h2 className="resource-title">{resource.title}</h2>
// // // //             <p className="resource-date">
// // // //               {new Date(resource.publicationDate).toLocaleDateString()}
// // // //             </p>
// // // //             <p className="resource-teaser">{resource.teaser}</p>
// // // //           </div>
// // // //           {/* Expanded (full content) view */}
// // // //           <AnimatePresence>
// // // //             {expandedIndex === index && (
// // // //               <motion.div
// // // //                 key="expanded-content"
// // // //                 className="resource-fullview"
// // // //                 initial={{ opacity: 0, scale: 0.9 }}
// // // //                 animate={{ opacity: 1, scale: 1 }}
// // // //                 exit={{ opacity: 0, scale: 0.9 }}
// // // //               >
// // // //                 <button className="resource-close-btn" onClick={() => setExpandedIndex(null)}>
// // // //                   Close
// // // //                 </button>
// // // //                 <div className="resource-full-content">
// // // //                   {resource.fullContent}
// // // //                 </div>
// // // //                 <button
// // // //                   className="resource-close-btn resource-close-btn-bottom"
// // // //                   onClick={() => setExpandedIndex(null)}
// // // //                 >
// // // //                   Close
// // // //                 </button>
// // // //               </motion.div>
// // // //             )}
// // // //           </AnimatePresence>
// // // //         </div>
// // // //       ))}
// // // //     </div>
// // // //   );
// // // // };

// // // // export default Resources;


// // // // // import React, { useState } from 'react';
// // // // // import { motion, AnimatePresence } from 'framer-motion';
// // // // // import blogSample from './resources/BlogSample';
// // // // // import videoSample from './resources/VideoSample';
// // // // // import assetSample from './resources/AssetSample';


// // // // // /**
// // // // //  * Resources Page
// // // // //  *
// // // // //  * Renders all resource components in order.
// // // // //  * All display logic (preview, expansion, animations, and layout) is handled here.
// // // // //  * Each resource shell provides its metadata (including resourceType, title, coverImage, publicationDate, teaser, fullContent, and desiredSize).
// // // // //  */
// // // // // const Resources = () => {
// // // // //   const resources = [blogSample, videoSample, assetSample];
// // // // //   const [expandedIndex, setExpandedIndex] = useState(null);

// // // // //   return (
// // // // //     <div className="resources-grid">
// // // // //       {resources.map((resource, index) => (
// // // // //         <div key={index} className={`resource-${resource.desiredSize}`}>
// // // // //           {expandedIndex !== index ? (
// // // // //             <div className="resource-item" onClick={() => setExpandedIndex(index)}>
// // // // //               <img src={resource.coverImage} alt={resource.title} className="resource-cover" />
// // // // //               <h2 className="resource-title">{resource.title}</h2>
// // // // //               <p className="resource-date">{new Date(resource.publicationDate).toLocaleDateString()}</p>
// // // // //               <p className="resource-teaser">{resource.teaser}</p>
// // // // //             </div>
// // // // //           ) : (
// // // // //             <AnimatePresence>
// // // // //               <motion.div
// // // // //                 className="resource-fullview"
// // // // //                 initial={{ opacity: 0, scale: 0.9 }}
// // // // //                 animate={{ opacity: 1, scale: 1 }}
// // // // //                 exit={{ opacity: 0, scale: 0.9 }}
// // // // //               >
// // // // //                 <button className="resource-close-btn" onClick={() => setExpandedIndex(null)}>
// // // // //                   Close
// // // // //                 </button>
// // // // //                 <div className="resource-full-content">
// // // // //                   {resource.fullContent}
// // // // //                 </div>
// // // // //                 <button className="resource-close-btn resource-close-btn-bottom" onClick={() => setExpandedIndex(null)}>
// // // // //                   Close
// // // // //                 </button>
// // // // //               </motion.div>
// // // // //             </AnimatePresence>
// // // // //           )}
// // // // //         </div>
// // // // //       ))}
// // // // //     </div>
// // // // //   );
// // // // // };

// // // // // export default Resources;
