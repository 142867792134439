import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import GlowBox from '../../components/GlowBox';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../../components/ScrollButton';

const ParallaxGlowBox = ({ children, glowColor, bgColor, x, y }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        exit="exit"
        variants={fadeInOut(x, y)}
      >
        <GlowBox glowColor={glowColor} bgColor={bgColor}>
          {children}
        </GlowBox>
      </motion.div>
    );
};

const fadeInOut = (x, y) => ({
    hidden: { opacity: 0, x, y },
    visible: {
      opacity: 1,
      x: '0%',
      y: '0%',
      transition: { duration: 0.5, ease: 'easeInOut' }
    },
    exit: { opacity: 0, x: -x, y: -y, transition: { duration: 0.5, ease: 'easeInOut' } }
});

const ParallaxBox = ({ children, x, y, className }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial={{ opacity: 0, x, y }}
        animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        className={className}
      >
        {children}
      </motion.div>
    );
};

const HeroBanner = () => {
  return (
    <ParallaxBanner className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/Teraace-bg-041.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            Churn Prevention<br/>
            <em>Stop Churn Before It Starts</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            Move beyond reactive analytics to predictive churn prevention with ML that learns from your unique user patterns
          </h3>
          <div className="hero-cta-container">
            <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
              Start Preventing Churn
            </ScrollToTopButton>
            <ScrollToTopButton to="/requestdemo" className="secondary-cta">
              See Prevention Demo
            </ScrollToTopButton>
          </div>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

const ChurnChallengeSection = () => {
  return (
    <div className="section-inner challenge-section">
      <h2 className="standard-pad no-btm">The Churn Challenge</h2>
      <div className="twobytwo">
        <ParallaxBox x={-50} y={0} className="panel-left">
          <div className="panel-content">
            <h3>Why Traditional Analytics Fail</h3>
            <ul>
              <li>Too focused on past behavior</li>
              <li>Miss early warning signs</li>
              <li>Overlook complex patterns</li>
              <li>React too late</li>
            </ul>
          </div>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="panel-right">
          <div className="panel-content">
            <h3>Teraace's ML Advantage</h3>
            <ul>
              <li>Predict churn risk early</li>
              <li>Identify warning patterns</li>
              <li>Suggest intervention points</li>
              <li>Learn from success cases</li>
            </ul>
          </div>
        </ParallaxBox>
      </div>
    </div>
  );
};

const FeaturesSection = () => {
  return (
    <div className="section-inner">
      <h2>Key Features</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="feature-column">
          <h3>Risk Predictor</h3>
          <ul>
            <li>Early warning system</li>
            <li>Behavior pattern analysis</li>
            <li>Segment-specific insights</li>
            <li>Intervention recommendations</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="feature-column">
          <h3>Success Analyzer</h3>
          <ul>
            <li>Identify retention drivers</li>
            <li>Map successful user journeys</li>
            <li>Understand feature impact</li>
            <li>Track engagement patterns</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="feature-column">
          <h3>Intervention Optimizer</h3>
          <ul>
            <li>Timing recommendations</li>
            <li>Action prioritization</li>
            <li>Impact prediction</li>
            <li>ROI tracking</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const PreventionSection = () => {
  return (
    <div className="product-showcase">
      <div className="section-inner">
        <div className="showcase-content">
          <div className="showcase-features">
            <h2>Prevention Strategies</h2>
            <ul>
              <li>Identify at-risk users early</li>
              <li>Understand churn triggers</li>
              <li>Optimize interventions</li>
              <li>Track prevention impact</li>
            </ul>
          </div>
          <div className="showcase-preview">
            <img src="/images/Teraace-bg-130.webp" alt="Prevention Dashboard" className="rounded-lg shadow-xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

const GetStartedSection = () => {
  return (
    <div className="section-inner">
      <h2>Quick Start Guide</h2>
      <div className="fourbyfour">
        <ParallaxBox x={0} y={20} className="step-item">
          <h3>1. Connect user data</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="step-item">
          <h3>2. Let ML identify patterns</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="step-item">
          <h3>3. Set up early warnings</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="step-item">
          <h3>4. Track intervention impact</h3>
        </ParallaxBox>
      </div>
    </div>
  );
};

const ChurnSection = () => {
  return (
    <div className="homepage">
      <HeroBanner />
      <ChurnChallengeSection />
      <FeaturesSection />
      <PreventionSection />
      <GetStartedSection />
      <div className="final-cta">
        <div className="section-inner">
          <h2>Start Preventing Churn Today</h2>
          <p>Transform your retention strategy with ML-powered churn prevention</p>
          <div className="cta-buttons">
            <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">Start Preventing Churn</ScrollToTopButton>
            <ScrollToTopButton to="/requestdemo" className="secondary-cta">See Prevention Demo</ScrollToTopButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const ChurnPrevention = () => {
  return (
    <ParallaxProvider>
      <ChurnSection />
    </ParallaxProvider>
  );
};

export default ChurnPrevention;