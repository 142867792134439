import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import GlowBox from '../components/GlowBox';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../components/ScrollButton';

const ParallaxGlowBox = ({ children, glowColor, bgColor, x, y }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        exit="exit"
        variants={fadeInOut(x, y)}
      >
        <GlowBox glowColor={glowColor} bgColor={bgColor}>
          {children}
        </GlowBox>
      </motion.div>
    );
};

const fadeInOut = (x, y) => ({
    hidden: { opacity: 0, x, y },
    visible: {
      opacity: 1,
      x: '0%',
      y: '0%',
      transition: { duration: 0.5, ease: 'easeInOut' }
    },
    exit: { opacity: 0, x: -x, y: -y, transition: { duration: 0.5, ease: 'easeInOut' } }
});

const HeroBanner = () => {
  return (
    <ParallaxBanner style={{ aspectRatio: '2 / 1', height: '600px' }} className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/footsteps-in-woods.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            Machine Learning That Learns Your Product,<br/>
            <em>Not Everyone Else's</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            Transform complex user behaviors into predictive intelligence
          </h3>
          <div className="hero-cta-container">
            <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
              Get Started Free
            </ScrollToTopButton>
            <ScrollToTopButton to="/requestdemo" className="secondary-cta">
              Watch Demo
            </ScrollToTopButton>
          </div>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

const ParallaxBox = ({ children, x, y, className }) => {
  const ref = React.useRef();
  const isInView = useInView(ref, { amount: 0.5 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x, y }}
      animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

const BenefitsSection = () => {
  return (
    <div className="benefits-section threebythree">
      <ParallaxBox x={-50} y={0} className="benefit-column">
        <h3>Beyond Basic Analytics</h3>
        <p>While others give you dashboards of page clicks, Teraace reveals the hidden patterns driving user success. Teraace's models learn from your unique user behaviors, not generic industry data.</p>
      </ParallaxBox>
      
      <ParallaxBox x={0} y={50} className="benefit-column">
        <h3>Discover What You Didn't Know to Look For</h3>
        <p>Stop swimming in metrics. Teraace automatically surfaces meaningful patterns and predicts future user behavior based on proven success paths.</p>
      </ParallaxBox>
      
      <ParallaxBox x={50} y={0} className="benefit-column">
        <h3>Intelligence That Grows With You</h3>
        <p>Start seeing valuable insights from day one. Teraace's models become increasingly powerful as they learn your product's unique patterns and user behaviors.</p>
      </ParallaxBox>
    </div>
  );
};

const UseCaseGrid = () => {
  return (
    <motion.div className="use-case-grid twobytwo">
      <ParallaxGlowBox 
        glowColor="rgba(61, 52, 139, 0.125)" 
        bgColor="bg-ltgray"
        x="-10%"
        y="-10%"
      >
        <h4>For Product Teams</h4>
        <p>Move beyond guesswork. Let Teraace reveal which features truly drive user success.</p>
      </ParallaxGlowBox>

      <ParallaxGlowBox 
        glowColor="rgba(118, 120, 237, 0.125)" 
        bgColor="bg-ltgray"
        x="10%"
        y="-10%"
      >
        <h4>For Growth Teams</h4>
        <p>Discover the real patterns behind activation and retention. Predict what drives future growth.</p>
      </ParallaxGlowBox>

      <ParallaxGlowBox 
        glowColor="rgba(130, 2, 99, 0.125)" 
        bgColor="bg-ltgray"
        x="-10%"
        y="10%"
      >
        <h4>For Customer Success</h4>
        <p>See user journeys before they happen. Prevent churn by identifying at-risk patterns early.</p>
      </ParallaxGlowBox>

      <ParallaxGlowBox 
        glowColor="rgba(217, 3, 104, 0.125)" 
        bgColor="bg-ltgray"
        x="10%"
        y="10%"
      >
        <h4>For UX Design</h4>
        <p>Design with confidence using real behavioral patterns and ML-identified success paths.</p>
      </ParallaxGlowBox>
    </motion.div>
  );
};

const IntegrationSection = () => {
  return (
    <div>
      <h2>Flexible Integration That Works for You</h2>
      <div className="integration-methods threebythree">
        <ParallaxBox x={-50} y={0} className="integration-method">
          <h3>One-Line Script</h3>
          <p>Drop in a single line of code for instant behavioral capture</p>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="integration-method">
          <h3>Direct API</h3>
          <p>Choose the events to send and maintain control with our comprehensive API</p>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="integration-method">
          <h3>Data Pipeline</h3>
          <p>Connect through your existing data infrastructure</p>
        </ParallaxBox>
      </div>
    </div>
  );
};

const Homepage = () => {
  return (
    <div className="homepage">
      <HeroBanner />
      
      <div className="social-proof">
        <div className="section-inner">
          <h2>Trusted by innovative companies to understand users</h2>
        </div>
      </div>

      <div className="benefits-wrapper">
        <div className="section-inner">
          <BenefitsSection />
        </div>
      </div>
      
      <div className="product-showcase">
        <div className="section-inner">
          <div className="showcase-content">
            <div className="showcase-features">
            <h2>From Data to Understanding</h2>
            <ul>
                <li>Automatic pattern discovery</li>
                <li>Predictive user journey mapping</li>
                <li>Custom-learned insights</li>
                <li>Real-time behavior analysis</li>
              </ul>
            </div>
            <div className="showcase-preview">
              <img src="/images/Teraace-bg-122.webp" alt="Platform preview" className="rounded-lg shadow-xl" />
            </div>
          </div>
        </div>
      </div>

      <div className="usecase-wrapper">
        <div className="section-inner">
          <UseCaseGrid />
        </div>
      </div>
      
        <div className="section-inner">
          <div className="comparison-grid twobytwo">
            <div className="comparison-grid-feature">
              <h3>Traditional Analytics</h3>
              <ul>
                <li>Generic dashboards</li>
                <li>Manual analysis required</li>
                <li>Surface-level metrics</li>
                <li>One-size-fits-all insights</li>
              </ul>
            </div>
            <div className="comparison-grid-feature">
              <h3>Teraace Intelligence</h3>
              <ul>
                <li>Custom-learned patterns</li>
                <li>Automatic insight generation</li>
                <li>Deep behavioral understanding</li>
                <li>Intelligence unique to your product</li>
              </ul>
            </div>
          </div>
        </div>

      <div className="integration-wrapper">
        <div className="section-inner">
          <IntegrationSection />
        </div>
      </div>
      
      <div className="final-cta">
        <div className="section-inner">
          <h2>Start Discovering Your Users' Success Patterns Today</h2>
          <p>Transform your product understanding with Machine Learning that uncovers your users' unique behaviors</p>
          <div className="cta-buttons">
            <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">Start Free Trial</ScrollToTopButton>
            <ScrollToTopButton to="/requestdemo" className="secondary-cta">Schedule Demo</ScrollToTopButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  return (
    <ParallaxProvider>
      <Homepage />
    </ParallaxProvider>
  );
};

export default Home;
