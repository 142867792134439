import React, { useState, useEffect, Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import Footer from './Footer';
import ErrorBoundary from './components/ErrorBoundary';
import SEO from './components/SEO';
import { ResponsiveWrapper } from './components/ResponsiveWrapper';
import { MantineProvider } from '@mantine/core';
// import BetaRequest from './pages/BetaRequest';
import { Provider } from 'react-redux';
import store from './redux/store';
// import SplashPage from './pages/SplashPage';
import GetAIDeck from './pages/GetAIDeck';
import useDebouncedResize from './components/ScreenResized';
import { HelmetProvider } from 'react-helmet-async';

import Home from './pages/Home';
import Platform from './pages/Platform';
import DataCollection from './pages/DataCollection';
import Security from './pages/Security';
import Enterprise from './pages/Enterprise';
import Solutions from './pages/solutions/Solutions';
import TeamProduct from './pages/solutions/TeamProduct';
import TeamGrowth from './pages/solutions/TeamGrowth';
import TeamCS from './pages/solutions/TeamCS';
import TeamUX from './pages/solutions/TeamUX';
import TeamExecutives from './pages/solutions/TeamExecutives';
import JourneyOptimization from './pages/usecases/JourneyOptimization';
import ChurnPrevention from './pages/usecases/ChurnPrevention';
import FeatureAdoption from './pages/usecases/FeatureAdoption';
import OnboardingOptimization from './pages/usecases/OnboardingOptimization';
import RevenueOptimization from './pages/usecases/RevenueOptimization';
import ProductLedGrowth from './pages/usecases/ProductLedGrowth';
import Insights from './pages/product/Insights';
import Exploration from './pages/product/Exploration';
import Validation from './pages/product/Validation';
import Action from './pages/product/Action';
import Product from './pages/product/Product';
import RequestDemo from './pages/RequestDemo';
import RequestContact from './pages/RequestContact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import Resources from './pages/Resources';

const routeConfig = {
  // SplashPage: { component: SplashPage, title: 'Introduction', description: 'Learn about Teraace', url: '/intro' },
  // BetaRequest: { component: BetaRequest, title: 'Request Beta Access', description: 'Ask for Teraace Beta Access', url: '/betarequest' },
  Home: { component: Home, title: 'Home', description: 'Transform complex user behaviors into predictive intelligence with machine learning that learns your product.', url: '/' },
  Platform: { component: Platform, title: 'Platform', description: "Discover how Teraace's ML platform transforms user behavior into actionable product intelligence.", url: '/platform' },
  DataCollection: { component: DataCollection, title: 'Data Collection', description: "Flexible data collection methods that work your way - choose from auto-capture script, direct API, or data pipeline integration.", url: '/datacollection' },
  Security: { component: Security, title: 'Security & Privacy', description: "Enterprise-grade security infrastructure protecting your user data with comprehensive security controls and compliance measures.", url: '/security' },
  Enterprise: { component: Enterprise, title: 'Enterprise', description: "Enterprise-grade analytics platform with advanced security, scalability, and control for organizations that demand excellence.", url: '/enterprise' },
  Solutions: { component: Solutions, title: 'Solutions', description: "ML-powered intelligence tailored for Product, Growth, Customer Success, UX, and Executive teams.", url: '/solutions' },
  TeamProduct: { component: TeamProduct, title: 'For Product', description: "Move beyond gut feelings to data-driven product decisions with ML-powered user behavior insights.", url: '/team-product' },
  TeamGrowth: { component: TeamGrowth, title: 'For Growth', description: "Discover the real patterns behind activation, retention, and expansion with ML-powered growth insights.", url: '/team-growth' },
  TeamCS: { component: TeamCS, title: 'For Customer Success', description: "Guide users to success through their preferred paths with ML-powered journey insights.", url: '/team-customersuccess' },
  TeamUX: { component: TeamUX, title: 'For User Experience', description: "Design with confidence using ML-powered behavioral insights and real user interaction patterns.", url: '/team-userexperience' },
  TeamExecutives: { component: TeamExecutives, title: 'For Executives', description: "Get a holistic view of product performance and user behavior patterns for strategic decision-making.", url: '/executives' },
  JourneyOptimization: { component: JourneyOptimization, title: 'Journey Optimization', description: "See how users actually succeed with ML-powered journey optimization. Discover real paths to value and optimize your product experience.", url: '/journeyoptimization' },
  ChurnPrevention: { component: ChurnPrevention, title: 'Churn Prevention', description: "Stop churn before it starts with ML-powered predictive analytics. Move beyond reactive measures to proactive retention strategies.", url: '/churnprevention' },
  FeatureAdoption: { component: FeatureAdoption, title: 'Feature Adoption', description: "Drive feature success with ML-powered insights. Understand how users discover and adopt features to optimize their path to value.", url: '/featureadoption' },
  OnboardingOptimization: { component: OnboardingOptimization, title: 'Onboarding Optimization', description: "Create perfect first experiences with ML-powered onboarding optimization. Let machine learning reveal the optimal paths to user success from day one.", url: '/onboardingoptimization' },
  RevenueOptimization: { component: RevenueOptimization, title: 'Revenue Optimization', description: "Maximize revenue through user understanding with ML-powered insights. Transform behavior patterns into revenue opportunities.", url: '/revenueoptimization' },
  ProductLedGrowth: { component: ProductLedGrowth, title: 'Product-Led Growth', description: "Optimize your product-led growth with ML-powered insights. Reveal the patterns that drive successful self-service adoption, conversion, and expansion.", url: '/productledgrowth' },
  Insights: { component: Insights, title: 'Insights', description: "Transform complex user data into actionable insights with Teraace's ML-powered analytics. Discover patterns, optimize conversion funnels, and understand user success paths.", url: '/insights' },
  Exploration: { component: Exploration, title: 'Exploration', description: "Explore complex user behavior patterns with ML-powered visualization tools. Discover hidden opportunities and understand what truly drives user success.", url: '/exploration' },
  Validation: { component: Validation, title: 'Validation', description: "Validate ML-discovered patterns against real user behavior. Inspect user journeys, explore data through natural language, and access processed behavioral data for custom analysis.", url: '/validation' },
  Action: { component: Action, title: 'Action', description: "Take action on the machine learning patterns of real user behavior. Call webhooks, send data to other platforms, take action to improve your user experiences", url: '/action' },
  Product: { component: Product, title: 'Product', description: "Transform user behavior into actionable insights with Teraace's ML-powered analytics platform. Discover, explore, and validate how users succeed with your product.", url: '/product' },
  RequestDemo: { component: RequestDemo, title: 'Request Demo', description: 'Ask for a Demo of Teraace', url: '/requestdemo' },
  RequestContact: { component: RequestContact, title: 'Request Contact', description: 'Request reply from Teraace', url: '/requestcontact' },
  PrivacyPolicy: { component: PrivacyPolicy, title: 'Privacy Policy', description: 'Teraace Privacy Policy', url: '/privacypolicy' },
  TermsOfService: { component: TermsOfService, title: 'Terms of Service', description: 'Teraace Terms of Service', url: '/termsofservice' },
  Resources: { component: Resources, title: 'Resources', description: 'Content, Blogs and Videos for Teraace', url: '/resources' },
};

const SEOWrapper = ({ route }) => {
  const { title = 'Teraace', description = '', url = '/' } = routeConfig[route] || routeConfig.Home;
  return <SEO title={title} description={description} url={url} />;
};

const Content = React.memo(() => {
  const location = useLocation();
  const getRouteKey = (pathname) => {
    pathname = pathname.replace('/', '');
    return pathname ? Object.keys(routeConfig).find(key => 
      routeConfig[key].url === `/${pathname}`
    ) : 'Home';
  };
  const [currentPage, setCurrentPage] = useState(getRouteKey(location.pathname));

  // const [currentPage, setCurrentPage] = useState(location.pathname.replace('/', '') || 'Home');
  // const { title = 'Teraace', description = '', url = '/' } = routeConfig[currentPage] || routeConfig.Home;
  const pages = Object.keys(routeConfig).map(key => ({
    name: routeConfig[key].title,
    route: routeConfig[key].url,
  }));
  
  // const onNavigate = (route) => {
  //   setCurrentPage(route);
  // };

  const onNavigate = (route) => {
    setCurrentPage(getRouteKey(route));
  };

  // const navigate = useNavigate();

  useEffect(() => {
    setCurrentPage(getRouteKey(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    // Optional: Add any global error handling or logging here
    const handleError = (event) => {
      console.error('Uncaught error:', event.error);
    };

    const handleUnhandledRejection = (event) => {
      console.error('Unhandled Promise Rejection:', event.reason);
    };

    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  }, []);

  return (
    <div className="relative min-h-screen scroll">
      <div className="outerwrapper">
        <div className="flex-container">
          <div className="innerwrapper">
            <ResponsiveWrapper>
              <Sidebar className="sidebar" pages={pages} onNavigate={onNavigate}/>
              <div>
                <SEOWrapper route={currentPage} />
                <Suspense fallback={<div>Loading...</div>}>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/platform" element={<Platform />} />
                    <Route path="/datacollection" element={<DataCollection />} />
                    <Route path="/security" element={<Security />} />
                    <Route path="/enterprise" element={<Enterprise />} />
                    <Route path="/solutions" element={<Solutions />} />
                    <Route path="/team-product" element={<TeamProduct />} />
                    <Route path="/team-growth" element={<TeamGrowth />} />
                    <Route path="/team-customersuccess" element={<TeamCS />} />
                    <Route path="/team-userexperience" element={<TeamUX />} />
                    <Route path="/executives" element={<TeamExecutives />} />
                    <Route path="/journeyoptimization" element={<JourneyOptimization />} />
                    <Route path="/churnprevention" element={<ChurnPrevention />} />
                    <Route path="/featureadoption" element={<FeatureAdoption />} />
                    <Route path="/onboardingoptimization" element={<OnboardingOptimization />} />
                    <Route path="/revenueoptimization" element={<RevenueOptimization />} />
                    <Route path="/productledgrowth" element={<ProductLedGrowth />} />
                    <Route path="/product" element={<Product />} />
                    <Route path="/insights" element={<Insights />} />
                    <Route path="/exploration" element={<Exploration />} />
                    <Route path="/validation" element={<Validation />} />
                    <Route path="/action" element={<Action />} />
                    {/* <Route path="/intro" element={<SplashPage />} />*/}
                    <Route path="/requestdemo" element={<RequestDemo />} />                        
                    <Route path="/requestcontact" element={<RequestContact />} />                        
                    <Route path="/privacypolicy" element={<PrivacyPolicy />} />                        
                    <Route path="/termsofservice" element={<TermsOfService />} />                        
                    <Route path="/resources" element={<Resources />} />                        
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route path="/getaideck" element={<GetAIDeck />} />
                  </Routes>
                </Suspense>
              </div>
              <Footer className="footer" pages={pages} onNavigate={onNavigate}/>

            </ResponsiveWrapper>
          </div>
        </div>          
      </div> 
    </div>
  );
});

const App = () => {
  const { width } = useDebouncedResize(300); // Adjust the delay as needed
  const [resizeKey, setResizeKey] = useState(0);

  useEffect(() => {
    // Force a re-render by updating the key
    setResizeKey(prevKey => prevKey + 1);
  }, [width]);

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <MantineProvider theme={{ /* your theme settings */ }}>
        <HelmetProvider>
          <Router>
            <Content resizeKey={resizeKey} />
          </Router>
          </HelmetProvider>
        </MantineProvider>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;

// // import React, { useState, useEffect} from 'react';
// // import { useLocation, useNavigate } from 'react-router-dom';
// // import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// // import Sidebar from './Sidebar';
// // import ErrorBoundary from './components/ErrorBoundary';
// // import SEO from './components/SEO';
// // import { ResponsiveWrapper } from './components/ResponsiveWrapper';
// // import { MantineProvider } from '@mantine/core';
// // import BetaRequest from './pages/BetaRequest';
// // import { Provider } from 'react-redux';
// // import store from './redux/store';
// // import SplashPage from './pages/SplashPage';
// // import GetAIDeck from './pages/GetAIDeck';
// // import useDebouncedResize from './components/ScreenResized';

// // import Home from './pages/Home';
// // import Platform from './pages/Platform';
// // import DataCollection from './pages/DataCollection';
// // import Security from './pages/Security';
// // import Enterprise from './pages/Enterprise';
// // import Solutions from './pages/Solutions';
// // import TeamProduct from './pages/TeamProduct';
// // import TeamGrowth from './pages/TeamGrowth';
// // import TeamCS from './pages/TeamCS';
// // import TeamUX from './pages/TeamUX';
// // import TeamExecutives from './pages/TeamExecutives';


// // const routeConfig = {
// //   SplashPage: { component: SplashPage, title: 'Introduction', description: 'Learn about Teraace', url: '/intro' },
// //   BetaRequest: { component: BetaRequest, title: 'Request Beta Access', description: 'Ask for Teraace Beta Access', url: '/betarequest' },
// //   // GetAIDeck: { component: GetAIDeck, title: 'Request AI Deck', description: 'Building Product Without Knowing Code: The Power of AI', url: '/getaideck' },
// //   Home: { component: Home, title: 'Home', description: 'Transform complex user behaviors into predictive intelligence with machine learning that learns your product.', url: '/' },
// //   Platform: { component: Platform, title: 'Platform', description: "Discover how Teraace's ML platform transforms user behavior into actionable product intelligence.", url: '/platform' },
// //   DataCollection: { component: DataCollection, title: 'Data Collection', description: "Flexible data collection methods that work your way - choose from auto-capture script, direct API, or data pipeline integration.", url: '/datacollection' },
// //   Security: { component: Security, title: 'Security & Privacy', description: "Enterprise-grade security infrastructure protecting your user data with comprehensive security controls and compliance measures.", url: '/security' },
// //   Enterprise: { component: Enterprise, title: 'Enterprise', description: "Enterprise-grade analytics platform with advanced security, scalability, and control for organizations that demand excellence.", url: '/enterprise' },
// //   Solutions: { component: Solutions, title: 'Solutions', description: "ML-powered intelligence tailored for Product, Growth, Customer Success, UX, and Executive teams.", url: '/solutions' },
// //   TeamProduct: { component: TeamProduct, title: 'For Product', description: "Move beyond gut feelings to data-driven product decisions with ML-powered user behavior insights.", url: '/team-product' },
// //   TeamGrowth: { component: TeamGrowth, title: 'For Growth', description: "Discover the real patterns behind activation, retention, and expansion with ML-powered growth insights.", url: '/team-growth' },
// //   TeamCS: { component: TeamCS, title: 'For Customer Success', description: "Guide users to success through their preferred paths with ML-powered journey insights.", url: '/team-customersuccess' },
// //   TeamUX: { component: TeamUX, title: 'For User Experience', description: "Design with confidence using ML-powered behavioral insights and real user interaction patterns.", url: '/team-userexperience' },
// //   TeamExecutives: { component: TeamExecutives, title: 'For Executives', description: "Get a holistic view of product performance and user behavior patterns for strategic decision-making.", url: '/executives' },
// // };

// // const SEOWrapper = ({ route }) => {
// //   const { title = 'Teraace', description = '', url = '/' } = routeConfig[route] || routeConfig.Home;
// //   return <SEO title={title} description={description} url={url} />;
// // };

// // const Content = React.memo(() => {
// //   // console.log("Content component rerendered");
// //   // const [currentPage, setCurrentPage] = useState('happyPath'); // Example page state
// //   const location = useLocation();
// //   const [currentPage, setCurrentPage] = useState(location.pathname.replace('/', '') || Home);
// //   const { title = 'Teraace', description = '', url = '/' } = routeConfig[currentPage] || routeConfig.Home;
// //   // const { title, description, url } = routeConfig[currentPage] || {};
// //   const pages = Object.keys(routeConfig).map(key => ({
// //     name: routeConfig[key].title,
// //     route: routeConfig[key].url,
// //   }));

  
// //   // console.log('App component rendering'); // Add this log  

  
// //   const onNavigate = (route) => {
// //     // console.log('onNavigate called');
// //     // console.log('onNavigate called with route:', route);
// //     // console.log(`Old Background image set to: ${backgroundImage}`);
// //     setCurrentPage(route);
// //     // console.log('Current page after:', currentPage);
// //     // setBackgroundImage(getRandomBackgroundImage()); // Update background image on navigation
// //     // console.log(`New Background image set to: ${backgroundImage}`);
// //   };

// //   const navigate = useNavigate();

  
// //   useEffect(() => {
// //     return () => {
// //       console.log('App component unmounted');
// //     };
// //   }, []);


// // return (
// //     <div className="relative min-h-screen scroll">
// //       <div className="outerwrapper">
// //     <div className="flex-container">
// //         <div className="innerwrapper">
           
// //                 <ResponsiveWrapper>
// //                 <Sidebar className="sidebar" pages={pages} onNavigate={onNavigate}/>
// //                 <div>
// //                 <SEOWrapper route={currentPage} />
// //                     <Routes>
// //                     <Route path="/" element={<Home />} />
// //                     <Route path="/platform" element={<Platform />} />
// //                     <Route path="/datacollection" element={<DataCollection />} />
// //                     <Route path="/security" element={<Security />} />
// //                     <Route path="/enterprise" element={<Enterprise />} />
// //                     <Route path="/solutions" element={<Solutions />} />
// //                     <Route path="/team-product" element={<TeamProduct />} />
// //                     <Route path="/team-growth" element={<TeamGrowth />} />
// //                     <Route path="/team-customersuccess" element={<TeamCS />} />
// //                     <Route path="/team-userexperience" element={<TeamUX />} />
// //                     <Route path="/executives" element={<TeamExecutives />} />
// //                     <Route path="/intro" element={<SplashPage />} />
// //                         <Route path="/betarequest" element={<BetaRequest />} />                       
// //                         <Route path="*" element={<Navigate to="/intro" />} />
// //                         <Route path="/getaideck" element={<GetAIDeck />} />
// //                     </Routes>
// //                     </div>
// //                 </ResponsiveWrapper>
           
// //         </div>
// //       </div>          
// //       </div> 
// //     </div>
// //   );
// //  });

// // const App = () => {
// //   const { width } = useDebouncedResize(300); // Adjust the delay as needed
// //   const [resizeKey, setResizeKey] = useState(0);

// //   useEffect(() => {
// //     // Force a re-render by updating the key
// //     setResizeKey(prevKey => prevKey + 1);
// //   }, [width]);

// //   return (
// //     <ErrorBoundary>
// //     <Provider store={store}>
// //     <MantineProvider theme={{ /* your theme settings */ }}>
// //     <Router>
// //     <Content resizeKey={resizeKey} />
// //         {/* <Routes>
// //               <Route path="*" element={<Content resizeKey={resizeKey} />} />
// //             </Routes> */}
// //         </Router>
// //     </MantineProvider>
// //     </Provider>
// //     </ErrorBoundary>
// //   );
// // };

// // export default App;
