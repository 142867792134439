import React from "react";

const TermsOfUse = () => {
  return (
    <div className="terms-container">
      <h1>Terms of Use</h1>
      <p className="last-modified">Last Modified: Feb 20, 2025</p>

      <section>
        <h3>Introduction</h3>
        <p>
          The Teraace product, website, and any other website owned, operated, licensed, or controlled by Teraace (collectively, the “Website") are copyrighted works belonging to and operated by HYE partners, LLC., its affiliates, licensors, and subsidiaries (collectively, "Teraace"). Your access to the Website constitutes your acknowledgment and acceptance of these Terms of Use ("Terms"). Teraace will provide notice of material changes to these Terms by posting an updated version on the Website and updating the “Last Modified” date. Continued use after the updated Terms become effective constitutes acceptance of the revised Terms. Should you reject these Terms, you are prohibited from accessing or using the Website. This includes all digital platforms operated by Teraace, such as mobile applications, software, and online services.
        </p>
      </section>

      <section>
        <h3>Copyright and Trademark</h3>
        <p>
          All trademarks, service marks, and logos displayed on the Website, including the Teraace logo, are trademarks and service marks of Teraace (collectively, the "Marks"). You may not use, copy, reproduce, republish, upload, post, transmit, distribute, or modify Teraace’s Marks in any way, including in advertising or publicity pertaining to distribution of materials on the Website, without prior written consent from Teraace.
        </p>
      </section>

      <section>
        <h3>External Links</h3>
        <p>
          Teraace may provide access to resources and links to other websites. When you access a non-Teraace website, even one that may contain the Teraace logo, you leave the Teraace Website and understand that it is independent from Teraace. Teraace is not responsible for third-party content or practices. Your interactions with third-party websites are at your own risk.
        </p>
      </section>

      <section>
        <h3>Privacy</h3>
        <p>
          Refer to <a href="/privacypolicy" className="link">Teraace's Privacy Policy</a> for details on the collection, use, and sharing of information.
        </p>
      </section>

      <section>
        <h3>Disclaimer of Warranties and Limitation of Liability</h3>
        <p>
          The Website is provided by Teraace on an "as is" and "as available" basis. Teraace makes no guarantees regarding the Website's availability, accuracy, or freedom from harmful components. The use of the Website is at your sole risk.
        </p>
      </section>

      <section>
        <h3>International Use</h3>
        <p>
          The Website's Content may not be appropriate or available for use outside the United States. International users access the Website at their own risk and are responsible for compliance with local laws.
        </p>
      </section>

      <section>
        <h3>Governing Law</h3>
        <p>
          These Terms of Use shall be governed, construed, and enforced in accordance with the laws of the State of Washington, USA. Disputes shall be resolved through arbitration in Seattle, WA, in accordance with the JAMS Streamlined Arbitration Rules and Procedures.
        </p>
      </section>

      <section>
        <h3>Customer Service</h3>
        <p>
          Questions or comments regarding the Website may be submitted to <a href="mailto:tos@teraace.com" className="link">tos@teraace.com</a>.
        </p>
      </section>
    </div>
  );
};

export default TermsOfUse;