import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../components/ScrollButton';

const ParallaxBox = ({ children, x, y, className }) => {
  const ref = React.useRef();
  const isInView = useInView(ref, { amount: 0.5 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x, y }}
      animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

const HeroBanner = () => {
  return (
    <ParallaxBanner style={{ aspectRatio: '2 / 1' }} className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/Teraace-bg-123.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            Enterprise-Grade Security<br/>
            <em>for Your User Data</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            We treat your users' data with the same care you do. Our comprehensive security program ensures your data is protected at every step.
          </h3>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

const SecurityInfrastructure = () => {
  return (
    <div className="section-inner">
      <h2>Security Infrastructure</h2>
      <div className="twobytwo">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Network Security</h3>
          <ul>
            <li>SSL/TLS with minimum TLS 1.2 for all services</li>
            <li>Cloud Armor firewall protection</li>
            <li>Geographic restriction to NA/EU traffic</li>
            <li>Rate limiting (20 requests/minute) against brute-force attacks</li>
            <li>CORS middleware for approved origins only</li>
            <li>Content Security Policy (CSP) headers</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Authentication & Authorization</h3>
          <ul>
            <li>JWT authentication with daily token expiration</li>
            <li>Revocable auth header tokens for 3rd party APIs</li>
            <li>Advanced encryption using HS256, RS256, and bcrypt</li>
            <li>Role-Based Access Control (RBAC)</li>
            <li>Personal Access Tokens (PAT) for secure transactions</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const InfrastructureSection = () => {
  return (
    <div className="section-inner">
      <div className="twobytwo">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Infrastructure Security</h3>
          <ul>
            <li>Google Cloud infrastructure</li>
            <li>Web Application Firewall (WAF) with preconfigured rules:</li>
            <li>SQL Injection protection</li>
            <li>Cross-Site Scripting (XSS) prevention</li>
            <li>Remote File Inclusion (RFI) protection</li>
            <li>Local File Inclusion (LFI) protection</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Endpoint Security</h3>
          <ul>
            <li>Complex password requirements</li>
            <li>Hard-drive encryption</li>
            <li>Zero remote access policy</li>
            <li>Multi-Factor Authentication (MFA) for Google Cloud access</li>
            <li>Signed transactions using Personal Access Tokens</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const DataProtection = () => {
  return (
    <div className="section-inner">
      <h2>Data Protection</h2>
      <div className="twobytwo">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Input Security</h3>
          <ul>
            <li>Input sanitization against SQL injection</li>
            <li>XSS attack prevention</li>
            <li>Rate limiting on API endpoints</li>
            <li>Secure data transmission protocols</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Access Controls</h3>
          <ul>
            <li>Role-based access management</li>
            <li>Granular permission settings</li>
            <li>Regular access reviews</li>
            <li>Automated access logging</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const Implementation = () => {
  return (
    <div className="section-inner">
      <h2>Implementation & Documentation</h2>
      <div className="twobytwo">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Security Resources</h3>
          <ul>
            <li>Implementation guidelines</li>
            <li>Security best practices</li>
            <li>Access control documentation</li>
            <li>API security documentation</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Technical Specifications</h3>
          <ul>
            <li>Detailed API documentation</li>
            <li>Authentication guides</li>
            <li>Security configuration guides</li>
            <li>Best practice recommendations</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

// const GettingStarted = () => {
//   return (
//     <div className="section-inner">
//       <h2>Getting Started</h2>
//       <div className="benefit-column">
//         <h3>Security Implementation</h3>
//         <ul>
//           <li>Review security documentation</li>
//           <li>Configure authentication</li>
//           <li>Set up access controls</li>
//           <li>Enable monitoring</li>
//           <li>Regular security reviews</li>
//         </ul>
//       </div>
//     </div>
//   );
// };


const GettingStarted = () => {
  return (
    <div className="section-inner">
      <h2>Getting Started</h2>
      <div className="steps-container">
        <div className="fivebyfive">
          <ParallaxBox x={0} y={20} className="five-column">
            <h3>1. Review security documentation</h3>
          </ParallaxBox>
          <ParallaxBox x={0} y={20} className="five-column">
            <h3>2. Configure authentication</h3>
          </ParallaxBox>
          <ParallaxBox x={0} y={20} className="five-column">
            <h3>3. Set up access controls</h3>
          </ParallaxBox>
          <ParallaxBox x={0} y={20} className="five-column">
            <h3>4. Enable monitoring</h3>
          </ParallaxBox>
          <ParallaxBox x={0} y={20} className="five-column">
            <h3>5. Regular security reviews</h3>
          </ParallaxBox>
        </div>
      </div>
    </div>
  );
};

const CTASection = () => {
  return (
    <div className="final-cta">
      <div className="section-inner">
        <h2>Stay Updated on Security</h2>
        <p>Subscribe to receive regular security updates, patch notifications, and best practice recommendations.</p>
        <div className="cta-buttons">
          <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
            Create Free Account
          </ScrollToTopButton>
          <ScrollToTopButton to="/requestcontact" className="secondary-cta">
            Contact Security Team
          </ScrollToTopButton>
        </div>
      </div>
    </div>
  );
};

const SecurityPrivacy = () => {
  return (
    <div className="homepage">
      <HeroBanner />
      <SecurityInfrastructure />
      <InfrastructureSection />
      <DataProtection />
      <Implementation />
      <GettingStarted />
      <CTASection />
    </div>
  );
};

const SecurityPrivacyPage = () => {
  return (
    <ParallaxProvider>
      <SecurityPrivacy />
    </ParallaxProvider>
  );
};

export default SecurityPrivacyPage;