import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import GlowBox from '../../components/GlowBox';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../../components/ScrollButton';

const ParallaxGlowBox = ({ children, glowColor, bgColor, x, y }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        exit="exit"
        variants={fadeInOut(x, y)}
      >
        <GlowBox glowColor={glowColor} bgColor={bgColor}>
          {children}
        </GlowBox>
      </motion.div>
    );
};

const fadeInOut = (x, y) => ({
    hidden: { opacity: 0, x, y },
    visible: {
      opacity: 1,
      x: '0%',
      y: '0%',
      transition: { duration: 0.5, ease: 'easeInOut' }
    },
    exit: { opacity: 0, x: -x, y: -y, transition: { duration: 0.5, ease: 'easeInOut' } }
});

const ParallaxBox = ({ children, x, y, className }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial={{ opacity: 0, x, y }}
        animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        className={className}
      >
        {children}
      </motion.div>
    );
};

const HeroBanner = () => {
  return (
    <ParallaxBanner className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/Teraace-bg-039.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            Feature Adoption<br/>
            <em>Drive Feature Success with ML-Powered Insights</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            Understand how users discover and adopt features in order to optimize their path to value.
          </h3>
          <div className="hero-cta-container">
            <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
              Boost Feature Adoption
            </ScrollToTopButton>
            <ScrollToTopButton to="/requestdemo" className="secondary-cta">
              See Adoption Analysis
            </ScrollToTopButton>
          </div>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

const ChallengesSection = () => {
  return (
    <div className="section-inner challenge-section xy-pad">
      <h2 className="standard-pad no-btm">Feature Adoption Challenges</h2>
      <div className="twobytwo">
        <ParallaxBox x={-50} y={0} className="panel-left">
          <div className="panel-content">
            <h3>Common Problems</h3>
            <ul>
              <li>Low discovery rates</li>
              <li>Unclear value proposition</li>
              <li>Complex learning curves</li>
              <li>Inconsistent usage</li>
              <li>Poor retention</li>
            </ul>
          </div>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="panel-right">
          <div className="panel-content">
            <h3>Teraace's Solutions</h3>
            <ul>
              <li>Pattern detection</li>
              <li>Success path mapping</li>
              <li>Usage prediction</li>
              <li>Value demonstration</li>
              <li>Adoption optimization</li>
            </ul>
          </div>
        </ParallaxBox>
      </div>
    </div>
  );
};

const FeaturesSection = () => {
  return (
    <div className="section-inner">
      <h2>Key Features</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="feature-column">
          <h3>Adoption Analyzer</h3>
          <ul>
            <li>Track discovery paths</li>
            <li>Monitor usage patterns</li>
            <li>Identify success factors</li>
            <li>Measure impact</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="feature-column">
          <h3>Success Path Mapper</h3>
          <ul>
            <li>Map successful journeys</li>
            <li>Identify friction points</li>
            <li>Optimize onboarding</li>
            <li>Track learning curves</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="feature-column">
          <h3>Impact Predictor</h3>
          <ul>
            <li>Forecast adoption rates</li>
            <li>Predict usage patterns</li>
            <li>Identify optimization opportunities</li>
            <li>Measure feature ROI</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const OptimizationSection = () => {
  return (
    <div className="product-showcase">
      <div className="section-inner">
        <div className="showcase-content">
          <div className="showcase-features">
            <h2>Data-Driven Approach</h2>
            <ul>
              <li>Identify successful patterns</li>
              <li>Optimize introduction timing</li>
              <li>Improve discovery flows</li>
              <li>Enhance user education</li>
            </ul>
          </div>
          <div className="showcase-preview">
            <img src="/images/Teraace-bg-004.webp" alt="Adoption Dashboard" className="rounded-lg shadow-xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

const MetricsSection = () => {
  return (
    <div className="section-inner">
      <h2>Success Metrics</h2>
      <div className="fivebyfive">
        <ParallaxBox x={0} y={20} className="metric-item">
          <h3>Adoption rates</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="metric-item">
          <h3>Time to first use</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="metric-item">
          <h3>Usage frequency</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="metric-item">
          <h3>Feature retention</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="metric-item">
          <h3>User satisfaction</h3>
        </ParallaxBox>
      </div>
    </div>
  );
};

const GetStartedSection = () => {
  return (
    <div className="section-inner bg-orange top-pad">
      <h2>Implementation Steps</h2>
      <div className="fourbyfour">
        <ParallaxBox x={0} y={20} className="step-item">
          <h3>1. Connect feature data</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="step-item">
          <h3>2. Analyze current patterns</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="step-item">
          <h3>3. Identify opportunities</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="step-item">
          <h3>4. Optimize and measure</h3>
        </ParallaxBox>
      </div>
    </div>
  );
};

const FeatureSection = () => {
  return (
    <div className="homepage">
      <HeroBanner />
      <ChallengesSection />
      <FeaturesSection />
      <OptimizationSection />
      <MetricsSection />
      <GetStartedSection />
      <div className="final-cta">
        <div className="section-inner">
          <h2>Start Boosting Feature Adoption Today</h2>
          <p>Transform your feature success with ML-powered adoption insights</p>
          <div className="cta-buttons">
            <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">Boost Feature Adoption</ScrollToTopButton>
            <ScrollToTopButton to="/requestdemo" className="secondary-cta">See Adoption Analysis</ScrollToTopButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeatureAdoption = () => {
  return (
    <ParallaxProvider>
      <FeatureSection />
    </ParallaxProvider>
  );
};

export default FeatureAdoption;