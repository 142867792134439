import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../../components/ScrollButton';

const ParallaxBox = ({ children, x, y, className }) => {
  const ref = React.useRef();
  const isInView = useInView(ref, { amount: 0.5 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x, y }}
      animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

const HeroBanner = () => {
  return (
    <ParallaxBanner style={{ aspectRatio: '2 / 1' }} className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/Teraace-bg-002.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            Deep Dive into<br/>
            <em>User Behavior Patterns</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            Move beyond basic analytics to truly understand how users navigate your product. Our suite of ML-powered exploration tools reveals patterns and relationships traditional analytics miss.
          </h3>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

const PathAnalyzerSection = () => {
  return (
    <div className="product-showcase bg-yt">
      <div className="section-inner">
        <div className="product-show-content">
          <div className="showcase-preview">
            <img src="/productgifs/Teraace-Path-Analyzer.gif" alt="3D Path Analyzer" className="rounded-lg shadow-xl" />
          </div>
          <div className="product-show-features">
            <h2>3D Path Analyzer</h2>
            <h3>Visualize Complex User Journeys</h3>
            <ul>
              <li>Interactive 3D visualization of user behavior patterns</li>
              <li>Zoom in from high-level flows to detailed interactions</li>
              <li>Identify connection points between different features</li>
              <li>Discover unexpected paths to user success</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const PeaksPatternSection = () => {
  return (
    <div className="product-showcase">
      <div className="section-inner">
        <div className="product-show-content">
          <div className="product-show-features">
            <h2>Peaks Pattern Discovery</h2>
            <h3>Surface Hidden Opportunities</h3>
            <ul>
              <li>Let ML identify significant behavioral patterns</li>
              <li>Discover feature combinations that drive success</li>
              <li>Understand how different user segments interact</li>
              <li>Find optimization opportunities you didn't know existed</li>
            </ul>
          </div>
          <div className="showcase-preview">
            <img src="/productgifs/Teraace-Peaks.gif" alt="Peaks Pattern Discovery" className="rounded-lg shadow-xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

const FeatureImportanceSection = () => {
  return (
    <div className="product-showcase bg-orange">
      <div className="section-inner">
        <div className="product-show-content">
          <div className="showcase-preview">
            <img src="/productgifs/Teraace-Feature-Insights.gif" alt="Feature Insights Analysis" className="rounded-lg shadow-xl" />
          </div>
          <div className="product-show-features">
            <h2>Feature Importance Analysis</h2>
            <h3>Understand What Truly Drives Success</h3>
            <ul>
              <li>See which features contribute most to user success</li>
              <li>Identify underutilized features with high potential</li>
              <li>Compare feature usage patterns across segments</li>
              <li>Predict impact of potential changes</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const TreeExplorerSection = () => {
  return (
    <div className="product-showcase bg-yt">
      <div className="section-inner">
        <div className="product-show-content">
          <div className="product-show-features">
            <h2>Tree Explorer</h2>
            <h3>Map Complex Decision Paths</h3>
            <ul>
              <li>Visualize decision trees of user behavior</li>
              <li>Understand how choices impact outcomes</li>
              <li>Compare successful vs struggling user paths</li>
              <li>Identify key decision points for optimization</li>
            </ul>
          </div>
          <div className="showcase-preview">
            <img src="/productgifs/Teraace-Tree-Explorer.gif" alt="Tree Explorer" className="rounded-lg shadow-xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

const CTASection = () => {
  return (
    <div className="final-cta">
      <div className="section-inner">
        <h2>Start Exploring User Patterns</h2>
        <p>Discover how your users actually navigate to success.</p>
        <div className="cta-buttons">
          <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
            Get Started Free
          </ScrollToTopButton>
          <ScrollToTopButton to="/requestdemo" className="secondary-cta">
            Schedule Demo
          </ScrollToTopButton>
        </div>
      </div>
    </div>
  );
};

const ExplorationPage = () => {
  return (
    <div className="homepage">
      <HeroBanner />
      <PathAnalyzerSection />
      <PeaksPatternSection />
      <FeatureImportanceSection />
      <TreeExplorerSection />
      <CTASection />
    </div>
  );
};

const Exploration = () => {
  return (
    <ParallaxProvider>
      <ExplorationPage />
    </ParallaxProvider>
  );
};

export default Exploration;