import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import GlowBox from '../../components/GlowBox';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../../components/ScrollButton';

const ParallaxGlowBox = ({ children, glowColor, bgColor, x, y }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        exit="exit"
        variants={fadeInOut(x, y)}
      >
        <GlowBox glowColor={glowColor} bgColor={bgColor}>
          {children}
        </GlowBox>
      </motion.div>
    );
};

const fadeInOut = (x, y) => ({
    hidden: { opacity: 0, x, y },
    visible: {
      opacity: 1,
      x: '0%',
      y: '0%',
      transition: { duration: 0.5, ease: 'easeInOut' }
    },
    exit: { opacity: 0, x: -x, y: -y, transition: { duration: 0.5, ease: 'easeInOut' } }
});

const ParallaxBox = ({ children, x, y, className }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial={{ opacity: 0, x, y }}
        animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        className={className}
      >
        {children}
      </motion.div>
    );
};

const HeroBanner = () => {
  return (
    <ParallaxBanner className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/Teraace-bg-115.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            User Journey Optimization<br/>
            <em>See How Users Actually Succeed</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            Let machine learning reveal the real paths users take to value, including patterns you never knew existed
          </h3>
          <div className="hero-cta-container">
            <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
              Start Optimizing Journeys
            </ScrollToTopButton>
            <ScrollToTopButton to="/requestdemo" className="secondary-cta">
              See Journey Analysis Demo
            </ScrollToTopButton>
          </div>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

const ChallengesSection = () => {
  return (
    <div className="section-inner">
      <h2>Why Journey Optimization Matters</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Common Journey Challenges</h3>
          <ul>
            <li>Unknown friction points</li>
            <li>Hidden success patterns</li>
            <li>Unclear feature relationships</li>
            <li>Unexpected user behaviors</li>
            <li>Complex cross-feature usage</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Beyond Basic Path Analysis</h3>
          <ul>
            <li>Discover multiple paths to success</li>
            <li>Identify unexpected winning routes</li>
            <li>See cross-feature journey patterns</li>
            <li>Understand segment-specific behaviors</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Our ML Solution</h3>
          <ul>
            <li>Automatic pattern discovery</li>
            <li>Real-time path analysis</li>
            <li>Success pattern identification</li>
            <li>Friction point detection</li>
            <li>Cross-feature journey mapping</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const FeaturesSection = () => {
  return (
    <div className="section-inner">
      <h2>Key Features</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>3D Path Analyzer</h3>
          <ul>
            <li>Visualize complex journeys</li>
            <li>Identify optimal paths</li>
            <li>Spot friction points</li>
            <li>Track cross-feature usage</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Pattern Discovery</h3>
          <ul>
            <li>Automatic success pattern detection</li>
            <li>Segment-specific journey analysis</li>
            <li>Feature relationship mapping</li>
            <li>Behavioral trend identification</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Journey Predictor</h3>
          <ul>
            <li>Forecast likely paths</li>
            <li>Identify potential roadblocks</li>
            <li>Suggest optimization points</li>
            <li>Predict success probability</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};
const MetricsSection = () => {
  return (
    <div className="product-showcase">
      <div className="section-inner">
        <div className="showcase-content">
          <div className="showcase-features">
            <h2>Success Metrics</h2>
            <ul>
              <li>Reduced time to value</li>
              <li>Increased completion rates</li>
              <li>Improved feature adoption</li>
              <li>Higher user satisfaction</li>
              <li>Decreased drop-off rates</li>
            </ul>
          </div>
          <div className="showcase-preview">
            <img src="/images/Teraace-bg-131.webp" alt="Platform preview" className="rounded-lg shadow-xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

const GetStartedSection = () => {
  return (
    <div className="section-inner">
      <h2>Getting Started</h2>
      <div className="steps-container">
        <div className="threebythree">
          <ParallaxBox x={0} y={20} className="benefit-column">
            <h3>1. Connect your data</h3>
          </ParallaxBox>
          <ParallaxBox x={0} y={20} className="benefit-column">
            <h3>2. Watch ML discover patterns</h3>
          </ParallaxBox>
          <ParallaxBox x={0} y={20} className="benefit-column">
            <h3>3. Identify optimization opportunities</h3>
          </ParallaxBox>
        </div>
      </div>
    </div>
  );
};

const JourneySection = () => {
  return (
    <div className="homepage">
      <HeroBanner />
      <ChallengesSection />
      <FeaturesSection />
      <MetricsSection />
      <GetStartedSection />
      <div className="final-cta">
        <div className="section-inner">
          <h2>Start Optimizing Your User Journeys Today</h2>
          <p>Transform your product experience with ML-powered journey optimization</p>
          <div className="cta-buttons">
            <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">Start Optimizing Journeys</ScrollToTopButton>
            <ScrollToTopButton to="/requestdemo" className="secondary-cta">See Journey Analysis Demo</ScrollToTopButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const JourneyOptimization = () => {
  return (
    <ParallaxProvider>
      <JourneySection />
    </ParallaxProvider>
  );
};

export default JourneyOptimization;
