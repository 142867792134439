import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../../components/ScrollButton';
// import SEO from '../../components/SEO';

const ParallaxBox = ({ children, x, y, className }) => {
  const ref = React.useRef();
  const isInView = useInView(ref, { amount: 0.5 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x, y }}
      animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

const HeroBanner = () => {
  return (
    <ParallaxBanner className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/Teraace-bg-079.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            Move Beyond Gut Feelings<br/>
            <em>to Data-Driven Conviction</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            Let machine learning reveal how users actually use your product, not just how you think they do.
          </h3>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

const KeyBenefits = () => {
  return (
    <div className="section-inner">
      <h2>Key Benefits</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Discover Hidden Patterns</h3>
          <ul>
            <li>Automatically identify successful user journeys</li>
            <li>Uncover feature combinations that drive engagement</li>
            <li>Spot emerging usage trends before they're obvious</li>
            <li>Learn which paths lead to user success</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Validate Product Decisions</h3>
          <ul>
            <li>Measure the true impact of product changes</li>
            <li>Understand how different user segments respond</li>
            <li>Track feature adoption and engagement</li>
            <li>Identify opportunities for optimization</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Predict User Behavior</h3>
          <ul>
            <li>Forecast likely user paths</li>
            <li>Identify potential friction points</li>
            <li>Anticipate feature adoption challenges</li>
            <li>Spot opportunities for improvement early</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const ProductFeatures = () => {
  return (
    <div className="section-inner">
      <h2>Features for Product Teams</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>3D Path Analyzer</h3>
          <ul>
            <li>Visualize complex user journeys</li>
            <li>Identify common paths to success</li>
            <li>Spot unexpected behavior patterns</li>
            <li>Understand feature relationships</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>Pattern Explorer</h3>
          <ul>
            <li>Automatic pattern detection</li>
            <li>Cross-feature usage analysis</li>
            <li>Segment comparison</li>
            <li>Success path identification</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Impact Prediction</h3>
          <ul>
            <li>Predict feature adoption</li>
            <li>Forecast user behavior changes</li>
            <li>Model different scenarios</li>
            <li>Measure actual vs. predicted impact</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const Applications = () => {
  return (
    <div className="section-inner">
      <h2>Real-World Applications</h2>
      <div className="threebythree">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Feature Development</h3>
          <ul>
            <li>Identify which features drive retention</li>
            <li>Understand feature relationships</li>
            <li>Validate development priorities</li>
            <li>Measure feature success</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={0} y={50} className="benefit-column">
          <h3>User Journey Optimization</h3>
          <ul>
            <li>Map successful user paths</li>
            <li>Identify friction points</li>
            <li>Optimize onboarding flows</li>
            <li>Improve feature discovery</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Product Strategy</h3>
          <ul>
            <li>Data-driven roadmap planning</li>
            <li>Feature impact assessment</li>
            <li>User behavior forecasting</li>
            <li>Success pattern replication</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

// const CaseStudy = () => {
//   return (
//     <div className="section-inner">
//       <h2>Success Stories</h2>
//       <div className="testimonial-box">
//         <img src="/images/example.webp" alt="Company Logos" className="logo-grid" />
//         <div className="testimonial-content">
//           <blockquote>
//             "Teraace helped us identify user patterns we never knew existed, leading to a 40% increase in feature adoption."
//           </blockquote>
//           <cite>- Sarah Chen, Head of Product at [Company]</cite>
//         </div>
//         <ScrollToTopButton to="/case-studies" className="secondary-cta">
//           Read More Case Studies
//         </ScrollToTopButton>
//       </div>
//     </div>
//   );
// };

const GetStarted = () => {
  return (
    <div className="final-cta">
      <div className="section-inner">
        <h2>Ready to Transform Your Product Understanding?</h2>
        <div className="cta-buttons">
          <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
            Start Free Trial
          </ScrollToTopButton>
          <ScrollToTopButton to="/requestdemo" className="secondary-cta">
            See Product Demo
          </ScrollToTopButton>
        </div>
      </div>
    </div>
  );
};

const ProductTeams = () => {
  return (
    <div className="homepage">
      <HeroBanner />
      <KeyBenefits />
      <ProductFeatures />
      <Applications />
      {/* <CaseStudy /> */}
      <GetStarted />
    </div>
  );
};

const ProductTeamsPage = () => {
  return (
    <ParallaxProvider>
      <ProductTeams />
    </ParallaxProvider>
  );
};

export default ProductTeamsPage;