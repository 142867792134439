import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mantine/core';

const ScrollToTopButton = ({ to, children, ...props }) => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'auto', // Optional: 'smooth' for smooth scrolling
    });

    // Check if the URL is external
    if (to.startsWith('http://') || to.startsWith('https://')) {
      window.location.href = to;
    } else {
      navigate(to);
    }
  };

  return (
    <Button {...props} onClick={handleClick}>
      {children}
    </Button>
  );
};

export default ScrollToTopButton;


// import { Link, useNavigate } from 'react-router-dom';
// import { Button } from '@mantine/core';

// const ScrollToTopButton = ({ to, children, ...props }) => {
//   const navigate = useNavigate();

//   const handleClick = (event) => {
//     event.preventDefault();
//     window.scrollTo({
//       top: 0,
//       behavior: 'auto', // Optional: 'smooth' for smooth scrolling
//     });
//     navigate(to);
//   };

//   return (
//     <Button {...props} onClick={handleClick}>
//       {children}
//     </Button>
//   );
// };

// export default ScrollToTopButton;
