import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import '@mantine/core/styles.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);

// Optional: Uncomment if you want to log web vitals
reportWebVitals(console.log);

// import React from 'react';
// // import ReactDOM from 'react-dom';
// import '@mantine/core/styles.css';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { createRoot } from 'react-dom/client';
// import { HelmetProvider } from 'react-helmet-async';

// // ReactDOM.render(<App />, document.getElementById('root'));

// const container = document.getElementById('root');
// const root = createRoot(container);
// root.render(
    
//   <React.StrictMode>
//         <HelmetProvider>
//         <App />
//         </HelmetProvider>
//         </React.StrictMode>
// );

// reportWebVitals(console.log);

// // window.addEventListener('beforeunload', function() {
// //     for (let key in localStorage) {
// //         if (key !== 'authToken') {
// //             localStorage.removeItem(key);
// //         }
// //     }
    
// // });