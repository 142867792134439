import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const DimensionContext = React.createContext();

const ResponsiveWrapper = ({ children }) => {
  const mainContentRef = useRef(null);
  const [mainContentStyle, setMainContentStyle] = useState({});

  useEffect(() => {
    const updateDimensions = () => {
      if (mainContentRef.current && mainContentRef.current.parentNode) {
        const parent = mainContentRef.current.parentNode;
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
  
        const sidebarElement = parent.querySelector('.sidebar-container');
        let sidebarWidth = sidebarElement ? sidebarElement.offsetWidth : 0;
        // Fallback: If sidebarWidth is 0, set it to viewportWidth
        if (sidebarWidth === 0) {
          sidebarWidth = viewportWidth;
        }
  
        setMainContentStyle({
          width: viewportWidth,
          height: viewportHeight,
        });
      }
    };
  
    // Set initial dimensions
    updateDimensions();
  
    // Add resize event listener
    window.addEventListener('resize', updateDimensions);
  
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);
  
  return (
    <DimensionContext.Provider value={mainContentStyle}>
      <div 
        ref={mainContentRef} 
        style={{ 
          width: `${mainContentStyle.width}px`, 
          height: `${mainContentStyle.height}px` 
        }} 
        className="responsivewrapper"
      >
        {children}
      </div>
    </DimensionContext.Provider>
  );
};

ResponsiveWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ResponsiveWrapper, DimensionContext };
export default ResponsiveWrapper;



// import React, { useRef, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';

// const DimensionContext = React.createContext();

// const ResponsiveWrapper = ({ children }) => {
//   const mainContentRef = useRef(null);
//   const [mainContentStyle, setMainContentStyle] = useState({});

//   useEffect(() => {
//     if (mainContentRef.current && mainContentRef.current.parentNode) {
//       const parent = mainContentRef.current.parentNode;

//       const viewportWidth = window.innerWidth;
//       const viewportHeight = window.innerHeight;

//       const sidebarElement = parent.querySelector('.sidebar-container');
//       let sidebarWidth = sidebarElement ? sidebarElement.offsetWidth : 0;
//       // Fallback: If sidebarWidth is 0, set it to 25% of viewportWidth
//       if (sidebarWidth === 0) {
//         sidebarWidth = viewportWidth;
//       }

//       const mainContentWidth = viewportWidth;
//       const mainContentHeight = viewportHeight;

//       setMainContentStyle({
//         width: mainContentWidth,
//         height: mainContentHeight,
//       });
//     }
//   }, [mainContentRef]);

//   return (
//     <DimensionContext.Provider value={mainContentStyle}>
//       <div 
//         ref={mainContentRef} 
//         style={{ 
//           width: `${mainContentStyle.width}px`, 
//           height: `${mainContentStyle.height}px` 
//         }} 
//         className="responsivewrapper"
//       >
//         {children}
//       </div>
//     </DimensionContext.Provider>
//   );
// };

// ResponsiveWrapper.propTypes = {
//   children: PropTypes.node.isRequired,
// };

// export { ResponsiveWrapper, DimensionContext };
// export default ResponsiveWrapper;





// // import React, { useRef, useEffect, useState } from 'react';
// // import PropTypes from 'prop-types';

// // export const DimensionContext = React.createContext();

// // export const ResponsiveWrapper = ({ children }) => {
// //   const mainContentRef = useRef(null);
// //   const [mainContentStyle, setMainContentStyle] = useState({});

// //   useEffect(() => {
// //     if (mainContentRef.current && mainContentRef.current.parentNode) {
// //       const parent = mainContentRef.current.parentNode;
// //       // const sidebarWidth = Array.from(parent.children).reduce(
// //       //   (width, child) => (child !== mainContentRef.current ? width + child.offsetWidth : width),
// //       //   0
// //       // );


// //       const viewportWidth = window.innerWidth;
// //       const viewportHeight = window.innerHeight;

// //       const sidebarElement = parent.querySelector('.sidebar-container');
// //       let sidebarWidth = sidebarElement ? sidebarElement.offsetWidth : 0;
// //       // Fallback: If sidebarWidth is 0, set it to 25% of viewportWidth
// //       if (sidebarWidth === 0) {
// //         sidebarWidth = viewportWidth;
// //         // sidebarWidth = viewportWidth * (1/7);
// //       }
// //       // console.log("viewportWidth:", viewportWidth);
// //       // console.log("sidebarWidth:", sidebarWidth);
// //       // console.log("viewportHeight:", viewportHeight);

// //       const mainContentWidth = viewportWidth;
// //       const mainContentHeight = viewportHeight;

// //       // console.log("mainContentWidth:", mainContentWidth);
// //       // console.log("mainContentHeight:", mainContentHeight);

// //       setMainContentStyle({
// //         width: mainContentWidth,
// //         height: mainContentHeight,
// //       });
// //     }
// //   }, [mainContentRef]);

// //   return (
// //     <DimensionContext.Provider value={mainContentStyle}>
// //       {/* <div ref={mainContentRef} style={mainContentStyle} className="h-screen"> */}

// //       <div ref={mainContentRef} style={{ width: `${mainContentStyle.width}px`, height: `${mainContentStyle.height}px` }} className="responsivewrapper">
// //         {children}
// //       </div>
// //     </DimensionContext.Provider>
// //   );
// // };

// // ResponsiveWrapper.propTypes = {
// //   children: PropTypes.node.isRequired,
// // };
