import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../../components/ScrollButton';

const HeroBanner = () => {
  return (
    <ParallaxBanner style={{ aspectRatio: '2 / 1' }} className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/Teraace-bg-032.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            Transform User Insights<br/>
            <em>into Automated Actions</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            Move beyond passive analytics. Our intelligent action system automatically responds to user behavior patterns, 
            helping you deliver the right intervention at the right time through powerful webhook automation.
          </h3>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

const TriggerSection = () => {
  return (
    <div className="product-showcase bg-yt">
      <div className="section-inner">
        <div className="product-show-content">
          <div className="product-show-features">
            <h2>Pattern-Based Triggers</h2>
            <h3>Intelligent Event Detection</h3>
            <ul>
              <li>Set sophisticated behavioral pattern triggers using ML-discovered insights</li>
              <li>Create compound conditions based on multiple user actions and metrics</li>
              <li>Define custom thresholds for different user segments and behaviors</li>
              <li>Chain multiple conditions together for precise targeting</li>
              <li>Use ML-powered anomaly detection to spot unusual patterns</li>
            </ul>
            <div className="mt-4">
              <h3 className="font-medium mb-2">Key Trigger Types:</h3>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <h4 className="font-medium">Behavior Patterns</h4>
                  <p className="text-sm">Match complex user journeys</p>
                </div>
                <div>
                  <h4 className="font-medium">Success Path Deviations</h4>
                  <p className="text-sm">Identify risk early</p>
                </div>
                <div>
                  <h4 className="font-medium">Engagement Thresholds</h4>
                  <p className="text-sm">React to usage changes</p>
                </div>
                <div>
                  <h4 className="font-medium">Time-Based Sequences</h4>
                  <p className="text-sm">Schedule perfect timing</p>
                </div>
              </div>
            </div>
          </div>
          <div className="showcase-preview">
            <img src="/productgifs/Teraace-Data-Triggers.gif" alt="Pattern Triggers Dashboard" className="rounded-lg shadow-xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

const WebhookSection = () => {
  return (
    <div className="product-showcase">
      <div className="section-inner">
        <div className="product-show-content">
        <div className="showcase-preview">
            <img src="/productgifs/Teraace-Webhooks.gif" alt="Webhook Configuration" className="rounded-lg shadow-xl" />
          </div>
          <div className="product-show-features">
            <h2>Webhook Automation System</h2>
            <h3>Turn Insights into Action</h3>
            <div className="grid grid-cols-2 gap-6">
              <div className="webhook-example p-4 bg-gray-50 rounded-lg">
                <h4 className="font-medium mb-2">Webhook 1: Engagement Recovery</h4>
                <p className="text-sm mb-2">When: User shows declining engagement</p>
                <ul className="text-sm">
                  <li>→ Update MAP segments</li>
                  <li>→ Trigger email campaign</li>
                  <li>→ Create CS team task</li>
                  <li>→ Modify in-app experience</li>
                </ul>
              </div>
              <div className="webhook-example p-4 bg-gray-50 rounded-lg">
                <h4 className="font-medium mb-2">Webhook 2: Success Path Optimization</h4>
                <p className="text-sm mb-2">When: Path deviation detected</p>
                <ul className="text-sm">
                  <li>→ Adjust UI elements</li>
                  <li>→ Send help resources</li>
                  <li>→ Update personalization</li>
                  <li>→ Log to analytics</li>
                </ul>
              </div>
              <div className="webhook-example p-4 bg-gray-50 rounded-lg">
                <h4 className="font-medium mb-2">Webhook 3: Conversion Opportunity</h4>
                <p className="text-sm mb-2">When: High purchase intent detected</p>
                <ul className="text-sm">
                  <li>→ Update CRM records</li>
                  <li>→ Modify pricing display</li>
                  <li>→ Adjust feature visibility</li>
                  <li>→ Personalize messaging</li>
                </ul>
              </div>
              <div className="webhook-example p-4 bg-gray-50 rounded-lg">
                <h4 className="font-medium mb-2">Webhook 4: Risk Mitigation</h4>
                <p className="text-sm mb-2">When: Churn risk identified</p>
                <ul className="text-sm">
                  <li>→ Alert success team</li>
                  <li>→ Trigger retention workflow</li>
                  <li>→ Adjust product experience</li>
                  <li>→ Update risk scores</li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

const IntegrationSection = () => {
  return (
    <div className="product-showcase bg-orange">
      <div className="section-inner">
        <div className="product-show-content">
          <div className="product-show-features">
            <h2>Integration Examples</h2>
            <h3>Popular Use Cases</h3>
            <div className="grid grid-cols-3 gap-4">
              <div className="integration-card p-4 bg-white rounded-lg shadow">
                <h3 className="font-medium">Marketing Automation</h3>
                <ul className="text-sm mt-2">
                  <li>Update user segments</li>
                  <li>Trigger email campaigns</li>
                  <li>Modify lead scoring</li>
                  <li>Sync engagement data</li>
                </ul>
              </div>
              <div className="integration-card p-4 bg-white rounded-lg shadow">
                <h3 className="font-medium">Website Personalization</h3>
                <ul className="text-sm mt-2">
                  <li>Update content</li>
                  <li>Modify UI elements</li>
                  <li>Adjust feature visibility</li>
                  <li>Personalize pricing</li>
                </ul>
              </div>
              <div className="integration-card p-4 bg-white rounded-lg shadow">
                <h3 className="font-medium">CRM Integration</h3>
                <ul className="text-sm mt-2">
                  <li>Update contact records</li>
                  <li>Create tasks</li>
                  <li>Trigger follow-ups</li>
                  <li>Log milestones</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="showcase-preview">
            <img src="/images/Teraace-bg-011.webp" alt="Integration Examples" className="rounded-lg shadow-xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

const MonitoringSection = () => {
  return (
    <div className="product-showcase">
      <div className="section-inner">
        <div className="product-show-content">
          <div className="product-show-features">
            <h2>Performance Monitoring</h2>
            <h3>Measure Action Impact</h3>
            <ul>
              <li>Track webhook delivery success rates and response times</li>
              <li>Monitor pattern trigger accuracy and effectiveness</li>
              <li>Analyze action impact on user behavior and success metrics</li>
              <li>Debug and optimize webhook configurations in real-time</li>
              <li>A/B test different automation strategies</li>
            </ul>
          </div>
          <div className="showcase-preview">
            <img src="/productgifs/Teraace-Action-Dashboard.gif" alt="Performance Monitoring" className="rounded-lg shadow-xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

const CTASection = () => {
  return (
    <div className="final-cta">
      <div className="section-inner">
        <h2>Start Automating Today</h2>
        <p>Transform insights into immediate action with intelligent pattern-based automation.</p>
        <div className="cta-buttons">
          <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
            Get Started Free
          </ScrollToTopButton>
          <ScrollToTopButton to="/requestdemo" className="secondary-cta">
            Schedule Demo
          </ScrollToTopButton>
        </div>
      </div>
    </div>
  );
};

const ActionsPage = () => {
  return (
    <div className="homepage">
      <HeroBanner />
      <TriggerSection />
      <WebhookSection />
      <IntegrationSection />
      <MonitoringSection />
      <CTASection />
    </div>
  );
};

const Actions = () => {
  return (
    <ParallaxProvider>
      <ActionsPage />
    </ParallaxProvider>
  );
};

export default Actions;