import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import GlowBox from '../../components/GlowBox';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../../components/ScrollButton';

const ParallaxGlowBox = ({ children, glowColor, bgColor, x, y }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        exit="exit"
        variants={fadeInOut(x, y)}
      >
        <GlowBox glowColor={glowColor} bgColor={bgColor}>
          {children}
        </GlowBox>
      </motion.div>
    );
};

const fadeInOut = (x, y) => ({
    hidden: { opacity: 0, x, y },
    visible: {
      opacity: 1,
      x: '0%',
      y: '0%',
      transition: { duration: 0.5, ease: 'easeInOut' }
    },
    exit: { opacity: 0, x: -x, y: -y, transition: { duration: 0.5, ease: 'easeInOut' } }
});

const ParallaxBox = ({ children, x, y, className }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial={{ opacity: 0, x, y }}
        animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        className={className}
      >
        {children}
      </motion.div>
    );
};

const HeroBanner = () => {
    return (
      <ParallaxBanner className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/Teraace-bg-095.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">
              Product-Led Growth<br/>
              <em>Let Your Product Drive</em>
            </h1>
            <h3 className="parallax-hero-subtext">
              Use ML-powered insights to optimize your product's natural ability to acquire, convert, and expand users.
            </h3>
            <div className="hero-cta-container">
              <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
                Start PLG Analytics
              </ScrollToTopButton>
              <ScrollToTopButton to="/requestdemo" className="secondary-cta">
                See How It Works
              </ScrollToTopButton>
            </div>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };
  
  const CorePrinciplesSection = () => {
    return (
      <div className="section-inner">
        <h2>Core PLG Principles</h2>
        <div className="threebythree">
          <ParallaxBox x={-50} y={0} className="feature-column">
            <h3>Value Before Monetization</h3>
            <ul>
              <li>Users experience value before paying</li>
              <li>Natural upgrade paths as usage grows</li>
              <li>Pricing aligned with received value</li>
              <li>Focus on product quality over sales</li>
            </ul>
          </ParallaxBox>
          
          <ParallaxBox x={0} y={50} className="feature-column">
            <h3>Frictionless Experience</h3>
            <ul>
              <li>Self-service signup and onboarding</li>
              <li>Immediate access to core features</li>
              <li>Built-in user education</li>
              <li>Minimal setup requirements</li>
            </ul>
          </ParallaxBox>
          
          <ParallaxBox x={50} y={0} className="feature-column">
            <h3>Viral Growth</h3>
            <ul>
              <li>Product naturally encourages sharing</li>
              <li>Network effects drive expansion</li>
              <li>Teams organically adopt together</li>
              <li>Value increases with usage</li>
            </ul>
          </ParallaxBox>
        </div>
      </div>
    );
  };
  
  const PLGStagesSection = () => {
    return (
      <div className="product-showcase">
        <div className="section-inner">
          <div className="showcase-content">
            <div className="showcase-features">
              <h2>The PLG Journey</h2>
              <ul>
                <li>
                  <strong>Acquisition:</strong> Users discover and try your product through self-service channels
                </li>
                <li>
                  <strong>Activation:</strong> Users experience core value quickly through intuitive onboarding
                </li>
                <li>
                  <strong>Adoption:</strong> Users integrate the product into their workflow and discover more features
                </li>
                <li>
                  <strong>Expansion:</strong> Usage naturally grows across teams and use cases
                </li>
                <li>
                  <strong>Advocacy:</strong> Satisfied users share and recommend the product
                </li>
              </ul>
            </div>
            <div className="showcase-preview">
              <img src="/images/Teraace-bg-101.webp" alt="PLG Journey" className="rounded-lg shadow-xl" />
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  const AnalyticsSection = () => {
    return (
      <div className="section-inner">
        <h2>How Teraace Optimizes PLG</h2>
        <div className="threebythree">
          <ParallaxBox x={-50} y={0} className="feature-column">
            <h3>User Journey Analysis</h3>
            <ul>
              <li>Track paths to activation</li>
              <li>Identify value realization points</li>
              <li>Map feature discovery patterns</li>
              <li>Measure time to core value</li>
            </ul>
          </ParallaxBox>
          
          <ParallaxBox x={0} y={50} className="feature-column">
            <h3>Growth Pattern Detection</h3>
            <ul>
              <li>Analyze viral sharing patterns</li>
              <li>Track team adoption waves</li>
              <li>Monitor expansion triggers</li>
              <li>Identify advocacy moments</li>
            </ul>
          </ParallaxBox>
          
          <ParallaxBox x={50} y={0} className="feature-column">
            <h3>Revenue Intelligence</h3>
            <ul>
              <li>Predict conversion readiness</li>
              <li>Optimize pricing signals</li>
              <li>Forecast expansion revenue</li>
              <li>Track customer acquisition cost</li>
            </ul>
          </ParallaxBox>
        </div>
      </div>
    );
  };
  
  const MetricsSection = () => {
    return (
      <div className="section-inner bg-orange top-pad">
        <h2>Key PLG Metrics</h2>
        <div className="fivebyfive">
          <ParallaxBox x={0} y={20} className="metric-item">
            <h3>Time to Value</h3>
            <p>How quickly users achieve their first success</p>
          </ParallaxBox>
          <ParallaxBox x={0} y={20} className="metric-item">
            <h3>Product-Qualified Leads</h3>
            <p>Users showing signs of conversion readiness</p>
          </ParallaxBox>
          <ParallaxBox x={0} y={20} className="metric-item">
            <h3>Expansion Revenue</h3>
            <p>Growth from existing customer base</p>
          </ParallaxBox>
          <ParallaxBox x={0} y={20} className="metric-item">
            <h3>Viral Coefficient</h3>
            <p>Rate of organic user acquisition</p>
          </ParallaxBox>
          <ParallaxBox x={0} y={20} className="metric-item">
            <h3>Net Dollar Retention</h3>
            <p>Revenue growth from existing customers</p>
          </ParallaxBox>
        </div>
      </div>
    );
  };

const GetStartedSection = () => {
  return (
    <div className="section-inner">
      <h2>Quick Start Guide</h2>
      <div className="fourbyfour">
        <ParallaxBox x={0} y={20} className="step-item">
          <h3>1. Connect product data</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="step-item">
          <h3>2. Map success patterns</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="step-item">
          <h3>3. Identify opportunities</h3>
        </ParallaxBox>
        <ParallaxBox x={0} y={20} className="step-item">
          <h3>4. Optimize and scale</h3>
        </ParallaxBox>
      </div>
    </div>
  );
};

const PLGSection = () => {
    return (
      <div className="homepage">
        <HeroBanner />
        <CorePrinciplesSection />
        <PLGStagesSection />
        <AnalyticsSection />
        <MetricsSection />
        <GetStartedSection />
        <div className="final-cta">
          <div className="section-inner">
            <h2>Transform Your Growth Strategy</h2>
            <p>Let your product lead the way with ML-powered insights that optimize every stage of the PLG journey</p>
            <div className="cta-buttons">
              <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">Start PLG Analytics</ScrollToTopButton>
              <ScrollToTopButton to="/requestdemo" className="secondary-cta">See How It Works</ScrollToTopButton>
            </div>
          </div>
        </div>
      </div>
    );
  };

const PLGAnalytics = () => {
  return (
    <ParallaxProvider>
      <PLGSection />
    </ParallaxProvider>
  );
};

export default PLGAnalytics;