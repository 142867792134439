import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../components/ScrollButton';

const ParallaxBox = ({ children, x, y, className }) => {
  const ref = React.useRef();
  const isInView = useInView(ref, { amount: 0.5 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x, y }}
      animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

const HeroBanner = () => {
  return (
    <ParallaxBanner className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/Teraace-bg-051.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">
            Flexible Data Collection<br/>
            <em>That Works Your Way</em>
          </h1>
          <h3 className="parallax-hero-subtext">
            Choose the integration method that best fits your needs, or combine multiple approaches for comprehensive coverage.
          </h3>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
};

/* ----------------------------------
   1) AUTO-CAPTURE SCRIPT SECTION
---------------------------------- */
const AutoCaptureSection = () => {
  return (
    <div className="section-inner">
      <h2>Auto-Capture Script</h2>
      <div className="twobytwo">
        {/* Left Column */}
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Instant Insights, Zero Configuration</h3>
          <p className="section-intro">
            Drop in our lightweight JavaScript snippet and start collecting user behavior data immediately.
          </p>
          
          <h4 className="feature-heading">Key Features</h4>
          <ul className="feature-list">
            <li>One-line installation</li>
            <li>Automatic event capture</li>
            <li>Smart sampling to minimize performance impact</li>
            <li>Configurable privacy controls</li>
            <li>Built-in sensitive data filtering</li>
            <li>Under 10kb gzipped</li>
          </ul>
        </ParallaxBox>

        {/* Right Column (Code Snippet) */}
        <ParallaxBox x={50} y={0} className="benefit-column">
        <h4 className="feature-heading">Best For</h4>
          <ul className="feature-list">
            <li>Quick implementation</li>
            <li>Maximum coverage with minimum effort</li>
            <li>Teams without extensive engineering resources</li>
            <li>Rapid prototyping and testing</li>
          </ul>
          
          <h4 className="feature-heading">Sample Implementation</h4>
          <div className="code-block">
            <pre>
              <code
                dangerouslySetInnerHTML={{
                  __html: `
Standard Use:
&lt;script defer src="https://api.teraace.com/happypath.js" data-org-id="ORG_ID_HERE"&gt;&lt;/script&gt;

Including Custom Data:
&lt;script&gt;
  window.myCustomData = {
    UserID: 'user123',
    SubscriptionTier: 'premium'
  };
&lt;/script&gt;

&lt;script defer type="text/javascript" src="https://api.teraace.com/happypath.js" data-id="ORG_ID_HERE"&gt;&lt;/script&gt;
                  `
                }}
              />
            </pre>
          </div>
        </ParallaxBox>
      </div>
    </div>
  );
};

/* ----------------------------------
   2) DIRECT API SECTION
---------------------------------- */
const DirectAPISection = () => {
  return (
    <div className="section-inner">
      <h2>Direct API</h2>
      <div className="twobytwo">
        {/* Left Column */}
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Complete Control Over Your Data</h3>
          <p className="section-intro">
            Send exactly the events you want, when you want, with our comprehensive REST API.
          </p>

          <h4 className="feature-heading">Key Features</h4>
          <ul className="feature-list">
            <li>RESTful JSON API</li>
            <li>Batch event support</li>
            <li>Custom property mapping</li>
            <li>Strong type checking</li>
            <li>Detailed error reporting</li>
            <li>Real-time data validation</li>
            <li>SDK support for major languages</li>
          </ul>

          <h4 className="feature-heading">Best For</h4>
          <ul className="feature-list">
            <li>Maximum control over data collection</li>
            <li>Custom implementation requirements</li>
            <li>High-security environments</li>
            <li>Complex data transformation needs</li>
          </ul>
        </ParallaxBox>

        {/* Right Column (API Details) */}
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h4 className="feature-heading">API Details & Sample Request</h4>
          <p>
            <strong>API URL:</strong> https://api.teraace.com/tpd/private<br />
            <strong>Method:</strong> POST<br />
            <strong>Authentication:</strong> Bearer token (generated in Admin)<br />
            <strong>Headers:</strong> Content-Type: application/json
          </p>
          <div className="code-block">
            <pre>
              <code>
{`POST https://api.teraace.com/tpd/private
Content-Type: application/json
Authorization: Bearer YOUR_AUTH_TOKEN_HERE

{
  "event": "Login",
  "url": "/login",
  "timestamp": "2024-01-17T20:20:51.080Z"
}`}
              </code>
            </pre>
          </div>

          <h4 className="feature-heading">Minimum Data</h4>
          <ul className="feature-list">
            <li>Event Name</li>
            <li>URL</li>
            <li>Timestamp</li>
          </ul>

          <h4 className="feature-heading">Suggested Data</h4>
          <ul className="feature-list">
            <li>UserID, OrgID</li>
            <li>Subscription Plan (ARR, etc.)</li>
            <li>Persona or ICP data</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

/* ----------------------------------
   3) DATA PIPELINE SECTION
---------------------------------- */
const DataPipelineSection = () => {
  return (
    <div className="section-inner">
      <h2>Data Pipeline Integration</h2>
      <div className="twobytwo">
        {/* Left Column */}
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Use Your Existing Data Infrastructure</h3>
          <p className="section-intro">
            Leverage your current data pipeline investments with our native integrations.
          </p>

          <h4 className="feature-heading">Supported Platforms</h4>
          <ul className="feature-list">
            <li>Segment</li>
            <li>Rudderstack</li>
            <li>Snowplow</li>
            <li>mParticle</li>
            <li>Custom ETL pipelines</li>
          </ul>

          <h4 className="feature-heading">Key Features</h4>
          <ul className="feature-list">
            <li>Native platform support</li>
            <li>Automatic schema mapping</li>
            <li>Real-time sync</li>
            <li>Historical data import</li>
            <li>Transformation support</li>
            <li>Error recovery</li>
            <li>Bi-directional sync options</li>
          </ul>
        </ParallaxBox>

        {/* Right Column (Segment Endpoint) */}
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h4 className="feature-heading">Best For</h4>
          <ul className="feature-list">
            <li>Organizations with existing data infrastructure</li>
            <li>Complex data environments</li>
            <li>Multi-source data collection</li>
            <li>Enterprise deployment</li>
          </ul>

          <h4 className="feature-heading">Sample Segment Request</h4>
          <div className="code-block">
            <pre>
              <code>
{`POST https://api.teraace.com/tpd/segment
Content-Type: application/json
Authorization: Bearer YOUR_AUTH_TOKEN_HERE

{
  "event": "Login",
  "url": "/login",
  "timestamp": "2024-01-17T20:20:51.080Z"
}`}
              </code>
            </pre>
          </div>
        </ParallaxBox>
      </div>
    </div>
  );
};

const SecuritySection = () => {
  return (
    <div className="section-inner">
      <h2>Data Security & Privacy</h2>
      <div className="twobytwo">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Built-in Protections</h3>
          <ul className="feature-list">
            <li>Automatic PII detection and filtering</li>
            <li>Data encryption in transit and at rest</li>
            <li>Configurable data retention</li>
            <li>GDPR compliance tools</li>
            <li>Customizable privacy controls</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Compliance & Certifications</h3>
          <ul className="feature-list">
            <li>SOC 2 Type II certified</li>
            <li>GDPR compliant</li>
            <li>CCPA ready</li>
            <li>ISO 27001 certified</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const ImplementationSupport = () => {
  return (
    <div className="section-inner">
      <h2>Implementation Support</h2>
      <div className="twobytwo">
        <ParallaxBox x={-50} y={0} className="benefit-column">
          <h3>Resources for Success</h3>
          <ul className="feature-list">
            <li>Detailed implementation guides</li>
            <li>Sample code repositories</li>
            <li>Interactive debugging tools</li>
            <li>Schema validation tools</li>
            <li>Best practice documentation</li>
          </ul>
        </ParallaxBox>
        
        <ParallaxBox x={50} y={0} className="benefit-column">
          <h3>Expert Help Available</h3>
          <ul className="feature-list">
            <li>Implementation consulting</li>
            <li>Technical support</li>
            <li>Custom integration assistance</li>
            <li>Migration support</li>
          </ul>
        </ParallaxBox>
      </div>
    </div>
  );
};

const FAQ = () => {
  return (
    <div className="section-inner">
      <h2>FAQ</h2>
      <div className="benefit-column">
        <h3>Common Questions</h3>
        <ul className="feature-list">
          <li>How much data should I collect?</li>
          <li>Which events should I track?</li>
          <li>How do I handle sensitive data?</li>
          <li>What about mobile apps?</li>
          <li>How do I test my implementation?</li>
        </ul>
        <div className="cta-buttons">
          <ScrollToTopButton to="/faq" className="inlink-button">
            View Full FAQ
          </ScrollToTopButton>
        </div>
      </div>
    </div>
  );
};

const GetStarted = () => {
  return (
    <div className="final-cta">
      <div className="section-inner">
        <h2>Need Help Choosing?</h2>
        <p>
          Our integration experts can help you select and implement the right data collection method for your needs.
        </p>
        <div className="cta-buttons">
          <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
            Create Free Account
          </ScrollToTopButton>
          <ScrollToTopButton to="/requestcontact" className="secondary-cta">
            Schedule a Consultation
          </ScrollToTopButton>
        </div>
      </div>
    </div>
  );
};

const DataCollection = () => {
  return (
    <div className="homepage">
      <HeroBanner />
      <AutoCaptureSection />
      <DirectAPISection />
      <DataPipelineSection />
      <SecuritySection />
      <ImplementationSupport />
      <FAQ />
      <GetStarted />
    </div>
  );
};

const DataCollectionPage = () => {
  return (
    <ParallaxProvider>
      <DataCollection />
    </ParallaxProvider>
  );
};

export default DataCollectionPage;




// import React from 'react';
// import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
// import { motion, useInView } from 'framer-motion';
// import ScrollToTopButton from '../components/ScrollButton';

// const ParallaxBox = ({ children, x, y, className }) => {
//   const ref = React.useRef();
//   const isInView = useInView(ref, { amount: 0.5 });

//   return (
//     <motion.div
//       ref={ref}
//       initial={{ opacity: 0, x, y }}
//       animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
//       transition={{ duration: 0.5, ease: 'easeInOut' }}
//       className={className}
//     >
//       {children}
//     </motion.div>
//   );
// };

// const HeroBanner = () => {
//   return (
//     <ParallaxBanner className="parallax-hero">
//       <ParallaxBannerLayer
//         image="/images/Teraace-bg-051.webp"
//         speed={-20}
//         expanded={true}
//         className="parallax-banner-layer"
//       />
//       <ParallaxBannerLayer speed={5}>
//         <div className="parallax-hero-text-div">
//           <h1 className="parallax-hero-text">
//             Flexible Data Collection<br />
//             <em>That Works Your Way</em>
//           </h1>
//           <h3 className="parallax-hero-subtext">
//             Choose an integration method that fits your needs—whether it’s our auto-capture script or a custom API integration.
//           </h3>
//         </div>
//       </ParallaxBannerLayer>
//     </ParallaxBanner>
//   );
// };

// const AutoCaptureSection = () => {
//   return (
//     <div className="section-inner">
//       <h2>Auto-Capture Script</h2>
//       <div className="twobytwo">
//         <ParallaxBox x={-50} y={0} className="benefit-column">
//           <h3>Instant Insights, Zero Configuration</h3>
//           <p className="section-intro">
//             Simply drop in our lightweight JavaScript snippet and start collecting user behavior data instantly.
//           </p>
//           <h4 className="feature-heading">Key Benefits</h4>
//           <ul className="feature-list">
//             <li>One-line installation</li>
//             <li>Automatic event capture</li>
//             <li>Minimal performance impact</li>
//             <li>Configurable privacy controls</li>
//           </ul>
//         </ParallaxBox>
//         <ParallaxBox x={50} y={0} className="benefit-column">
//           <h4 className="feature-heading">Sample Code</h4>
//           <div className="code-block">
//           <pre>
        //     <code dangerouslySetInnerHTML={{
        //       __html: `
        // Standard Use:
        // &lt;script defer src="https://api.teraace.com/happypath.js" data-org-id="ORG_ID_HERE"&gt;&lt;/script&gt;

        // Including Custom Data:
        // &lt;script&gt;
        //   window.myCustomData = {
        //     UserID: 'user123',
        //     SubscriptionTier: 'premium'
        //   };
        // &lt;/script&gt;

        // &lt;script defer type="text/javascript" src="https://api.teraace.com/happypath.js" data-id="ORG_ID_HERE"&gt;&lt;/script&gt;
        //       `
        //     }} />
//           </pre>
//           </div>
//         </ParallaxBox>
//       </div>
//     </div>
//   );
// };

// const BringYourOwnEventsSection = () => {
//   return (
//     <div className="section-inner">
//       <h2>Bring Your Own Events</h2>
//       <div className="twobytwo">
//         <ParallaxBox x={-50} y={0} className="benefit-column">
//           <h3>Full Control with Our Direct API</h3>
//           <p className="section-intro">
//             Send your own events using our REST API or via Segment. Our API supports minimal required fields and optional data for richer context.
//           </p>
//           <h4 className="feature-heading">Essential API Details</h4>
//           <ul className="feature-list">
//             <li>
//               <strong>Endpoint:</strong> <code>https://api.teraace.com/tpd/private</code>
//             </li>
//             <li>
//               <strong>Method:</strong> POST
//             </li>
//             <li>
//               <strong>Headers:</strong> 
//               <ul>
//                 <li>Content-Type: application/json</li>
//                 <li>Authorization: Bearer YOUR_AUTH_TOKEN_HERE</li>
//               </ul>
//             </li>
//             <li>
//               <strong>Minimum Data:</strong> Event Name, URL, Timestamp
//             </li>
//           </ul>
//         </ParallaxBox>
//         <ParallaxBox x={50} y={0} className="benefit-column">
//           <h4 className="feature-heading">Sample API Request</h4>
//           <div className="code-block">
//             <pre>
//               <code>
// {`POST https://api.teraace.com/tpd/private
// Headers:
//   Content-Type: application/json
//   Authorization: Bearer YOUR_AUTH_TOKEN_HERE

// Body:
// {
//   "event": "Login",
//   "url": "/login",
//   "timestamp": "2024-01-17T20:20:51.080Z"
// }`}
//               </code>
//             </pre>
//           </div>
//         </ParallaxBox>
//       </div>
//     </div>
//   );
// };

// const FAQSection = () => {
//   return (
//     <div className="section-inner">
//       <h2>FAQ</h2>
//       <div className="benefit-column">
//         <h3>Common Questions</h3>
//         <ul className="feature-list">
//           <li>Which integration method is right for me?</li>
//           <li>How do I secure my API requests?</li>
//           <li>Can I combine multiple data collection methods?</li>
//           <li>What data is captured automatically?</li>
//         </ul>
//         <div className="cta-buttons">
//           <ScrollToTopButton to="/faq" className="inlink-button">
//             View Full FAQ
//           </ScrollToTopButton>
//         </div>
//       </div>
//     </div>
//   );
// };

// const GetStartedCTA = () => {
//   return (
//     <div className="final-cta">
//       <div className="section-inner">
//         <h2>Ready to Get Started?</h2>
//         <p>Our experts are here to help you choose and implement the right data collection method for your needs.</p>
//         <div className="cta-buttons">
//           <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
//             Create Free Account
//           </ScrollToTopButton>
//           <ScrollToTopButton to="/requestcontact" className="secondary-cta">
//             Schedule a Consultation
//           </ScrollToTopButton>
//         </div>
//       </div>
//     </div>
//   );
// };

// const DataCollection = () => {
//   return (
//     <div className="homepage">
//       <HeroBanner />
//       <AutoCaptureSection />
//       <BringYourOwnEventsSection />
//       <FAQSection />
//       <GetStartedCTA />
//     </div>
//   );
// };

// const DataCollectionPage = () => {
//   return (
//     <ParallaxProvider>
//       <DataCollection />
//     </ParallaxProvider>
//   );
// };

// export default DataCollectionPage;



// // import React from 'react';
// // import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
// // import { motion, useInView } from 'framer-motion';
// // import ScrollToTopButton from '../components/ScrollButton';

// // const ParallaxBox = ({ children, x, y, className }) => {
// //   const ref = React.useRef();
// //   const isInView = useInView(ref, { amount: 0.5 });

// //   return (
// //     <motion.div
// //       ref={ref}
// //       initial={{ opacity: 0, x, y }}
// //       animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
// //       transition={{ duration: 0.5, ease: 'easeInOut' }}
// //       className={className}
// //     >
// //       {children}
// //     </motion.div>
// //   );
// // };

// // const HeroBanner = () => {
// //   return (
// //     <ParallaxBanner className="parallax-hero">
// //       <ParallaxBannerLayer
// //         image="/images/Teraace-bg-051.webp"
// //         speed={-20}
// //         expanded={true}
// //         className="parallax-banner-layer"
// //       />
// //       <ParallaxBannerLayer speed={5}>
// //         <div className="parallax-hero-text-div">
// //           <h1 className="parallax-hero-text">
// //             Flexible Data Collection<br/>
// //             <em>That Works Your Way</em>
// //           </h1>
// //           <h3 className="parallax-hero-subtext">
// //             Choose the integration method that best fits your needs, or combine multiple approaches for comprehensive coverage.
// //           </h3>
// //         </div>
// //       </ParallaxBannerLayer>
// //     </ParallaxBanner>
// //   );
// // };

// // const AutoCaptureSection = () => {
// //   return (
// //     <div className="section-inner">
// //       <h2>Auto-Capture Script</h2>
// //       <div className="twobytwo">
// //         <ParallaxBox x={-50} y={0} className="benefit-column">
// //           <h3>Instant Insights, Zero Configuration</h3>
// //           <p className="section-intro">Drop in our lightweight JavaScript snippet and start collecting user behavior data immediately.</p>
          
// //           <h4 className="feature-heading">Key Features</h4>
// //           <ul className="feature-list">
// //             <li>One-line installation</li>
// //             <li>Automatic event capture</li>
// //             <li>Smart sampling to minimize performance impact</li>
// //             <li>Configurable privacy controls</li>
// //             <li>Built-in sensitive data filtering</li>
// //             <li>Under 10kb gzipped</li>
// //           </ul>
          
// //           <h4 className="feature-heading">Best For</h4>
// //           <ul className="feature-list">
// //             <li>Quick implementation</li>
// //             <li>Maximum coverage with minimum effort</li>
// //             <li>Teams without extensive engineering resources</li>
// //             <li>Rapid prototyping and testing</li>
// //           </ul>
// //         </ParallaxBox>
        
// //         <ParallaxBox x={50} y={0} className="benefit-column">
// //           <h4 className="feature-heading">Sample Implementation</h4>
// //           <div className="code-block">
// //             <pre>
// //               <code>
// // {`<script>
// //   (function(t,e,r,a,c,e){
// //     t[a]=t[a]||function(){(t[a].q=t[a].q||[]).push(arguments)};
// //     var s=e.createElement(r);s.async=1;s.src=c;
// //     var g=e.getElementsByTagName(r)[0];
// //     g.parentNode.insertBefore(s,g);
// //   })(window,document,'script','tra','//cdn.teraace.com/tracker.js');
  
// //   tra('init', 'YOUR_PROJECT_ID');
// // </script>`}
// //               </code>
// //             </pre>
// //           </div>
// //         </ParallaxBox>
// //       </div>
// //     </div>
// //   );
// // };

// // const DirectAPISection = () => {
// //   return (
// //     <div className="section-inner">
// //       <h2>Direct API</h2>
// //       <div className="twobytwo">
// //         <ParallaxBox x={-50} y={0} className="benefit-column">
// //           <h3>Complete Control Over Your Data</h3>
// //           <p className="section-intro">Send exactly the events you want, when you want, with our comprehensive REST API.</p>
          
// //           <h4 className="feature-heading">Key Features</h4>
// //           <ul className="feature-list">
// //             <li>RESTful JSON API</li>
// //             <li>Batch event support</li>
// //             <li>Custom property mapping</li>
// //             <li>Strong type checking</li>
// //             <li>Detailed error reporting</li>
// //             <li>Real-time data validation</li>
// //             <li>SDK support for major languages</li>
// //           </ul>
          
// //           <h4 className="feature-heading">Best For</h4>
// //           <ul className="feature-list">
// //             <li>Maximum control over data collection</li>
// //             <li>Custom implementation requirements</li>
// //             <li>High-security environments</li>
// //             <li>Complex data transformation needs</li>
// //           </ul>
// //         </ParallaxBox>
        
// //         <ParallaxBox x={50} y={0} className="benefit-column">
// //           <h4 className="feature-heading">Sample API Request</h4>
// //           <div className="code-block">
// //             <pre>
// //               <code>
// // {`POST https://api.teraace.com/v1/events

// // {
// //   "event_type": "feature_usage",
// //   "user_id": "usr_123",
// //   "properties": {
// //     "feature_name": "export_report",
// //     "duration_seconds": 45,
// //     "success": true
// //   },
// //   "timestamp": "2025-02-04T10:15:30Z"
// // }`}
// //               </code>
// //             </pre>
// //           </div>
// //         </ParallaxBox>
// //       </div>
// //     </div>
// //   );
// // };

// // const DataPipelineSection = () => {
// //   return (
// //     <div className="section-inner">
// //       <h2>Data Pipeline Integration</h2>
// //       <div className="twobytwo">
// //         <ParallaxBox x={-50} y={0} className="benefit-column">
// //           <h3>Use Your Existing Data Infrastructure</h3>
// //           <p className="section-intro">Leverage your current data pipeline investments with our native integrations.</p>
          
// //           <h4 className="feature-heading">Supported Platforms</h4>
// //           <ul className="feature-list">
// //             <li>Segment</li>
// //             <li>Rudderstack</li>
// //             <li>Snowplow</li>
// //             <li>mParticle</li>
// //             <li>Custom ETL pipelines</li>
// //           </ul>
          
// //           <h4 className="feature-heading">Key Features</h4>
// //           <ul className="feature-list">
// //             <li>Native platform support</li>
// //             <li>Automatic schema mapping</li>
// //             <li>Real-time sync</li>
// //             <li>Historical data import</li>
// //             <li>Transformation support</li>
// //             <li>Error recovery</li>
// //             <li>Bi-directional sync options</li>
// //           </ul>
// //         </ParallaxBox>
        
// //         <ParallaxBox x={50} y={0} className="benefit-column">
// //           <h4 className="feature-heading">Best For</h4>
// //           <ul className="feature-list">
// //             <li>Organizations with existing data infrastructure</li>
// //             <li>Complex data environments</li>
// //             <li>Multi-source data collection</li>
// //             <li>Enterprise deployment</li>
// //           </ul>
// //           <img src="/images/Teraace-bg-110.webp" alt="Connected data platforms" className="pipeline-diagram" />
// //         </ParallaxBox>
// //       </div>
// //     </div>
// //   );
// // };

// // const SecuritySection = () => {
// //   return (
// //     <div className="section-inner">
// //       <h2>Data Security & Privacy</h2>
// //       <div className="twobytwo">
// //         <ParallaxBox x={-50} y={0} className="benefit-column">
// //           <h3>Built-in Protections</h3>
// //           <ul className="feature-list">
// //             <li>Automatic PII detection and filtering</li>
// //             <li>Data encryption in transit and at rest</li>
// //             <li>Configurable data retention</li>
// //             <li>GDPR compliance tools</li>
// //             <li>Customizable privacy controls</li>
// //           </ul>
// //         </ParallaxBox>
        
// //         <ParallaxBox x={50} y={0} className="benefit-column">
// //           <h3>Compliance & Certifications</h3>
// //           <ul className="feature-list">
// //             <li>SOC 2 Type II certified</li>
// //             <li>GDPR compliant</li>
// //             <li>CCPA ready</li>
// //             <li>ISO 27001 certified</li>
// //           </ul>
// //         </ParallaxBox>
// //       </div>
// //     </div>
// //   );
// // };

// // const ImplementationSupport = () => {
// //   return (
// //     <div className="section-inner">
// //       <h2>Implementation Support</h2>
// //       <div className="twobytwo">
// //         <ParallaxBox x={-50} y={0} className="benefit-column">
// //           <h3>Resources for Success</h3>
// //           <ul className="feature-list">
// //             <li>Detailed implementation guides</li>
// //             <li>Sample code repositories</li>
// //             <li>Interactive debugging tools</li>
// //             <li>Schema validation tools</li>
// //             <li>Best practice documentation</li>
// //           </ul>
// //         </ParallaxBox>
        
// //         <ParallaxBox x={50} y={0} className="benefit-column">
// //           <h3>Expert Help Available</h3>
// //           <ul className="feature-list">
// //             <li>Implementation consulting</li>
// //             <li>Technical support</li>
// //             <li>Custom integration assistance</li>
// //             <li>Migration support</li>
// //           </ul>
// //         </ParallaxBox>
// //       </div>
// //     </div>
// //   );
// // };

// // const FAQ = () => {
// //   return (
// //     <div className="section-inner">
// //       <h2>FAQ</h2>
// //       <div className="benefit-column">
// //         <h3>Common Questions</h3>
// //         <ul className="feature-list">
// //           <li>How much data should I collect?</li>
// //           <li>Which events should I track?</li>
// //           <li>How do I handle sensitive data?</li>
// //           <li>What about mobile apps?</li>
// //           <li>How do I test my implementation?</li>
// //         </ul>
// //         <div className="cta-buttons">
// //           <ScrollToTopButton to="/faq" className="inlink-button">
// //             View Full FAQ
// //           </ScrollToTopButton>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // const GetStarted = () => {
// //   return (
// //     <div className="final-cta">
// //       <div className="section-inner">
// //         <h2>Need Help Choosing?</h2>
// //         <p>Our integration experts can help you select and implement the right data collection method for your needs.</p>
// //         <div className="cta-buttons">
// //           <ScrollToTopButton to="https://app.teraace.com/signup" className="primary-cta">
// //            Create Free Account
// //           </ScrollToTopButton>
// //           <ScrollToTopButton to="/requestcontact" className="secondary-cta">
// //             Schedule a Consultation
// //           </ScrollToTopButton>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // const DataCollection = () => {
// //   return (
// //     <div className="homepage">
// //       <HeroBanner />
// //       <AutoCaptureSection />
// //       <DirectAPISection />
// //       <DataPipelineSection />
// //       <SecuritySection />
// //       <ImplementationSupport />
// //       <FAQ />
// //       <GetStarted />
// //     </div>
// //   );
// // };

// // const DataCollectionPage = () => {
// //   return (
// //     <ParallaxProvider>
// //       <DataCollection />
// //     </ParallaxProvider>
// //   );
// // };

// // export default DataCollectionPage;






// // // import React from 'react';
// // // import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
// // // import { motion, useInView } from 'framer-motion';
// // // import ScrollToTopButton from '../components/ScrollButton';
// // // import SEO from '../components/SEO';

// // // const ParallaxBox = ({ children, x, y, className }) => {
// // //   const ref = React.useRef();
// // //   const isInView = useInView(ref, { amount: 0.5 });

// // //   return (
// // //     <motion.div
// // //       ref={ref}
// // //       initial={{ opacity: 0, x, y }}
// // //       animate={isInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x, y }}
// // //       transition={{ duration: 0.5, ease: 'easeInOut' }}
// // //       className={className}
// // //     >
// // //       {children}
// // //     </motion.div>
// // //   );
// // // };

// // // const HeroBanner = () => {
// // //   return (
// // //     <ParallaxBanner style={{ aspectRatio: '2 / 1' }} className="parallax-hero">
// // //       <ParallaxBannerLayer
// // //         image="/images/example.webp"
// // //         speed={-20}
// // //         expanded={true}
// // //         className="parallax-banner-layer"
// // //       />
// // //       <ParallaxBannerLayer speed={5}>
// // //         <div className="parallax-hero-text-div">
// // //           <h1 className="parallax-hero-text">
// // //             Flexible Data Collection<br/>
// // //             <em>That Works Your Way</em>
// // //           </h1>
// // //           <h3 className="parallax-hero-subtext">
// // //             Choose the integration method that best fits your needs, or combine multiple approaches for comprehensive coverage.
// // //           </h3>
// // //         </div>
// // //       </ParallaxBannerLayer>
// // //     </ParallaxBanner>
// // //   );
// // // };

// // // const AutoCaptureSection = () => {
// // //   return (
// // //     <div className="section-inner">
// // //       <h2>Auto-Capture Script</h2>
// // //       <div className="twobytwo">
// // //         <ParallaxBox x={-50} y={0} className="benefit-column">
// // //           <h3>Instant Insights, Zero Configuration</h3>
// // //           <p>Drop in our lightweight JavaScript snippet and start collecting user behavior data immediately.</p>
          
// // //           <h4>Key Features</h4>
// // //           <ul>
// // //             <li>One-line installation</li>
// // //             <li>Automatic event capture</li>
// // //             <li>Smart sampling to minimize performance impact</li>
// // //             <li>Configurable privacy controls</li>
// // //             <li>Built-in sensitive data filtering</li>
// // //             <li>Under 10kb gzipped</li>
// // //           </ul>
          
// // //           <h4>Best For</h4>
// // //           <ul>
// // //             <li>Quick implementation</li>
// // //             <li>Maximum coverage with minimum effort</li>
// // //             <li>Teams without extensive engineering resources</li>
// // //             <li>Rapid prototyping and testing</li>
// // //           </ul>
// // //         </ParallaxBox>
        
// // //         <ParallaxBox x={50} y={0} className="benefit-column">
// // //           <h4>Sample Implementation</h4>
// // //           <pre className="bg-gray-100 p-4 rounded-lg overflow-x-auto">
// // //             <code>
// // // {`<script>
// // //   (function(t,e,r,a,c,e){
// // //     t[a]=t[a]||function(){(t[a].q=t[a].q||[]).push(arguments)};
// // //     var s=e.createElement(r);s.async=1;s.src=c;
// // //     var g=e.getElementsByTagName(r)[0];
// // //     g.parentNode.insertBefore(s,g);
// // //   })(window,document,'script','tra','//cdn.teraace.com/tracker.js');
  
// // //   tra('init', 'YOUR_PROJECT_ID');
// // // </script>`}
// // //             </code>
// // //           </pre>
// // //         </ParallaxBox>
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // const DirectAPISection = () => {
// // //   return (
// // //     <div className="section-inner">
// // //       <h2>Direct API</h2>
// // //       <div className="twobytwo">
// // //         <ParallaxBox x={-50} y={0} className="benefit-column">
// // //           <h3>Complete Control Over Your Data</h3>
// // //           <p>Send exactly the events you want, when you want, with our comprehensive REST API.</p>
          
// // //           <h4>Key Features</h4>
// // //           <ul>
// // //             <li>RESTful JSON API</li>
// // //             <li>Batch event support</li>
// // //             <li>Custom property mapping</li>
// // //             <li>Strong type checking</li>
// // //             <li>Detailed error reporting</li>
// // //             <li>Real-time data validation</li>
// // //             <li>SDK support for major languages</li>
// // //           </ul>
          
// // //           <h4>Best For</h4>
// // //           <ul>
// // //             <li>Maximum control over data collection</li>
// // //             <li>Custom implementation requirements</li>
// // //             <li>High-security environments</li>
// // //             <li>Complex data transformation needs</li>
// // //           </ul>
// // //         </ParallaxBox>
        
// // //         <ParallaxBox x={50} y={0} className="benefit-column">
// // //           <h4>Sample API Request</h4>
// // //           <pre className="bg-gray-100 p-4 rounded-lg overflow-x-auto">
// // //             <code>
// // // {`POST https://api.teraace.com/v1/events

// // // {
// // //   "event_type": "feature_usage",
// // //   "user_id": "usr_123",
// // //   "properties": {
// // //     "feature_name": "export_report",
// // //     "duration_seconds": 45,
// // //     "success": true
// // //   },
// // //   "timestamp": "2025-02-04T10:15:30Z"
// // // }`}
// // //             </code>
// // //           </pre>
// // //         </ParallaxBox>
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // const DataPipelineSection = () => {
// // //   return (
// // //     <div className="section-inner">
// // //       <h2>Data Pipeline Integration</h2>
// // //       <div className="twobytwo">
// // //         <ParallaxBox x={-50} y={0} className="benefit-column">
// // //           <h3>Use Your Existing Data Infrastructure</h3>
// // //           <p>Leverage your current data pipeline investments with our native integrations.</p>
          
// // //           <h4>Supported Platforms</h4>
// // //           <ul>
// // //             <li>Segment</li>
// // //             <li>Rudderstack</li>
// // //             <li>Snowplow</li>
// // //             <li>mParticle</li>
// // //             <li>Custom ETL pipelines</li>
// // //           </ul>
          
// // //           <h4>Key Features</h4>
// // //           <ul>
// // //             <li>Native platform support</li>
// // //             <li>Automatic schema mapping</li>
// // //             <li>Real-time sync</li>
// // //             <li>Historical data import</li>
// // //             <li>Transformation support</li>
// // //             <li>Error recovery</li>
// // //             <li>Bi-directional sync options</li>
// // //           </ul>
// // //         </ParallaxBox>
        
// // //         <ParallaxBox x={50} y={0} className="benefit-column">
// // //           <h4>Best For</h4>
// // //           <ul>
// // //             <li>Organizations with existing data infrastructure</li>
// // //             <li>Complex data environments</li>
// // //             <li>Multi-source data collection</li>
// // //             <li>Enterprise deployment</li>
// // //           </ul>
          
// // //           <img src="/images/example.webp" alt="Connected data platforms" className="mt-8" />
// // //         </ParallaxBox>
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // const SecuritySection = () => {
// // //   return (
// // //     <div className="section-inner">
// // //       <h2>Data Security & Privacy</h2>
// // //       <div className="twobytwo">
// // //         <ParallaxBox x={-50} y={0} className="benefit-column">
// // //           <h3>Built-in Protections</h3>
// // //           <ul>
// // //             <li>Automatic PII detection and filtering</li>
// // //             <li>Data encryption in transit and at rest</li>
// // //             <li>Configurable data retention</li>
// // //             <li>GDPR compliance tools</li>
// // //             <li>Customizable privacy controls</li>
// // //           </ul>
// // //         </ParallaxBox>
        
// // //         <ParallaxBox x={50} y={0} className="benefit-column">
// // //           <h3>Compliance & Certifications</h3>
// // //           <ul>
// // //             <li>SOC 2 Type II certified</li>
// // //             <li>GDPR compliant</li>
// // //             <li>CCPA ready</li>
// // //             <li>ISO 27001 certified</li>
// // //           </ul>
// // //         </ParallaxBox>
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // const ImplementationSupport = () => {
// // //   return (
// // //     <div className="section-inner">
// // //       <h2>Implementation Support</h2>
// // //       <div className="twobytwo">
// // //         <ParallaxBox x={-50} y={0} className="benefit-column">
// // //           <h3>Resources for Success</h3>
// // //           <ul>
// // //             <li>Detailed implementation guides</li>
// // //             <li>Sample code repositories</li>
// // //             <li>Interactive debugging tools</li>
// // //             <li>Schema validation tools</li>
// // //             <li>Best practice documentation</li>
// // //           </ul>
// // //         </ParallaxBox>
        
// // //         <ParallaxBox x={50} y={0} className="benefit-column">
// // //           <h3>Expert Help Available</h3>
// // //           <ul>
// // //             <li>Implementation consulting</li>
// // //             <li>Technical support</li>
// // //             <li>Custom integration assistance</li>
// // //             <li>Migration support</li>
// // //           </ul>
// // //         </ParallaxBox>
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // const FAQ = () => {
// // //   return (
// // //     <div className="section-inner">
// // //       <h2>FAQ</h2>
// // //       <div className="benefit-column">
// // //         <h3>Common Questions</h3>
// // //         <ul>
// // //           <li>How much data should I collect?</li>
// // //           <li>Which events should I track?</li>
// // //           <li>How do I handle sensitive data?</li>
// // //           <li>What about mobile apps?</li>
// // //           <li>How do I test my implementation?</li>
// // //         </ul>
// // //         <div className="mt-8">
// // //           <ScrollToTopButton to="/faq" className="inlink-button">
// // //             View Full FAQ
// // //           </ScrollToTopButton>
// // //         </div>
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // const GetStarted = () => {
// // //   return (
// // //     <div className="final-cta">
// // //       <div className="section-inner">
// // //         <h2>Need Help Choosing?</h2>
// // //         <p>Our integration experts can help you select and implement the right data collection method for your needs.</p>
// // //         <div className="cta-buttons">
// // //           <ScrollToTopButton to="/implementation-guide" className="primary-cta">
// // //             View Implementation Guide
// // //           </ScrollToTopButton>
// // //           <ScrollToTopButton to="/consultation" className="secondary-cta">
// // //             Schedule a Consultation
// // //           </ScrollToTopButton>
// // //         </div>
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // const DataCollection = () => {
// // //   return (
// // //     <div className="homepage">
// // //       <HeroBanner />
// // //       <AutoCaptureSection />
// // //       <DirectAPISection />
// // //       <DataPipelineSection />
// // //       <SecuritySection />
// // //       <ImplementationSupport />
// // //       <FAQ />
// // //       <GetStarted />
// // //       <div className="footer">
// // //       <p className="text-center text-sm mt-8">©2025 HYE Partners, LLC.</p>
// // //       </div>
// // //       <SEO
// // //         title="Data Collection - Teraace"
// // //         description="Flexible data collection methods that work your way - choose from auto-capture script, direct API, or data pipeline integration."
// // //         url="/data-collection"
// // //       />
// // //     </div>
// // //   );
// // // };

// // // const DataCollectionPage = () => {
// // //   return (
// // //     <ParallaxProvider>
// // //       <DataCollection />
// // //     </ParallaxProvider>
// // //   );
// // // };

// // // export default DataCollectionPage;